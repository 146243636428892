import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';

const PatternPage = lazy(() => import('./pattern-page'));
export const PatternPageLazy = () => (
  <PatternPage />
  // <Suspense fallback={<LazyFallback />}>
   
  // </Suspense>
);
