import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collections } from '@/common/interfaces/collection.interface';
import { Resource } from '@/common/interfaces/resource.interface';
import { ArchiveResourcePayload, ResourceData, ResourceSteps } from '../types/resource';

export interface ResourceState {
  resourceCreated: boolean;
  isLoading: boolean;
  currentStep: ResourceSteps;
  resourceData: ResourceData;
  categorizationData: {
    [Collections.Craft]: number;
    [Collections.ResourceType]: number[];
    [Collections.ResourceContentType]: number[];
    [Collections.Technics]: number[];
    [Collections.Yarn]: number[];
  };
}

const initialState: ResourceState = {
  resourceCreated: false,
  isLoading: false,
  currentStep: ResourceSteps.RESOURCE,
  resourceData: {
    name: '',
    description: '',
    files: {},
  },
  categorizationData: {
    [Collections.Craft]: 1,
    [Collections.ResourceType]: [],
    [Collections.ResourceContentType]: [],
    [Collections.Technics]: [],
    [Collections.Yarn]: [],
  },
};

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<ResourceSteps>) => {
      state.currentStep = action.payload;
    },
    setResourceData: (state, action: PayloadAction<ResourceData>) => {
      state.resourceData = action.payload;
    },
    setResourceCategorizationData: (
      state,
      { payload }: PayloadAction<{ collection: Collections; value: number | number[] }>,
    ) => {
      state.categorizationData = {
        ...state.categorizationData,
        [payload.collection]: payload.value,
      };
    },
    getResourceDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getResourceDataSuccessAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    getResourceDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    createResourceDataAction: (state, _: PayloadAction<Resource>) => {
      state.isLoading = true;
    },
    createResourceDataSuccessAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
      state.resourceCreated = true;
    },
    createResourceDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    resetStoreAction: () => {
      return initialState;
    },
    // Archive resource
    archiveResourceAction: (state, _: PayloadAction<ArchiveResourcePayload>) => {
      state.isLoading = true;
    },
    archiveResourceSuccessAction: (state) => {
      state.isLoading = false;
    },
    archiveResourceErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
  },
});

export const {
  resetStoreAction,
  setCurrentStep,
  setResourceData,
  createResourceDataAction,
  createResourceDataSuccessAction,
  createResourceDataErrorAction,
  setResourceCategorizationData,
  getResourceDataAction,
  getResourceDataSuccessAction,
  getResourceDataErrorAction,
  archiveResourceAction,
  archiveResourceSuccessAction,
  archiveResourceErrorAction,
} = resourceSlice.actions;
export const resourceReducer = resourceSlice.reducer;
