import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YarnWithStage } from '../types/yarniverse';

export interface YarnUploadedState {
  isUploadedYarnLoading: boolean;
  uploadedYarn: YarnWithStage[];
  error: boolean;
}

const initialState: YarnUploadedState = {
  isUploadedYarnLoading: false,
  uploadedYarn: [],
  error: false,
};

export const yarnUploadedSlice = createSlice({
  name: 'yarn-uploaded',
  initialState,
  reducers: {
    // Get yarn by id
    getUploadedYarnAction: (state) => {
      state.isUploadedYarnLoading = true;
    },
    getUploadedYarnSuccessAction: (state, action: PayloadAction<YarnWithStage[]>) => {
      state.isUploadedYarnLoading = false;
      state.uploadedYarn = action.payload;
    },
    getUploadedYarnErrorAction: (state) => {
      state.isUploadedYarnLoading = false;
    },
  },
});

export const { getUploadedYarnAction, getUploadedYarnSuccessAction, getUploadedYarnErrorAction } =
  yarnUploadedSlice.actions;

export const yarnUploadedReducer = yarnUploadedSlice.reducer;
