import { styled } from '@mui/material/styles';

interface HeaderProps {
  isTransparent: boolean;
}

export const HeaderEl = styled('header')<HeaderProps>(({ isTransparent }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: isTransparent ? 'transparent' : '#fff',
  borderBottom: '1px solid #000',
  padding: '1.80rem 1rem',
  zIndex: 999,
  '.header__container': {
    padding: '0 1rem',
    margin: '0 -1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.header__nav-list': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  '.header__nav-item': {
    display: 'inline-block',
    margin: '0 1rem',
  },
  '.header__nav-item:hover': {
    textDecoration: 'underline',
  },
  '.header__nav-link': {
    color: '#211E21',
    fontSize: '1.125rem',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  '.header__logo': {
    height: '30px',
    marginLeft: '-13rem',
  },
}));
