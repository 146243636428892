import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { Input } from '../input/input';
import { useTranslation } from 'react-i18next';
import {
  ImageContainer,
  Name,
  Container,
  DeleteButton,
  ImgEl,
  PlaceHolderImage,
  EanPlaceHolderImage,
  RemovingOverlay,
} from './styles';
import { Button } from '../button/button';
import { CircularProgress } from '@mui/material';

interface Props {
  name?: string;
  ean?: string;
  colorImage?: string;
  eanImage?: string;
  readonly?: boolean;
  onSave?: (color: { image?: File; eanImage?: File; ean?: string; name?: string }) => void;
  onRemoveColorImage?: () => void;
  onRemoveEanImage?: () => void;
  onRemove?: () => void;
}

export const YarnColorAndEanComponent: FC<Props> = ({
  name,
  ean,
  colorImage,
  eanImage,
  readonly = false,
  onSave = () => {},
  onRemoveColorImage = () => {},
  onRemoveEanImage = () => {},
  onRemove = () => {},
}) => {
  const { t } = useTranslation();

  const colorImageUploadRef = useRef<HTMLInputElement>(null);
  const eanImageUploadRef = useRef<HTMLInputElement>(null);
  const [colorImageUrl, setColorImageUrl] = useState<string | Blob | null>(colorImage || null);
  const [eanImageUrl, setEanImageUrl] = useState<string | Blob | null>(eanImage || null);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleSave = () => {
    onSave({
      ean: eanNumber,
      name: title,
      ...(colorImageUrl instanceof File && { colorImage: colorImageUrl }),
    });
  };

  const [title, setTitle] = useInputWithDelay(name || '', handleSave, 500);
  const handleChangeTitle = (value: string) => setTitle(value);

  const [eanNumber, setEanNumber] = useInputWithDelay(ean || '', handleSave, 500);
  const handleChangeEanNumber = (value: string) => setEanNumber(value);

  const handleRemoveColorImage = () => {
    setColorImageUrl(null);
    onRemoveColorImage();
  };

  const handleRemoveEanImage = () => {
    setEanImageUrl(null);
    onRemoveEanImage();
  };

  const handleClickUploadColorImage = () => {
    if (readonly) return;
    colorImageUploadRef.current?.click();
  };

  const handleClickUploadEanImage = () => {
    if (readonly) return;
    eanImageUploadRef.current?.click();
  };

  const handleChangeColorImage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    const imageBlobUrl = target.files?.[0];
    if (imageBlobUrl) {
      setColorImageUrl(imageBlobUrl);
      onSave({ image: imageBlobUrl });
    }
    target.value = '';
  };

  const handleChangeEanImage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    const imageBlobUrl = target.files?.[0];
    if (imageBlobUrl) {
      setEanImageUrl(imageBlobUrl);
      onSave({ eanImage: imageBlobUrl });
    }
    target.value = '';
  };

  return (
    <Container>
      {colorImageUrl ? (
        <ImageContainer>
          {!readonly && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveColorImage();
              }}
            >
              {t('common.remove')}
            </DeleteButton>
          )}
          <ImgEl
            src={
              typeof colorImageUrl === 'string'
                ? getAssetBackendUrl(colorImageUrl, 900)
                : URL.createObjectURL(colorImageUrl)
            }
            onClick={handleClickUploadColorImage}
            style={{ cursor: readonly ? 'auto' : 'pointer' }}
          />
        </ImageContainer>
      ) : (
        <PlaceHolderImage
          onClick={handleClickUploadColorImage}
          style={{ cursor: readonly ? 'auto' : 'pointer' }}
        >
          {t('Yarniverse.uploadImage')}
        </PlaceHolderImage>
      )}
      <input
        type='file'
        ref={colorImageUploadRef}
        accept='image/*'
        name={'colorImage'}
        onChange={handleChangeColorImage}
        hidden
      />
      {readonly ? (
        <Name>{name}</Name>
      ) : (
        <Input
          placeholder={t('Yarniverse.nameOfColor')}
          value={title}
          onChange={(e) => handleChangeTitle(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
          }}
        />
      )}
      <div style={{ minHeight: '16px' }} />
      <input
        type='file'
        ref={eanImageUploadRef}
        accept='image/*'
        name={'eanImage'}
        onChange={handleChangeEanImage}
        hidden
      />
      {eanImageUrl ? (
        <ImageContainer>
          {!readonly && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveEanImage();
              }}
            >
              {t('common.remove')}
            </DeleteButton>
          )}
          <ImgEl
            src={
              typeof eanImageUrl === 'string'
                ? getAssetBackendUrl(eanImageUrl)
                : URL.createObjectURL(eanImageUrl)
            }
            onClick={handleClickUploadEanImage}
          />
        </ImageContainer>
      ) : (
        <EanPlaceHolderImage
          onClick={handleClickUploadEanImage}
          style={{ cursor: readonly ? 'auto' : 'pointer' }}
        >
          {t('Yarniverse.uploadEanImage')}
        </EanPlaceHolderImage>
      )}
      {readonly ? (
        <Name>{ean}</Name>
      ) : (
        <Input
          placeholder={t('Yarniverse.eanNumber')}
          value={eanNumber}
          onChange={(e) => handleChangeEanNumber(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
          }}
        />
      )}

      {!readonly && (
        <Button
          remove
          style={{ marginTop: '16px' }}
          onClick={() => {
            setIsRemoving(true);
            onRemove();
          }}
          disabled={isRemoving}
        >
          {t('common.remove')}
        </Button>
      )}
      {isRemoving && (
        <RemovingOverlay>
          <CircularProgress style={{ color: '#000' }} size={24} />
        </RemovingOverlay>
      )}
    </Container>
  );
};
