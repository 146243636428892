import { PATH } from '@/common/constants/path';
import { styled } from '@mui/material/styles';
import { FC, memo, useEffect, useState } from 'react';
import { ResourceWithStage } from '@/store/types/pattern';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ResourceTypes } from '@/common/constants/resources';
import { ImgVideo } from '@/components/img-video/img-video';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const apiPath = process.env.REACT_APP_API_URL || '';

export interface ResourcesItemProps {
  resource: ResourceWithStage;
  onOpenPreview: (resourceId: number) => void;
  onResourceDeselect?: (deselectedResource: number) => void;
}

const ListEl = styled('li')({
  marginBottom: '1.5rem',
  position: 'relative',
});

const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
});

const Title = styled('p')({
  margin: '0.5rem 0 0 0',
  fontSize: '1rem',
  lineHeight: '1.5rem',
});

const ButtonContainer = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const MenuBtn = styled(Button)({
  padding: '0.5rem',
  minWidth: 'auto',
  backgroundColor: '#fff',
  color: '#000',
  ':hover': {
    backgroundColor: '#F6F4ED',
  },
});

const CopyBtn = styled(Button)(({ theme }) => ({
  ...theme.typography.body3,
  textTransform: 'capitalize',
  backgroundColor: '#fff',
  color: '#000',
  ':hover': {
    backgroundColor: '#F6F4ED',
  },
}));

export const ResourceItem: FC<ResourcesItemProps> = memo(({ resource, onOpenPreview, onResourceDeselect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name, files, id } = resource;
  const [isVisible, setIsVisible] = useState(false);

  //@ts-ignore
  const fileId = files ? files[0]?.file : '';
  const fileSrc = `${apiPath}/assets/${fileId}?width=400`;

  const copyLinkHandler = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://${window.location.host}${PATH.RESOURCE_VIEWER}?id=${id}&type=${ResourceTypes.RESOURCE}`,
      );
    } catch (error) {
      console.error('Failed to copy the link:', error);
    }
  };

  const handleResourceDeselect = () => {
    if (id) {
      onResourceDeselect && onResourceDeselect(id);
    }
  }

  const navigateToEditResource = (id: number) => {
    navigate(`${PATH.RESOURCE_UPLOAD}?id=${id}`);
  };

  const onOpenPreviewHandler = () => {
    resource.id && onOpenPreview(resource.id);
  };

  return (
    <ListEl>
      {!!fileId && <Image naturalAspectRatio src={fileSrc} onClick={onOpenPreviewHandler} />}
      <ButtonContainer>
        <MenuBtn onClick={() => setIsVisible(!isVisible)}><MoreVertIcon fontSize='large' /></MenuBtn>
        {isVisible && (
          <>
            <CopyBtn onClick={copyLinkHandler}>{t('common.copyLink')}</CopyBtn>
            <CopyBtn onClick={handleResourceDeselect}>{t('common.deselect')}</CopyBtn>
            {/* {id !== undefined && <CopyBtn onClick={() => navigateToEditResource(id)}>{t('common.edit')}</CopyBtn>} */}
          </>
        )}
      </ButtonContainer>
      <Title>{name}</Title>
    </ListEl>
  );
});
