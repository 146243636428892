import { ResourceTooltip } from '@/components/resource-tooltip/resource-tooltip';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { PatternCategoryInfo } from '../pattern-category-info';
import { CurrentStep } from './components/current-step';
import { Diagrams } from './components/diagrams';
import { Step } from './components/step';
import { Container, Content, MainContent } from './styles';
import { PatternStepWithStage, PatternWithStage, ResourceWithStage } from '@/store/types/pattern';
import { useDispatch, useSelector } from 'react-redux';
import { BigPageTitle } from '../big-page-title';
import { KnitmodeHeader } from './components/knitmode-header';
import { StepMenu } from './components/step-menu';
import { StepInstructions } from './components/step-instructions';
import { ResourcesPanel } from './components/resources-panel';
import { CloseBtn } from '../close-btn';
import { getPatternStepsAction } from '@/store/reducers/pattern.reducer';
import { getPatternStepsSelector } from '@/store/selectors/pattern.selector';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';
import { DiagramCollectionItem } from '@/common/interfaces/collection.interface';
import { getKnitryResourcesSelector } from '@/store/selectors/pattern.selector';
import { getKnitryResourcesAction } from '@/store/reducers/resource-all.reducer';

interface Props {
  pattern: PatternWithStage;
  zoom?: number;
  selectedSizeId?: number | null;
  previewMode?: boolean;
  onZoomChange?: (zoom: number) => void;
  onSizeChange?: (sizeId: number) => void;
  onExitPreview?: () => void;
  initialStepIndex?: number;
  onStepChange?: (index: number) => void;
}

export const PatternStepViewer: FC<Props> = (props) => {
  const { 
    pattern,
    zoom = 100, 
    selectedSizeId = null, 
    previewMode, 
    onZoomChange, 
    onSizeChange, 
    onExitPreview,
    initialStepIndex = 0,
    onStepChange
  } = props;
  const {
    pattern_steps = [],
    craft,
    type_of_project,
    passer_til,
    technique,
    user_created,
    name,
    needle,
  } = pattern;
  
  console.log('PatternStepViewer props:', {
    patternId: pattern?.id,
    zoom,
    selectedSizeId,
    previewMode,
    hasSteps: pattern?.pattern_steps?.length
  });

  if (!pattern_steps || pattern_steps.length === 0) {
    return <p>No steps available for this pattern.</p>;
  }

  const [currentStep, setCurrentStep] = useState<PatternStepWithStage>(
    pattern_steps[initialStepIndex] || pattern_steps[0]
  );
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const dispatch = useDispatch();

  const categoryInfoData = useMemo(() => {
    return {
      craft: {
        label: 'Craft',
        value: craft?.name || '',
      },
      project: {
        label: 'Project',
        value: type_of_project?.map((el) => el?.name).join(', ') || '',
      },
      suitable: {
        label: 'Suitable for',
        value: passer_til?.map((el) => el?.name).join(', ') || '',
      },
      technics: {
        label: 'Technics',
        value: technique?.map((el) => el?.name).join(', ') || '',
      },
    };
  }, [pattern]);

  useEffect(() => {
    setCurrentStep(pattern_steps[0]);
  }, [pattern]);

  const recommendedNeedles = useMemo(() => {
    return (
      needle?.map(({ needle_size, needle_type, cable_length }) =>
        [needle_type?.name, needle_size?.size, cable_length?.value].filter(Boolean).join(', '),
      ) || []
    ).filter(Boolean);
  }, [pattern]);

  const handleStepChange = (step: PatternStepWithStage) => {
    setCurrentStep(step);
    const newIndex = pattern_steps.findIndex(s => s.id === step.id);
    if (newIndex !== -1 && onStepChange) {
      onStepChange(newIndex);
    }
  };

  const handleNextStep = () => {
    const currentIndex = pattern_steps.findIndex(step => step.id === currentStep.id);
    if (currentIndex < pattern_steps.length - 1) {
      setCurrentStep(pattern_steps[currentIndex + 1]);
    }
  };

  const handleFinish = () => {
    // Implement finish logic here
    console.log('Pattern finished!');
  };

  const handlePrevStep = () => {
    const currentIndex = pattern_steps.findIndex(step => step.id === currentStep.id);
    if (currentIndex > 0) {
      setCurrentStep(pattern_steps[currentIndex - 1]);
    }
  };

  const isFirstStep = currentStep.id === pattern_steps[0].id;

  const isLastStep = currentStep.id === pattern_steps[pattern_steps.length - 1].id;

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const [allResources, setAllResources] = useState<ResourceWithStage[]>([]);
  const [allDiagrams, setAllDiagrams] = useState<DiagramCollectionItem[]>([]);
  const patternSteps = useSelector(getPatternStepsSelector);

  useEffect(() => {
    if (pattern.id) {
      dispatch(getPatternStepsAction(pattern.id));
    }
  }, [pattern.id]);

  useEffect(() => {
    if (patternSteps) {
      // Deduplicate resources by ID
      const resourceMap = new Map();
      patternSteps.forEach(step => {
        (step.ressources || []).forEach(resource => {
          if (!resourceMap.has(resource.id)) {
            resourceMap.set(resource.id, resource);
          }
        });
      });
      setAllResources(Array.from(resourceMap.values()));

      // Deduplicate diagrams by ID
      const diagramMap = new Map();
      patternSteps.forEach(step => {
        (step.step_diagrams || []).forEach(diagram => {
          if (!diagramMap.has(diagram.id)) {
            diagramMap.set(diagram.id, diagram);
          }
        });
      });
      setAllDiagrams(Array.from(diagramMap.values()));
    }
  }, [patternSteps]);

  const handleOpenResource = (id: number, type: 'resource' | 'diagram') => {
    // Implement resource opening logic here
  };

  const selectedSize = useMemo(() => {
    console.log('Looking up size:', {
      selectedSizeId,
      availableSizes: pattern.available_sizes
    });
    
    if (!selectedSizeId || !pattern.available_sizes) return null;
    const size = pattern.available_sizes.find(size => size.id === selectedSizeId);
    console.log('Found size:', size);
    return size;
  }, [selectedSizeId, pattern.available_sizes]);

  const handleZoomChange = (newZoom: number) => {
    if (onZoomChange) {
      onZoomChange(newZoom);
    }
  };

  const handleSizeChange = (sizeId: number) => {
    if (onSizeChange) {
      onSizeChange(sizeId);
    }
  };

  useEffect(() => {
    if (pattern_steps[initialStepIndex]) {
      setCurrentStep(pattern_steps[initialStepIndex]);
    }
  }, [initialStepIndex, pattern_steps]);

  useEffect(() => {
    dispatch(getKnitryResourcesAction());
  }, []);

  const patternResources = useMemo(() => 
    allResources.filter(resource => 
      !resource.isOfficial && 
      !resource.ResourceType?.some(type => type.resource_type_id?.id === 3)
    ), 
    [allResources]
  );

  const diagramResources = useMemo(() => 
    allResources.filter(resource =>
      !resource.isOfficial &&
      resource.ResourceType?.some(type => type.resource_type_id?.id === 3)
    ),
    [allResources]
  );

  const knitryResources = useMemo(() => 
    allResources.filter(resource => resource.isOfficial === true),
    [allResources]
  );

  return (
    <Container>
      <KnitmodeHeader
        patternName={name || ''}
        username={user_created?.display_name || ''}
        userId={user_created?.id || ''}
        language='English'
        patternSize={selectedSize?.size_name || ''}
        zoom={zoom}
        onZoomChange={handleZoomChange}
        availableSizes={pattern.available_sizes || []}
        selectedSizeId={selectedSizeId}
        onSizeChange={handleSizeChange}
        previewMode={previewMode}
        onExitPreview={onExitPreview}
      />
      <Content>
        <StepMenu
          steps={pattern_steps}
          currentStep={currentStep}
          onStepChange={handleStepChange}
          isMenuVisible={isMenuVisible}
          onToggleMenu={handleToggleMenu}
          patternId={pattern.id}
          previewMode={previewMode}
        />
        <MainContent>
          <StepInstructions
            currentStep={currentStep}
            fontSize={zoom}
            selectedSizeId={selectedSizeId}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            onFinish={handleFinish}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            onOpenResource={handleOpenResource}
            patternSizeModel={pattern.pattern_size_model}
            previewMode={previewMode}
            onExitPreview={onExitPreview}
          />
          <ResourcesPanel
            resources={patternResources}
            diagrams={diagramResources}
            knitryResources={knitryResources}
            onOpenResource={handleOpenResource}
            isMenuVisible={isMenuVisible}
            showInPanel={true}
          />
        </MainContent>
      </Content>
    </Container>
  );
};
