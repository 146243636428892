import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DiagramCollectionItem,
  ResourceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { ImgVideo } from '../img-video/img-video';

const apiPath = process.env.REACT_APP_API_URL || '';

export interface ResourceTooltipContentProps {
  resource: ResourceCollectionItem | DiagramCollectionItem | null;
  loading: boolean;
}

const Progress = styled(CircularProgress)({
  margin: '1rem auto',
  display: 'block',
});

const Img = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  paddingBottom: '60%',
});

const Title = styled('p')({
  margin: '0.5rem 0 0',
  fontSize: '1rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Description = styled('p')({
  margin: '0.5rem 0 0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ResourceTooltipContent: FC<ResourceTooltipContentProps> = ({ resource, loading }) => {
  return loading ? (
    <Progress />
  ) : !!resource ? (
    <>
      <Img
        src={`${apiPath}/assets/${
          !!(resource as ResourceCollectionItem).files
            ? (resource as ResourceCollectionItem).files[0].file
            : (resource as DiagramCollectionItem).image
        }`}
      />
      <Title>{resource.name}</Title>
      {!!(resource as ResourceCollectionItem).description && (
        <Description>{(resource as ResourceCollectionItem).description}</Description>
      )}
    </>
  ) : (
    <></>
  );
};
