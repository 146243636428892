import React, { FC, useEffect, useState, ReactNode, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { createApi } from '@/services/api';
import {
  Collections,
  DiagramCollectionItem,
  ResourceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { ResourceTooltipContent } from './resource-tooltip-content';
import { getQueryParamsForCollections } from '@/common/constants/directus-fields';

export interface ResourceTooltipProps {
  children: ReactNode;
}

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F5F4F0',
    width: '20rem',
    border: '1px solid #000',
    padding: '0.5rem',
    color: '#000',
  },
}));

export const ResourceTooltip: FC<ResourceTooltipProps> = ({ children }) => {
  const [linkEl, setLinkEl] = useState<HTMLLinkElement | null>(null);
  const [resource, setResource] = useState<ResourceCollectionItem | DiagramCollectionItem | null>(
    null,
  );
  const [resourceLoading, setResourceLoading] = useState(true);
  const [loadingError, setLoadingError] = useState('');

  const getResource = async (resourceId: number, resourceType: string) => {
    try {
      setLoadingError('');
      setResourceLoading(true);

      let collectionToGet: Collections | null = null;
      switch (resourceType) {
        case 'resource': {
          collectionToGet = Collections.Resources;
          break;
        }
        case 'diagram': {
          collectionToGet = Collections.Diagrams;
          break;
        }
      }

      if (!collectionToGet) {
        return;
      }

      const resource = (await createApi().getCollectionItemById(
        collectionToGet,
        resourceId,
        getQueryParamsForCollections(collectionToGet),
      )) as ResourceCollectionItem | DiagramCollectionItem;
      if (!resource) {
        throw new Error('Resource load failed');
      }
      setResource(resource);
    } catch (e) {
      setLoadingError((e as Error).message);
    } finally {
      setResourceLoading(false);
    }
  };

  useEffect(() => {
    if (linkEl) {
      const { searchParams } = new URL(linkEl.href);
      const resourceId = searchParams.get('id');
      const resourceType = searchParams.get('type');
      if (resourceId && resourceType) {
        getResource(+resourceId, resourceType);
      }
    }
  }, [linkEl]);

  const handleLinkElement = ({ target }: MouseEvent<HTMLDivElement>) => {
    const resourceEl = !(target as HTMLLinkElement).href ? null : target;
    if (resourceEl !== linkEl) {
      setLinkEl(resourceEl as HTMLLinkElement);
    }
  };

  return (
    <TooltipStyled
      open={!!linkEl}
      title={
        loadingError || <ResourceTooltipContent resource={resource} loading={resourceLoading} />
      }
      placement='top'
      arrow
      PopperProps={{
        anchorEl: !!linkEl
          ? {
              getBoundingClientRect: () => {
                return linkEl.getBoundingClientRect();
              },
            }
          : undefined,
      }}
    >
      <div onMouseMove={handleLinkElement}>{children}</div>
    </TooltipStyled>
  );
};
