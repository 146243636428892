import { DropdownGroup } from '@/components/dropdown-group';
import {
  addFiberAction,
  getFibersByYarnIdAction,
  removeFiberAction,
  updateFiberAction,
} from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseCurrentStepSelector,
  getYarniverseDataSelector,
} from '@/store/selectors/yarniverse.selector';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YarniverseStepsKeys } from '../../../steps';
import { ButtonLabelStyled, ButtonStyled, Container } from './styles';
import { useTranslation } from 'react-i18next';

export const Fiber = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const currentStep = useSelector(getYarniverseCurrentStepSelector);

  const { Fiber, FiberType, Countries } = useSelector(getCollectionDataSelector);

  const yarn = useSelector(getYarniverseDataSelector);

  const TYPES = FiberType.map((_) => ({ label: _.name, value: _.id }));
  const PERCENTS = new Array(100)
    .fill(null)
    .map((_, i) => ({ value: `${i + 1}%`, label: `${i + 1}%` }));
  const COUNTRIES = Countries.map((_) => ({ label: _.name, value: _.id }));

  const handleAdd = () => {
    if (!yarn.id) return;
    dispatch(
      addFiberAction({
        yarn: yarn,
      }),
    );
  };

  const handleChange = (id: number, type: string, value: string | number) => {
    if (!yarn.id) return;
    dispatch(
      updateFiberAction({
        yarnId: yarn.id,
        fiber: {
          id,
          [type]: value,
        },
      }),
    );
  };

  const handleRemove = (id?: number) => {
    if (!yarn.id || !id) return;
    dispatch(
      removeFiberAction({
        fiberId: id,
        yarn: yarn,
      }),
    );
  };

  useEffect(() => {
    if (yarn.id && currentStep === YarniverseStepsKeys.Categorization) {
      dispatch(getFibersByYarnIdAction(yarn.id));
    }
  }, [currentStep, yarn]);

  return (
    <Container>
      {Fiber.map((item, i) => {
        if (!item) return null;
        return (
          <DropdownGroup
            key={item.id}
            id={item.id}
            label={'Fiber ' + (i + 1)}
            data={[
              {
                type: 'type',
                value: item.type?.id,
                options: TYPES,
              },
              {
                type: 'pourcent',
                value: item.pourcent,
                options: PERCENTS,
              },
              {
                type: 'country',
                value: item.country?.id,
                options: COUNTRIES,
              },
            ]}
            onChange={(type, value) => handleChange(item.id, type, value)}
            onDelete={(id) => handleRemove(id)}
          />
        );
      })}
      <ButtonStyled onClick={handleAdd}>
        <ButtonLabelStyled>{t('Yarniverse.addFiber')}</ButtonLabelStyled>
      </ButtonStyled>
    </Container>
  );
};
