import { Button } from '@/components/button/button';
import { styled } from '@mui/material';

export const Title = styled('div')({
  fontSize: '2.5rem',
  fontWeight: 400,
});

export const Description = styled('div')({
  fontSize: '1.125rem',
  margin: '1rem 0',
});

export const Sizes = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '1rem',
});

export const Item = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  minWidth: '10rem',
  width: 'fit-content',
  borderRadius: '0',
  padding: '1rem',
  '&:hover': {
    borderColor: '#000',
  },
}));
