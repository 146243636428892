import { useSelector } from 'react-redux';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { isUserRole } from '@/utils/isUserRole';
import { isPrelaunchForPath, isPrelaunchForUserRole } from '@/utils/isPrelaunch';
import { UserRole } from '@/common/constants/launchDates';
import { PATH } from '@/common/constants/path';

const usePrelaunchState = (path: PATH): boolean => {
  const currentUser = useSelector(getCurrentUserSelector);
  const userRole: UserRole = currentUser && isUserRole(currentUser.role.name) ? currentUser.role.name : 'default';

  const prelaunchForPath = isPrelaunchForPath(path);
  const prelaunchForUserRole = isPrelaunchForUserRole(userRole);

  return prelaunchForPath || prelaunchForUserRole;
};

export default usePrelaunchState;