import { Box, Button, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const MenuContainer = styled(Box)(({ theme }) => ({
  width: '50vw',
  minWidth: '17.5rem',
  maxWidth: '60rem',
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  [theme.breakpoints.down('desktop')]: {
      width: '75vw',
  },
}));

export const MenuHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',          
  alignItems: 'center',       
  marginTop: '2rem',
  padding: '0.75rem 1.5rem 0.75rem 2.5rem',
  borderTop: '1px solid #000',
  borderBottom: '1px solid #000',
  [theme.breakpoints.down('tablet')]: {
    marginTop: '4.5rem',
    padding: '0.5rem 0 0.5rem 0.5rem',
  },
}));

export const HeaderLabelStyle = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle1,
  textTransform: 'uppercase',
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle1,
}));

export const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5vw',
  padding: '6vw 0rem 6vw 2.5rem',
  [theme.breakpoints.up('wide')]: {
    padding: '7.2rem 0rem 7.2rem 2.5rem',
    rowGap: '6rem',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '1.5rem 0rem 1.5rem 0.5rem',
    rowGap: '2rem',
  },
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.text,
}));

export const MenuLinkStyle = styled('div')<{ active?: boolean }>(({ theme, active }) => ({
  ...theme.typography.subtitle2,
  textAlign: 'left',
  color: active ? 'inherit' : theme.palette.primary.inactive,
  '&:hover': {
      fontWeight: '700'
    }
  }
));

export const MenuByline = styled(Typography)<{ active?: boolean }>(({ theme, active }) => ({
  ...theme.typography.body1,
  margin: '0 0 0 0.5vw',
  textAlign: 'initial',
  whiteSpace: 'pre-line',
  color: active ? 'inherit' : theme.palette.primary.inactive,
  [theme.breakpoints.up('wide')]: {
    margin: '0rem 0 0 0.6rem',
  },
  [theme.breakpoints.down('laptop')]: {
    margin: 0,
    fontSize: '0.75rem',
  },
}));