import { z } from "zod";
import { FormatString, parseFormatString } from "@/common/size-format";
import { getCollectionDataSelector } from "@/store/selectors/collection.selector";
import { getPatternAvailableSizesSelector, getPatternDataSelector } from "@/store/selectors/pattern.selector";
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPatternDataAction } from "@/store/reducers/pattern.reducer";
import { t } from "i18next";
import { Button } from "@/components/button/button";
import ImportantNotice from "./important-notice";
import { theme } from "@/common/mui-theme";
import { Close } from "@mui/icons-material";
import { CloseBtn } from "@/components/close-btn";
import { AvailableSizeCollectionItem } from "@/common/interfaces/collection.interface";

const style = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '90rem',
  minHeight: '65%',
  backgroundColor: 'white',
} as any;

type Props = {
  onClose: () => void;
};

export const SizeFormatEdit = ({ onClose: close }: Props) => {
  const { id, pattern_size_model } = useSelector(getPatternDataSelector);
  const availableSizes = useSelector(getPatternAvailableSizesSelector);

  const serializedFormat = useMemo(() => {
    let s = serializeFormat(FormatString.parse(JSON.parse(pattern_size_model ?? "[]")));

    const sizes_in_serial: number[] = s.map(s => s.type === "size" && s.size_id || null).filter(x => x !== null) as any;
    const newSizes = availableSizes.filter(size => !sizes_in_serial.includes(size));
    const oldSizes = sizes_in_serial.filter(size => !availableSizes.includes(size));

    // remove old values
    for (let i = 0; i < s.length - 1; i++) {
      const value = s[i];
      if (oldSizes.includes(value.type === "size" && value.size_id as any)) {
        s.splice(i, 2);
        i--;
      }
    }

    // add new values
    s = s.concat(newSizes.flatMap(size_id => {
      return [{
        type: "size",
        size_id,
      }, {...empty_delim}];
    }));

    return s;
  }, [pattern_size_model, availableSizes]);

  const [serials, setSerials] = useState<Serial[]>(serializedFormat);
  useEffect(() => {
    if (pattern_size_model) setSerials(serializedFormat);
    else setSerials(availableSizes
                    .map(size_id => ({ type: "size", size_id } as const))
                    .reduce((acc, s) => [...acc, s, {...empty_delim}], [empty_delim]));
  }, [serializedFormat, pattern_size_model])

  const delimiters_in_data = serials.flatMap(y => y.type === "delimiter" ? [y.delim] : []);
  const uniqueDelimiters = new Set([
      "",
      "(",
      ")",
      "/",
      "-",
      ", ",
      ...delimiters_in_data,
    ]);

  const delimiters: string[] = [];
  uniqueDelimiters.forEach(delim => {
    delimiters.push(delim);
  });

  const set = (delimiter: Serial) => (event: SelectChangeEvent) => {
    if (!serials) return;
    const newSerials = serials.map(x => {
      if (x === delimiter) {
        return {
          ...delimiter,
          delim: event.target.value as string,
        };
      } else {
        return x;
      }
    });
    setSerials(newSerials);
  };

  const format = parseFormat(serials ?? []);

  const { AvailableSize: sizes } = useSelector(getCollectionDataSelector);

  const dispatch = useDispatch();
  const save = () => {
    dispatch(setPatternDataAction({
      id,
      pattern_size_model: JSON.stringify(format),
      callback: close,
    }));
  };

  return (
    <>
      <Box style={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #000',
            padding: '2rem 2rem 1rem 2rem',
          }}
        >
          <Typography variant='h2'>{t('patternizerSteps.sizeValueRecognition')}</Typography>
          <Close sx={{ cursor: 'pointer', ...theme.typography.h2 }} onClick={close} />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 2rem',
            ...theme.typography.body2,
            flexWrap: 'wrap',
            rowGap: '1rem',
          }}
        >
          {serials.map((y, i) =>
            y.type === 'size' ? (
              <Box
                key={y.type + y.size_id + i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 'fit-content',
                  width: '4rem',
                  padding: '0.5rem',
                  textAlign: 'center',
                }}
              >
                {sizes.find((x) => x.id === y.size_id)?.size_name}
              </Box>
            ) : (
              <Select
                sx={{
                  borderRadius: 0,
                  width: '4rem',
                  ...theme.typography.body2,
                  '& .MuiSelect-select': {
                    '&:focus': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#000',
                  },
                }}
                key={y.delim + i}
                value={y.delim}
                onChange={set(y)}
              >
                {delimiters.map((d) => (
                  <MenuItem
                    sx={{
                      ...theme.typography.body2,
                      '&:hover': { backgroundColor: theme.palette.secondary.main },
                    }}
                    key={d}
                    value={d}
                  >
                    {d}
                  </MenuItem>
                ))}
              </Select>
            ),
          )}
        </Box>
        <Box style={{ display: 'flex', flexWrap: 'wrap', padding: '0 2rem', columnGap: '1rem' }}>
          <Typography variant='body2'>{t('patternizerSteps.sizeFormat')}</Typography>
          <Typography variant='body2'>{renderFormat(format, sizes)}</Typography>
        </Box>
        <Box style={{ display: 'flex', flexWrap: 'wrap', padding: '0 2rem', columnGap: '1rem' }}>
          <Typography variant='body2'>{t('patternizerSteps.sizeExample')}</Typography>
          <Typography variant='body2'>{renderFormat(format)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button colored onClick={save}>
            Save
          </Button>
        </Box>
        <Box sx={{ padding: '0 2rem' }}>
          <ImportantNotice />
        </Box>
      </Box>
    </>
  );
}

type Serial = {
  type: "delimiter";
  delim: string;
} | {
  type: "size";
  size_id: number;
}

const empty_delim: Serial = {
  type: "delimiter",
  delim: "",
};

const group_delim: Serial = {
  type: "delimiter",
  delim: "/",
};

function intersperse<T>(v: T, xs: T[]): T[] {
  if (xs.length === 0) return [];
  return xs.slice(1).reduce((acc, x) => [...acc, {...v}, x], [xs[0]]);
}

const serializeFormat = (s: z.infer<typeof FormatString>): Serial[] => {
  const result = s.flatMap(x => {
    if (x.type === "delimiter") {
      return [{
        type: "delimiter",
        delim: x.delimiter,
      }] as Serial[];
    } else {
      return intersperse(group_delim, x.for_sizes.map(y => ({
        type: "size",
        size_id: y,
      }) as Serial));
    }
  });

  for (let i = 0; i < result.length - 1; i++) {
    const a = result[i];
    const b = result[i+1]
    if (a.type === "size" && b.type === "size") {
      result.splice(i+1, 0, {...empty_delim});
    }
  }

  const beginning_delim: Serial[] = result.at(0)?.type !== "delimiter" ? [empty_delim] : [];
  const ending_delim: Serial[] = result.at(-1)?.type !== "delimiter" ? [empty_delim] : [];

  return beginning_delim.concat(result).concat(ending_delim);
};

const parseFormat = (s: Serial[]): z.infer<typeof FormatString> => {
  const result: z.infer<typeof FormatString> = [];
  let current = null;

  while (true) {
    const head = s[0];
    s = s.slice(1);
    if (!head) break;

    if (head.type === "size") {
      if (!current) current = {
        type: "size_info_match_group",
        for_sizes: [] as number[],
      } as any;
      current.for_sizes.push(head.size_id);
    } else {
      if (head.delim === "/") {
        continue;
      }
      if (current) result.push(current);
      current = null;
      result.push({
        type: "delimiter",
        delimiter: head.delim,
      });
    }
  }
  if (current) result.push(current);

  for (let i = 0; i < result.length; i++) {
    const head = result[i];
    if (head.type === "delimiter" && head.delimiter === "") {
      result.splice(i, 1);
    }
  }

  return result;
}

export const renderFormatString = (format_string: string | undefined, selected_sizes: number[], sizes?: AvailableSizeCollectionItem[]) => {
  if (!format_string) throw "no format string";

  const ast = parseFormatString(format_string, selected_sizes);

  const format: z.infer<typeof FormatString> = ast.flatMap(x => {
    if (x.type === "ignore-group") {
      return [];
    }
    if (x.type === "match-group") {
      return [{
        type: "delimiter" as const,
        delimiter: x.openBracket ?? " ",
      },
      ...x.for_sizes.map(y => {
        if (Array.isArray(y)) {
          return {
            type: "size_info_match_group" as const,
            for_sizes: y,
          };
        } else {
          return {
            type: "delimiter" as const,
            delimiter: y.value,
          }
        }
      }),
      {
        type: "delimiter" as const,
        delimiter: x.closeBracket ?? " ",
      }];
    }
    if (x.optional) {
      return [];
    }
    return [{
      type: "delimiter",
      delimiter: x.value,
    }];
  });

  // because of the above formatting we sometimes get too many spaces
  // in the displayed string, so we just special case that and remove
  // them here (note that we do want these spaces in other cases,
  // such as groups being next to each other or having delimiter === "-")
  for (let i = 0; i < format.length - 2; i++) {
    const a = format[i];
    const b = format[i+1];
    const c = format[i+2];
    if (a.type === "delimiter" && a.delimiter === " "
     && b.type === "delimiter" && b.delimiter === ", "
     && c.type === "delimiter" && c.delimiter === " ") {
         format.splice(i, 3, b);
         i--;
    }
  }

  return renderFormat(format, sizes);
};

const renderFormat = (f: z.infer<typeof FormatString>, sizes?: AvailableSizeCollectionItem[]) => {
  let size_map: Map<number, string> | null = null;
  if (sizes) {
    size_map = new Map();
    for (const s of sizes) {
      size_map.set(s.id, s.size_name);
    }
  }

  let str = "";

  let n = 1;
  while (true) {
    const head = f[0];
    f = f.slice(1);
    if (!head) break;

    if (head.type === "delimiter") {
      if (head.delimiter === "(") {
        str += " (";
      } else if (head.delimiter === ")") {
        str += ") ";
      } else if (head.delimiter === "-") {
        str += " - ";
      } else {
        str += head.delimiter;
      }
    } else {
      if (size_map) {
        str += head.for_sizes.map(size_id =>
          size_map?.get(size_id) ?? "....."
        ).join("/");
      } else {
        // 10, 20, 30, ...etc for display purposes
        str += n * 10;
        n++;
      }
    }
  }

  return str;
};
