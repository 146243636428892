import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarniverse';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Container } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  yarn: Yarn;
}

export const WashAndCare: FC<Props> = (props) => {
  const { yarn } = props;

  const { t } = useTranslation();

  const { WashAndCare } = useSelector(getCollectionDataSelector);

  const washAndCareMemo = useMemo(() => {
    return WashAndCare.filter((item) => (yarn?.wash_and_care || []).includes(item.id));
  }, [yarn, WashAndCare]);

  return (
    <div>
      <Typography variant='h3'>{t('Yarniverse.washAndCare')}</Typography>

      <Container>
        {washAndCareMemo.map((el) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '110px',
              flexWrap: 'wrap',
            }}
            key={el.id}
          >
            <Cell src={getAssetBackendUrl(el.image, 900)} />
            <div
              style={{
                whiteSpace: 'wrap',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              {el.name}
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};
