import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { YarnManufacturerShipmentMethodWithStage } from '@/store/types/yarn-supplier';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { ColorPicker } from '@/components/color-picker';
import { QuantityController } from '@/components/quantity-controller';
import {
  Container,
  Image,
  Info,
  LastRow,
  Left,
  Main,
  NameDescription,
  Price,
  Right,
  SelectColor,
} from './styles';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@/components/button/button';

interface Props {
  name: string;
  description: string;
  image: string;
  price: number;
  quantity: number;
  patternColor?: string;
  colors?: YarnColorCollectionItem[];
  manufacturerId: number;
  onColorChange?: (colorId: number) => void;
  onQuantityChange?: (value: number) => void;
  shipmentMethods?: YarnManufacturerShipmentMethodWithStage[];
  from?: number | null;
  to?: number | null;
}

export const YarnItem: FC<Props> = (props) => {
  const {
    name,
    description,
    image,
    price,
    quantity,
    patternColor,
    colors,
    manufacturerId,
    onColorChange,
    onQuantityChange,
    shipmentMethods,
    from,
    to,
    ...rest
  } = props;

  const collections = useSelector(getCollectionDataSelector);
  const [colorAnchor, setColorAnchor] = useState<HTMLDivElement | null>(null);

  const openPicker = (event: MouseEvent<HTMLDivElement>) => {
    setColorAnchor(event.currentTarget);
  };

  const closePicker = () => {
    setColorAnchor(null);
  };

  const manufacturer = collections.YarnManufacturer.find(manufacturer => manufacturer.id === manufacturerId);
  const manufacturerName = manufacturer ? manufacturer.name : 'Unknown Manufacturer';
  const formattedPrice = new Intl.NumberFormat('nb-NO').format(price);

  return (
    <>
      <Container {...rest}>
        <Main>
          <Image src={getAssetBackendUrl(image, 300)} />
          <Info>
            <NameDescription>
              <Typography variant='h5' fontWeight='bold' mb={0.5} style={{ textDecoration: 'underline' }}>
                {manufacturerName}
              </Typography>
              <Typography variant='body1' mb={0.25}>{name}</Typography>
              {/* <Typography variant='body1'>{patternColor}</Typography> */}
            </NameDescription>
            <Typography variant='body1'>Quantity: <span style={{ fontWeight: 700 }}>{quantity}</span></Typography>
            {/* <QuantityController value={quantity} onChange={onQuantityChange} /> */}
            <LastRow>
              {/* <SelectColor variant='body1' onClick={openPicker}>
                {t('patternizerSteps.advertisement.switchColor')}
              </SelectColor> */}
              <Price variant='h4'>{formattedPrice},- NOK</Price>
            </LastRow>
            {/* <Button>{t('patternizerSteps.advertisement.substituteYarn')}</Button> */}
          </Info>
        </Main>
        {/* <Delivery shipmentMethods={shipmentMethods || []} from={from} to={to} /> */}
      </Container>
      {!!onColorChange && (
        <ColorPicker
          manufacturerName={manufacturerName}
          yarnName={name}
          anchorEl={colorAnchor}
          onClose={closePicker}
          colors={colors}
          onSelect={(colorId) => !!onColorChange && onColorChange(colorId)}
        />
      )}
    </>
  );
};
