import { Input } from '@/components/input/input';
import { Box, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, Grid } from '@mui/material';

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5rem',
  width: '100%',
});

export const GridContainer = styled(Grid)({
  // height: '5rem',
});

export const FilterCol = styled(Grid)(({ theme }) => ({
  // borderRight: '1px solid #000',
  [theme.breakpoints.down('laptop')]: {
    borderRight: 'none',
  },
}));

export const InputStyled = styled(Input)({
  width: '100%',

  '& .MuiInput-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root:before': {
    border: 'none !important',
  },
});

export const SearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #000',
  alignItems: 'center',
  width: '50%',
});

export const SearchIconStyled = styled(SearchIcon)({
  marginLeft: '1rem',
});

export const Title = styled(Typography)(({ theme }) => ({
  padding: '2rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem',
    fontSize: '2.5rem',
  },
}));


export const Yarniverse = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  rowGap: '2rem',
});