import { PATH } from '@/common/constants/path';
import { Collections } from '@/common/interfaces/collection.interface';
import { ItemSearchType } from '@/common/interfaces/search.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { useTranslation } from 'react-i18next';
import {
  clearSearchAction,
  hideSearchAction,
  searchItemsAction,
  setItemSearchTypeAction,
  showSearchAction,
} from '@/store/reducers/search.reducer';
import { getSearchDataSelector } from '@/store/selectors/search.selector';
import { setSearchbarAction,clearSearchbarAction } from '@/store/reducers/system.reducer';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filter } from './components/filter';
import { Result } from './components/result';
import { SearchInput } from './components/search-input';
import { Drawer } from '@mui/material';
import { SlideInFilter } from './components/slide-in-filter';


export const Search = () => {
  const INLINE_RESULT_PAGES:string[] = [PATH.DESIGNERS, PATH.YARNS, PATH.ALLPATTERNS] ;  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [additional, setAdditional] = useState<{ [key: string]: number[] }>({});
  const [Craft, setCraft] = useState<number>();
  const data = useSelector(getSearchDataSelector);

  const handleSearch = () => {
    dispatch(
      searchItemsAction({
        value: searchValue,
        itemType: data.itemSearchType,
        additional: {
          ...additional,
          Craft,
        },
      }),
    );
  };

  //const [searchValue, setSearchValue] = useInputWithDelay('', handleSearch, 500);
  const [searchValue, setSearchValue] = useState('');
  const [searchFromSearchBar, setSearchFromSearchBar] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);



  const handleSearchValue = (value: string) => {
    setAdditional({}); // clearing this from the hardcode Pattern normally added, not sure why it would be neeeded
    if (!searchFromSearchBar) setSearchFromSearchBar(true);
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    dispatch(hideSearchAction());
    dispatch(clearSearchAction());
  };

  const handleCloseAndNavigate = (path:string, id?:string|number) => {
    handleClearFromSearchBar();
    const url = `${path}${id ? '?id=' + id : null}`;
    
    navigate(url);
  }


  useEffect(() => {
    if (!Object.keys(additional).length) return;
    handleSearch();
  }, [additional]);

  useEffect(() => {
    if (!Craft) return;
    handleSearch();
  }, [Craft]);


  const isResult = useMemo(() => {
    return !!data?.patterns?.length || !!data?.yarns?.length || !!data?.resources?.length || !!data?.designers?.length || !!data?.technics?.length;
  }, [data.patterns, data.yarns, data.resources, data.designers, data.technics]);

  const handleClear = () => {
    dispatch(clearSearchAction());
  };

  const handleSetItemSearchType = (type: ItemSearchType | null) => {
    dispatch(setItemSearchTypeAction(type));
  };

  const location = useLocation();
  const isGlobalSearch = !INLINE_RESULT_PAGES.includes(location.pathname);

  useEffect(() => {
    if (searchFromSearchBar  && searchValue.length > 2) {
        handleConfirmSearch();
    }
  }, [location]);

  useEffect(() => {
    setAdditional({Pattern: [1]});
    setCraft(undefined);
  }, [data.itemSearchType]);

  useEffect(() => {
    if (!data.isLoading && !searchCompleted && searchFromSearchBar && searchValue.length > 2) {
        setSearchCompleted(true);
    }
  }, [data]);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const itemSearchType = searchParams.get('itemSearchType');
    if (itemSearchType === ItemSearchType.Designer) {
      setAdditional({});
      handleSetItemSearchType(itemSearchType as ItemSearchType);
    }
  }, [location.search]);



  
  const handleConfirmSearch = () => {
        if (searchValue && searchValue.length > 2) {
            dispatch(setSearchbarAction(searchValue));
            if (isGlobalSearch) handleSearch();
        } 
    }

  const handleClearFromSearchBar = () => {
    setSearchValue('');
    dispatch(clearSearchbarAction());
    setSearchCompleted(false);
    if (PATH.SEARCH === location.pathname) {
        dispatch(clearSearchAction());
    }
  };

  const showResultPanel =  (
    searchFromSearchBar && 
    searchValue && 
    isGlobalSearch &&
    searchCompleted
);

const getSearchPlaceholder = () => {
    let placeholder = 'Search for patterns, designers, techniques or yarn';
    if (location.pathname === PATH.DESIGNERS) placeholder = 'Search for designers';
    if (location.pathname === PATH.YARNS) placeholder = 'Search for yarns';
    if (location.pathname === PATH.ALLPATTERNS) placeholder = 'Search for patterns';
    return placeholder;
}



  return (
      <>

          <SearchInput
              value={searchValue}
              onChange={handleSearchValue}
              onClear={handleClearFromSearchBar}
              onConfirm={handleConfirmSearch}
              placeholder={getSearchPlaceholder()}
          ></SearchInput>

          {showResultPanel ?
              <Result
                  searchBarResult
                  searchCompleted={searchCompleted}
                  patterns={data.patterns}
                  yarns={data.yarns}
                  recourses={data.resources}
                  designers={data.designers}
                  technics={data.technics}
                  close={handleCloseFilter}
                  closeAndNavigate={handleCloseAndNavigate}
                  clear={handleClear}
              />
              :
              <>
              {/* <LegacySearch/> */}
              </>
          }
      </>
  );
};
