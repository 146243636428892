import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import i18n from '@/services/i18n';
import useResource from '@/hooks/api/useResource';
import { PageContainer } from '@/components/page-container/page-container';
import { TermsAndConditionsTabs } from './components/terms-and-conditions-tabs';
import { ContentContainer } from './components/content-container';
import { LanguageList } from '@/common/constants/languages';

export enum TermsPrivacyAboutPageType {
  ABOUT = 'ABOUT',
  PRIVACY = 'PRIVACY',
  TERMS = 'TERMS',
}

const Container = styled(PageContainer)(({ theme }) => ({
  padding: 0,
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
  },
}));

const Title = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  padding: '2rem',
  margin: 0,
  width: '100%',
  maxHeight: '119px', // This is here to fix an issue with the Terms & Conditions page getting a different height than the other pages due to the ES Allianz Font having a different font height for the '&' characters
  borderBottom: '1px solid #000',
  [theme.breakpoints.down('tablet')]: {
    padding: '2rem 1rem',
    fontSize: '2rem',
  },
}));

interface Translation {
  id: number;
  title: string;
  subtitle: string;
  slug: string;
  languages_code: string;
  content: string;
  Articles_id: string;
}

interface ArticleData {
  id: string;
  status: string;
  translations: Translation[];
}

interface Article {
  data: ArticleData[];
}

const TermPrivacyAboutPage = () => {
  const [searchParams] = useSearchParams();

  const [type, setType] = useState<TermsPrivacyAboutPageType>();
  const [articles, setArticles] = useState<Article>();

  const languageCode = i18n.language;
  const dbLanguageCode = LanguageList.find(lang => lang.value === languageCode)?.code || languageCode;
  
  const { data, fetchData } = useResource(`item/Articles/?fields=*,translations.*&deep[translations][_filter][languages_code][_eq]=${dbLanguageCode}`) as { data: Article | null, fetchData: () => void};

  useEffect(() => {
    fetchData();
  }, [fetchData, dbLanguageCode]);

  useEffect(() => {
    if (data) {
      setArticles(data)
    }
  }, [data]);

  useEffect(() => {
    const handleLanguageChange = () => {
      fetchData();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, fetchData]);
  
  useEffect(() => {
    setType(searchParams.get('type') as TermsPrivacyAboutPageType);
  }, [searchParams]);

  const getTitle = useMemo(() => {
    if (!articles) return '';
    const slug = type === TermsPrivacyAboutPageType.ABOUT ? 'about' : 
                  type === TermsPrivacyAboutPageType.PRIVACY ? 'privacy-policy' : 
                  type === TermsPrivacyAboutPageType.TERMS ? 'terms-intro' : '';
    for (const article of articles.data) {
      const translation = article.translations.find(t => t.slug === slug);
      if (translation) {
        return translation.title;
      }
    }
    return 'Title not found';
  }, [type, articles]);

  const getObjectContent = useMemo(() => {
    if (!articles) return { subtitle: 'Content not found', content: '', slug: '' };
    const slug = type === TermsPrivacyAboutPageType.ABOUT ? 'about' : 
                 type === TermsPrivacyAboutPageType.PRIVACY ? 'privacy-policy' : '';
    for (const article of articles.data) {
      const translation = article.translations.find(t => t.slug === slug);
      if (translation) {
        return {
          subtitle: translation.subtitle,
          content: translation.content,
          slug: translation.slug
        };
      }
    }
    return { subtitle: 'Content not found', content: '', slug: '' };
  }, [type, articles]);

  const getArrayContent = useMemo(() => {
    if (!articles) return [];
    const contents = articles.data.flatMap(article =>
      article.translations
        .filter(translation => translation.slug.startsWith('terms-'))
        .map(translation => ({
          subtitle: translation.subtitle,
          content: translation.content,
          slug: translation.slug
        }))
    );
    return contents;
  }, [articles]);

  return (
    <Container>
      <Title>{getTitle}</Title>
      {type === TermsPrivacyAboutPageType.TERMS &&
        <TermsAndConditionsTabs content={getArrayContent} />
      }
      {type === TermsPrivacyAboutPageType.PRIVACY && <ContentContainer content={getObjectContent} />}
      {type === TermsPrivacyAboutPageType.ABOUT && <ContentContainer content={getObjectContent} />}
    </Container>
  );
};

export default TermPrivacyAboutPage;
