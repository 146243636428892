import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const YarniverseStartPage = lazy(() => import('./yarniverse-start-page'));

export const YarniverseStartPageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <YarniverseStartPage />
  </Suspense>
);
