import { Button } from '@/components/button/button';
import { getYarnManufacturerBySupplierAction } from '@/store/reducers/yarn-supplier.reducer';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { getYarniverseDataSelector } from '@/store/selectors/yarniverse.selector';
import { Grid, Input, styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  onPressNext: () => void;
}

const ButtonStyled = styled(Button)({
  width: 'fit-content',
  backgroundColor: '#CEFF1A',
});

const InputStyled = styled(Input)({
  margin: '12rem 0',
  minWidth: '25%',
});

const PageContainer = styled('section')(() => ({
  maxWidth: '1600px',
  margin: '0 auto',
  padding: '2rem',
}));
export const Start: FC<Props> = (props) => {
  const { onPressNext } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const yarn = useSelector(getYarniverseDataSelector);

  const user = useSelector(getCurrentUserSelector);

  const yarnManufacturer = useSelector(getYarniverseDataSelector);

  const [name, setName] = useState(yarn.name);

  const handleNext = () => {
    handleDataSave();
    onPressNext();
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleDataSave = () => {
    dispatch(setYarnDataAction({ ...yarn, name }));
  };

  useEffect(() => {
    if (yarn.name) {
      setName(yarn.name);
    }
  }, [yarn.name]);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(
      getYarnManufacturerBySupplierAction({
        userId: user.id,
      }),
    );
  }, []);

  return (
    <PageContainer>
      <Grid container direction='column'>
        <div>
          <Typography variant='h2'>
            {yarnManufacturer?.name || user?.display_name || user?.first_name || user?.username}
          </Typography>
          <InputStyled
            placeholder={t('Yarniverse.yarnName')}
            value={name}
            onChange={(event) => handleNameChange(event.target.value)}
          />
        </div>

        <ButtonStyled colored onClick={handleNext} disabled={!name}>
          {t('common.next')}
        </ButtonStyled>
      </Grid>
    </PageContainer>
  );
};
