import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';

interface ContainerProps {
  paddingBottom?: string;
  paddingBottomMobile?: string;
  backgroundColor?: string;
  naturalSize?: boolean;
}

export const Container = styled('span')<ContainerProps>(({ theme, paddingBottom, paddingBottomMobile, backgroundColor, naturalSize }) => ({
  position: 'relative',
  width: '100%',
  lineHeight: 0,
  paddingBottom: naturalSize ? undefined : (paddingBottom || '100%'),
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: naturalSize ? undefined : (paddingBottomMobile || '100%'),
  },
  backgroundColor: backgroundColor || theme.palette.secondary.main,
}));

export const Loader = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

interface ImgProps {
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  naturalSize?: boolean;
}

export const Img = styled('img')<ImgProps>(({ objectFit, naturalSize }) => ({
  position: naturalSize ? 'relative' : 'absolute',
  top: naturalSize ? undefined : 0,
  left: naturalSize ? undefined : 0,
  width: '100%',
  height: '100%',
  objectFit: objectFit || 'cover',
}));

interface VideoProps {
  naturalSize?: boolean;
}

export const Video = styled('video')<VideoProps>(({ naturalSize }) => ({
  position: naturalSize ? 'relative' : 'absolute',
  top: naturalSize ? undefined : 0,
  left: naturalSize ? undefined : 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
