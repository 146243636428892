import { UserRole, launchDates, LaunchKey } from '@/common/constants/launchDates';
import { PATH } from '@/common/constants/path';

/**
 * Determines if the current date is before the launch date for a given key.
 * @param key - The user role or path to check.
 * @returns True if the current date is before the launch date, false otherwise.
 * The behaviour can be tested in development environment by setting the environment variable REACT_APP_FORCE_PRODUCTION to 'true' in env.development.
 */

export const isPrelaunch = (key: LaunchKey): boolean => {
  const isProduction = process.env.NODE_ENV === 'production' || process.env.REACT_APP_FORCE_PRODUCTION === 'true';

  if (!isProduction) {
    return false;
  }

  const currentDate = new Date();
  const launchDate = launchDates[key];
  return currentDate < launchDate;
};

/**
 * Determines if the current date is before the launch date for a given user role.
 * @param userRole - The user role to check.
 * @returns True if the current date is before the launch date, false otherwise.
 */
export const isPrelaunchForUserRole = (userRole: UserRole): boolean => {
  return isPrelaunch(userRole);
};

/**
 * Determines if the current date is before the launch date for a given path.
 * @param path - The path to check.
 * @returns True if the current date is before the launch date, false otherwise.
 */
export const isPrelaunchForPath = (path: PATH): boolean => {
  return isPrelaunch(path);
};