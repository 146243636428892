import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';

const NeedleCreatePage = lazy(() => import('./needle-create-page'));

export const NeedleCreatePageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <NeedleCreatePage />
  </Suspense>
);
