import { DirectusFields, getQueryParamsForCollections } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import {
  AlternativeItemCollectionItem,
  Collections,
  FiberCollectionItem,
  NeedleCollectionItem,
  YarnManufacturerCollectionItem,
} from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import {
  addFiberAction,
  addFiberSuccessAction,
  addMeasurementWithNameAction,
  addYarnAlternativeWithItemAction,
  getFibersByYarnIdAction,
  getMeasurementsByPatternSuccessAction,
  updateFiberAction,
} from '@/store/reducers/collection.reducer';
import { FiberWithCollection, normalizeFiber } from '@/utils/normalizeFiber';
import { QueryMany, TransportResponse } from '@directus/sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  addAlternativeItemsAction,
  addAlternativeItemsSuccessAction,
  addMeasurementAction,
  addMeasurementSuccessAction,
  addYarnAlternativeAction,
  addYarnAlternativeItemAction,
  addYarnAlternativeItemSuccessAction,
  addYarnAlternativeSuccessAction,
  collectionErrorActions,
  getCollectionsAction,
  getCollectionsErrorAction,
  getCollectionsSuccessAction,
  getFibersByYarnSuccessAction,
  getMeasurementsByPatternAction,
  getNeedlesByPatternAction,
  getNeedlesByPatternErrorAction,
  getNeedlesByPatternSuccessAction,
  getYarnAlternativeByPatternAction,
  getYarnAlternativeByPatternSuccessAction,
  getYarnByManufacturerAction,
  getYarnByManufacturerSuccessAction,
  removeAlternativeItemsAction,
  removeAlternativeItemsSuccessAction,
  removeFiberAction,
  removeFiberSuccessAction,
  removeMeasurementAction,
  removeMeasurementSuccessAction,
  removeNeedleAction,
  removeNeedleSuccessAction,
  removeYarnAlternativeAction,
  removeYarnAlternativeSuccessAction,
  searchYarnManufacturerAction,
  searchYarnManufacturerSuccessAction,
  setAlternativeItemsColorAction,
  setNeedleAction,
  setQuantityWithSizeAction,
  setQuantityWithSizeSuccessAction,
  updateAlternativeItemsAction,
  updateAlternativeItemsSuccessAction,
  updateMeasurementNameAction,
  updateMeasurementNameSuccessAction,
  updateMeasurementsAction,
  updateMeasurementSizeAction,
  updateMeasurementSizeSuccessAction,
  updateMeasurementsSuccessAction,
  updateYarnMeasurementAction,
  updateYarnMeasurementSizeAction,
  updateYarnMeasurementSizeSuccessAction,
  updateYarnMeasurementSuccessAction,
} from '../reducers/collection.reducer';
import { setPatternDataAction } from '../reducers/pattern.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import { updateYarnStateData } from '../reducers/yarniverse.reducer';
import { getYarnAlternativeDataSelector } from '../selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '../selectors/pattern.selector';
import {
  MeasurementWithStage,
  NeedleCollectionItemWithStage,
  YarnAlternativeWithStage,
  YarnCollectionItemWithStage,
} from '../types/collection';
import { Yarn } from '../types/yarniverse';
import {getSearchbarDataSelector} from '@/store/selectors/system.selector';
import {
  AvailableSizeCollectionItem,
  MeasurementAvailableSizesCollectionItem,
  MeasurementCollectionItem,
  YarnAlternativeCollectionItem,
  YarnMeasurementCollectionItem,
} from './../../common/interfaces/collection.interface';

function* getCollectionsRequest(api: ApiType, action: ReturnType<typeof getCollectionsAction>) {
    const searchbarValue: string = yield select(getSearchbarDataSelector);
    const searchableCollections = [Collections.Yarn];
  let types = [];
  if (!Array.isArray(action.payload)) {
    types.push(action.payload);
  } else {
    types = action.payload;
  }

  const data = {};
  for (let i = 0; i < types.length; i++) {
    const type = types[i];

    let requestOptions:any = getQueryParamsForCollections(type);
    if (searchableCollections.includes(type)) {
        if (searchbarValue) {
            requestOptions['search'] = searchbarValue;
        }
    }
    
    
    try {
      const response: TransportResponse<unknown> = yield call(
        api.getCollection,
        type,
        requestOptions,
        1000,
      );
      if (response.data) {
        //@ts-ignore
        data[type] = response.data;
      } else {
        throw new Error();
      }
    } catch (error) {
      yield put(getCollectionsErrorAction(error));
    }
  }

  yield put(getCollectionsSuccessAction(data));
}

function* getNeedlesByPatternRequest(
  api: ApiType,
  action: ReturnType<typeof getNeedlesByPatternAction>,
) {
  const patternId = action.payload;

  try {
    const response: TransportResponse<NeedleCollectionItemWithStage[]> = yield call(
      api.getCollection,
      Collections.Needle,
      {
        filter: {
          pattern: { _eq: patternId },
        },
        fields: '*, needle_type.*, needle_size.*, cable_length.*',
      },
    );

    if (response.data) {
      yield put(getNeedlesByPatternSuccessAction(response.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(getNeedlesByPatternErrorAction(error));
  }
}

function* setNeedleRequest(api: ApiType, action: ReturnType<typeof setNeedleAction>) {
  const { needle, pattern } = action.payload;
  try {
    const response: NeedleCollectionItem = yield call(
      needle.id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.Needle,
      needle,
    );

    if (response.pattern) {
      yield put(
        setPatternDataAction({
          ...pattern,
          needle: pattern.needle?.length ? [...pattern.needle, response.id] : [response.id],
        }),
      );
      yield put(getNeedlesByPatternAction(response.pattern));
    } else {
      throw new Error(Errors.Default);
    }
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* removeNeedleRequest(api: ApiType, action: ReturnType<typeof removeNeedleAction>) {
  const { id, pattern } = action.payload;

  try {
    yield call(api.removeCollectionItem, Collections.Needle, id);
    yield put(
      setPatternDataAction({
        ...pattern,
        needle: pattern.needle?.filter((el) => el !== id),
      }),
    );
    yield put(removeNeedleSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addMeasurementRequest(api: ApiType, action: ReturnType<typeof addMeasurementAction>) {
  try {
    const { patternId, availableSizes } = action.payload;

    const responseAvailableSizes: TransportResponse<MeasurementAvailableSizesCollectionItem[]> =
      yield call(
        api.createCollectionItems,
        Collections.MeasurementAvailableSizes,
        availableSizes.map((id) => ({
          status: 'published',
          size_chart: id,
          value: '',
        })),
      );

    if (!responseAvailableSizes.data) {
      throw new Error();
    }

    const responseMeasurement: MeasurementCollectionItem = yield call(
      api.createCollectionItem,
      Collections.Measurements,
      {
        status: 'published',
        measurement_values: responseAvailableSizes.data.map((el) => el.id),
        pattern: patternId,
      },
    );

    if (!responseMeasurement) {
      throw new Error(Errors.Default);
    }

    yield put(addMeasurementSuccessAction());
    yield put(getMeasurementsByPatternAction(patternId));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addMeasurementWithNameRequest(api: ApiType, action: ReturnType<typeof addMeasurementWithNameAction>) {
  try {
    const { patternId, availableSizes, name } = action.payload;

    const responseAvailableSizes: TransportResponse<MeasurementAvailableSizesCollectionItem[]> =
      yield call(
        api.createCollectionItems,
        Collections.MeasurementAvailableSizes,
        availableSizes.map((id) => ({
          status: 'published',
          size_chart: id,
          value: '',
        })),
      );

    if (!responseAvailableSizes.data) {
      throw new Error();
    }

    const responseMeasurement: MeasurementCollectionItem = yield call(
      api.createCollectionItem,
      Collections.Measurements,
      {
        status: 'published',
        measurement_values: responseAvailableSizes.data.map((el) => el.id),
        pattern: patternId,
      },
    );

    if (!responseMeasurement) {
      throw new Error(Errors.Default);
    }

    console.log('responseMeasurement', responseMeasurement);

    yield put(addMeasurementSuccessAction());

    try {
      const response: MeasurementCollectionItem = yield call(
        api.updateCollectionItem,
        Collections.Measurements,
        {
          id: responseMeasurement.id,
          name,
        },
      );
  
      if (!response) {
        throw new Error(Errors.Default);
      }
  
      yield put(updateMeasurementNameSuccessAction());
      yield put(getMeasurementsByPatternAction(patternId));
    } catch (error: any) {
      yield put(collectionErrorActions());
      yield put(displayErrorAction(error?.message as string));
    }

  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* getMeasurementsByPatternRequest(
  api: ApiType,
  action: ReturnType<typeof getMeasurementsByPatternAction>,
) {
  try {
    const id = action.payload;

    const response: TransportResponse<MeasurementWithStage[]> = yield call(
      api.getCollection,
      Collections.Measurements,
      {
        filter: {
          pattern: { _eq: id },
        },
        fields: DirectusFields.Measurements,
      },
    );

    if (!response.data) {
      throw new Error(Errors.Default);
    }

    yield put(getMeasurementsByPatternSuccessAction(response.data));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateMeasurementRequest(
  api: ApiType,
  action: ReturnType<typeof updateMeasurementsAction>,
) {
  try {
    const { patternId, measurements, availableSizes } = action.payload;

    for (const measurement of measurements) {
      // Added sizes if not exist in measurement
      const notCreatedSizes = [];
      for (const size of availableSizes) {
        if (!measurement.measurement_values.map((el) => el?.size_chart?.id).includes(size)) {
          const responseAvailableSizes: MeasurementAvailableSizesCollectionItem = yield call(
            api.createCollectionItem,
            Collections.MeasurementAvailableSizes,
            {
              status: 'published',
              size_chart: size,
              value: '',
            },
          );
          notCreatedSizes.push(responseAvailableSizes);
        }
      }

      // Remove sizes if not selected by user
      for (const size of measurement.measurement_values) {
        if (!availableSizes.includes(size?.size_chart?.id)) {
          yield call(api.removeCollectionItem, Collections.MeasurementAvailableSizes, size.id);
        }
      }

      // Updated measurement
      if (notCreatedSizes.length) {
        yield call(api.updateCollectionItem, Collections.Measurements, {
          id: measurement.id,
          measurement_values: [
            ...measurement.measurement_values.map((el) => el.id),
            ...notCreatedSizes.map((el) => el?.id),
          ],
          name: measurement.name,
        });
      }
    }

    yield put(updateMeasurementsSuccessAction());
    yield put(getMeasurementsByPatternAction(patternId as number));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateMeasurementSizeRequest(
  api: ApiType,
  action: ReturnType<typeof updateMeasurementSizeAction>,
) {
  try {
    const { id, value } = action.payload;

    const response: unknown = yield call(
      api.updateCollectionItem,
      Collections.MeasurementAvailableSizes,
      {
        id,
        value,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(updateMeasurementSizeSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateMeasurementNameRequest(
  api: ApiType,
  action: ReturnType<typeof updateMeasurementNameAction>,
) {
  try {
    const { id, name } = action.payload;

    const response: MeasurementCollectionItem = yield call(
      api.updateCollectionItem,
      Collections.Measurements,
      {
        id,
        name,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(updateMeasurementNameSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* removeMeasurementRequest(
  api: ApiType,
  action: ReturnType<typeof removeMeasurementAction>,
) {
  const { id, patternId } = action.payload;

  try {
    yield call(api.removeCollectionItem, Collections.Measurements, id);

    yield put(removeMeasurementSuccessAction());
    yield put(getMeasurementsByPatternAction(patternId));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* getYarnAlternativeByPatternRequest(api: ApiType) {
  const pattern: Pattern = yield select(getPatternDataSelector);

  try {
    if (!pattern.id) {
      throw new Error(Errors.Default);
    }
    const response: TransportResponse<YarnAlternativeWithStage[]> = yield call(
      api.getCollection,
      Collections.YarnAlternatives,
      {
        filter: {
          pattern: { _eq: pattern.id },
        },
        fields: [
          '*, alternativ_items.*',
          '*, alternativ_items.yarn.*',
          '*, alternativ_items.color.*',
          '*, alternativ_items.yarn_measurement.*',
          '*, alternativ_items.yarn.colors.*',
          '*, alternativ_items.yarn.prices.*',
          '*, alternativ_items.yarn.prices.currency.*',
          '*, alternativ_items.quantity_with_size.*',
        ],
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getYarnAlternativeByPatternSuccessAction(response.data));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addYarnAlternativeRequest(api: ApiType) {
  const pattern: Pattern = yield select(getPatternDataSelector);
  try {
    const responseYarnAlternatives: YarnAlternativeCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnAlternatives,
      {
        pattern: pattern.id,
        name: '',
      },
    );

    if (!responseYarnAlternatives) {
      throw new Error();
    }

    yield put(
      setPatternDataAction({
        id: pattern.id as number,
        alternatives: [...(pattern.alternatives || []), responseYarnAlternatives.id],
      }),
    );
    yield put(addYarnAlternativeSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addYarnAlternativeWithItemRequest(
  api: ApiType,
  action: ReturnType<typeof addYarnAlternativeWithItemAction>,
) {
  const pattern: Pattern = yield select(getPatternDataSelector);
  try {
    const responseYarnAlternatives: YarnAlternativeCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnAlternatives,
      {
        pattern: pattern.id,
        name: '',
      },
    );

    if (!responseYarnAlternatives) {
      throw new Error();
    }

    yield put(
      setPatternDataAction({
        id: pattern.id as number,
        alternatives: [...(pattern.alternatives || []), responseYarnAlternatives.id],
      }),
    );
    yield put(addYarnAlternativeSuccessAction());

    try {
      const { yarnId, colorId } = action.payload;
  
      const availableSizes: number[] = yield select(getPatternAvailableSizesSelector);
  
      const responseMeasurement: TransportResponse<YarnMeasurementCollectionItem[]> = yield call(
        api.createCollectionItems,
        Collections.YarnMeasurement,
        availableSizes.map((el) => ({
          size: el,
          value: '',
        })),
      );
  
      if (!responseMeasurement.data) {
        throw new Error(Errors.Default);
      }
  
      const responseAlternative: AlternativeItemCollectionItem = yield call(
        api.createCollectionItem,
        Collections.AlternativeItem,
        {
          yarn: yarnId,
          color: colorId,
          yarn_measurement: responseMeasurement.data.map((el) => el.id),
          alternative: responseYarnAlternatives.id,
        },
      );
  
      if (!responseAlternative) {
        throw new Error(Errors.Default);
      }
  
      yield put(addYarnAlternativeItemSuccessAction());
      yield put(getYarnAlternativeByPatternAction());
    } catch (error: any) {
      yield put(collectionErrorActions());
      yield put(displayErrorAction(error?.message as string));
    }
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addYarnAlternativeItemActionRequest(
  api: ApiType,
  action: ReturnType<typeof addYarnAlternativeItemAction>,
) {
  try {
    const { alternativeId, yarnId, colorId } = action.payload;

    const availableSizes: number[] = yield select(getPatternAvailableSizesSelector);

    const responseMeasurement: TransportResponse<YarnMeasurementCollectionItem[]> = yield call(
      api.createCollectionItems,
      Collections.YarnMeasurement,
      availableSizes.map((el) => ({
        size: el,
        value: '',
      })),
    );

    if (!responseMeasurement.data) {
      throw new Error(Errors.Default);
    }

    const responseAlternative: AlternativeItemCollectionItem = yield call(
      api.createCollectionItem,
      Collections.AlternativeItem,
      {
        yarn: yarnId,
        color: colorId,
        yarn_measurement: responseMeasurement.data.map((el) => el.id),
        alternative: alternativeId,
      },
    );

    if (!responseAlternative) {
      throw new Error(Errors.Default);
    }

    yield put(addYarnAlternativeItemSuccessAction());
    yield put(getYarnAlternativeByPatternAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateAlternativeItemRequest(
  api: ApiType,
  action: ReturnType<typeof updateAlternativeItemsAction>,
) {
  try {
    const { yarn, name, alternativeItem } = action.payload;

    yield call(
      api.updateCollectionItem,
      Collections.AlternativeItem,
      yarn
        ? {
          id: alternativeItem,
          yarn,
        }
        : {
          id: alternativeItem,
          name,
        },
    );

    yield put(getYarnAlternativeByPatternAction());
    yield put(updateAlternativeItemsSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateYarnMeasurementRequest(
  api: ApiType,
  action: ReturnType<typeof updateYarnMeasurementAction>,
) {
  try {
    const measurement = action.payload;

    yield call(api.updateCollectionItem, Collections.YarnMeasurement, measurement);

    yield put(updateYarnMeasurementSuccessAction(action.payload));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateYarnMeasurementSizeActionRequest(api: ApiType) {
  try {
    const YarnAlternatives: YarnAlternativeWithStage[] = yield select(
      getYarnAlternativeDataSelector,
    );
    const availableSizes: number[] = yield select(getPatternAvailableSizesSelector);

    for (const alternative of YarnAlternatives) {
      for (const item of alternative.alternativ_items) {
        for (const size of availableSizes) {
          if (!item.yarn_measurement.map((el) => el.size).includes(size)) {
            yield call(api.createCollectionItem, Collections.YarnMeasurement, {
              size: size,
              value: '',
              alternative_items: item.id,
            });
          }
        }

        // for (const size of item.yarn_measurement) {
        //   if (!AvailableSize.map((el) => el.id).includes(size.size)) {
        //     yield call(api.removeCollectionItem, Collections.YarnMeasurement, size.id);
        //   }
        // }
      }
    }

    yield put(getYarnAlternativeByPatternAction());
    yield put(updateYarnMeasurementSizeSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* removeYarnAlternativeRequest(
  api: ApiType,
  action: ReturnType<typeof removeYarnAlternativeAction>,
) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    const id = action.payload;

    yield call(api.removeCollectionItem, Collections.YarnAlternatives, id);

    yield put(
      setPatternDataAction({
        id: pattern.id as number,
        alternatives: [...(pattern.alternatives || []).filter((el) => el !== id)],
      }),
    );
    yield put(removeYarnAlternativeSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addAlternativeItemRequest(
  api: ApiType,
  action: ReturnType<typeof addAlternativeItemsAction>,
) {
  try {
    const yarnAlternative = action.payload;

    const availableSizes: AvailableSizeCollectionItem[] = yield select(
      getPatternAvailableSizesSelector,
    );

    const yarnMeasurements = [];
    for (const size of availableSizes) {
      const response: YarnMeasurementCollectionItem = yield call(
        api.createCollectionItem,
        Collections.YarnMeasurement,
        {
          size: size.id,
        },
      );
      yarnMeasurements.push(response.id);
    }

    yield call(api.createCollectionItem, Collections.AlternativeItem, {
      alternative: yarnAlternative.id,
      yarn_measurement: yarnMeasurements,
    });

    yield put(getYarnAlternativeByPatternAction());
    yield put(addAlternativeItemsSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* removeAlternativeItemsRequest(
  api: ApiType,
  action: ReturnType<typeof removeAlternativeItemsAction>,
) {
  try {
    const id = action.payload;

    yield call(api.removeCollectionItem, Collections.AlternativeItem, id);

    yield put(getYarnAlternativeByPatternAction());
    yield put(removeAlternativeItemsSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* setAlternativeItemsColorRequest(
  api: ApiType,
  action: ReturnType<typeof setAlternativeItemsColorAction>,
) {
  try {
    const { alternative, color } = action.payload;
    yield call(api.updateCollectionItem, Collections.AlternativeItem, {
      id: alternative,
      color,
    });
    yield put(getYarnAlternativeByPatternAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* setQuantityWithSize(api: ApiType, action: ReturnType<typeof setQuantityWithSizeAction>) {
  try {
    const { alternativeItemId, quantityId, value, sizeId } = action.payload;

    yield call(
      quantityId ? api.updateCollectionItem : api.createCollectionItem,
      Collections.QuantityWithSize,
      {
        alternative_item: alternativeItemId,
        id: quantityId,
        quantity: value,
        size_chart: sizeId,
      },
    );

    yield put(getYarnAlternativeByPatternAction());
    yield put(setQuantityWithSizeSuccessAction());
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* getFibersByYarnIdRequest(
  api: ApiType,
  action: ReturnType<typeof getFibersByYarnIdAction>,
) {
  const yarnId = action.payload;

  try {
    const response: { fiber: FiberWithCollection[] } = yield call(
      api.getCollectionItemById,
      Collections.Yarn,
      yarnId,
      {
        fields: DirectusFields.YarnFiber,
      },
    );

    if (!response) {
      throw new Error('Something went wrong');
    }
    yield put(getFibersByYarnSuccessAction(response.fiber.map((el) => normalizeFiber(el))));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* addFiberRequest(api: ApiType, action: ReturnType<typeof addFiberAction>) {
  const { yarn } = action.payload;
  console.log('DEBUGPRINT[8]: collection.saga.ts:727: yarn=', yarn);

  try {
    if (!yarn.id) throw new Error('Something went wrong');

    const responseFiber: FiberCollectionItem = yield call(
      api.createCollectionItem,
      Collections.Fiber,
      {},
    );
    console.log('DEBUGPRINT[9]: collection.saga.ts:733: responseFiber=', responseFiber);

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarn.id,
      fiber: [...(yarn?.fiber || []), { fiber_id: responseFiber.id }],
    });
    console.log('DEBUGPRINT[10]: collection.saga.ts:740: response=', response);

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(addFiberSuccessAction(responseFiber));
    yield put(updateYarnStateData(response));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* searchYarnManufacturerRequest(
  api: ApiType,
  action: ReturnType<typeof searchYarnManufacturerAction>,
) {
  try {
    const search = action.payload;

    const options: QueryMany<unknown> = {};

    if (search) {
      options.filter = {
        name: { _contains: search },
      };
    }

    const response: TransportResponse<YarnManufacturerCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnManufacturer,
      options,
    );

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    yield put(searchYarnManufacturerSuccessAction(response.data));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* getYarnByManufacturerRequest(
  api: ApiType,
  action: ReturnType<typeof getYarnByManufacturerAction>,
) {
  try {
    const manufactureId = action.payload;

    const response: TransportResponse<YarnCollectionItemWithStage[]> = yield call(
      api.getCollection,
      Collections.Yarn,
      {
        filter: {
          yarn_manufacturer: { _eq: manufactureId },
        },
        fields: DirectusFields.Yarn,
      },
    );

    if (!response.data) {
      throw new Error('Something went wrong');
    }

    yield put(getYarnByManufacturerSuccessAction(response.data));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* removeFiberRequest(api: ApiType, action: ReturnType<typeof removeFiberAction>) {
  try {
    const { yarn, fiberId } = action.payload;

    const response: {
      fiber: {
        Yarn_id: number;
        fiber_id: number;
        id: number;
      }[];
    } = yield call(api.getCollectionItemById, Collections.Yarn, yarn.id as number, {
      fields: ['fiber.*'],
    });

    const fibers = response.fiber.filter((el) => el.fiber_id !== fiberId);

    yield call(api.updateCollectionItem, Collections.Yarn, {
      ...yarn,
      fiber: fibers,
    });

    yield call(api.removeCollectionItem, Collections.Fiber, fiberId);

    yield put(removeFiberSuccessAction());
    yield put(getFibersByYarnIdAction(yarn.id as number));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

function* updateFiberRequest(api: ApiType, action: ReturnType<typeof updateFiberAction>) {
  try {
    const { fiber, yarnId } = action.payload;

    yield call(api.updateCollectionItem, Collections.Fiber, fiber);

    yield put(getFibersByYarnIdAction(yarnId));
  } catch (error: any) {
    yield put(collectionErrorActions());
    yield put(displayErrorAction(error?.message as string));
  }
}

export const collectionSaga = function* (api: ApiType) {
  yield all([takeLatest(getCollectionsAction.type, getCollectionsRequest, api)]);
  yield all([takeLatest(getNeedlesByPatternAction.type, getNeedlesByPatternRequest, api)]);
  yield all([takeLatest(setNeedleAction.type, setNeedleRequest, api)]);
  yield all([takeLatest(removeNeedleAction.type, removeNeedleRequest, api)]);
  yield all([takeLatest(addMeasurementAction.type, addMeasurementRequest, api)]);
  yield all([takeLatest(addMeasurementWithNameAction.type, addMeasurementWithNameRequest, api)]);
  yield all([
    takeLatest(getMeasurementsByPatternAction.type, getMeasurementsByPatternRequest, api),
  ]);
  yield all([takeLatest(updateMeasurementsAction.type, updateMeasurementRequest, api)]);
  yield all([takeLatest(updateMeasurementSizeAction.type, updateMeasurementSizeRequest, api)]);
  yield all([takeLatest(updateMeasurementNameAction.type, updateMeasurementNameRequest, api)]);
  yield all([takeLatest(removeMeasurementAction.type, removeMeasurementRequest, api)]);
  yield all([
    takeLatest(getYarnAlternativeByPatternAction.type, getYarnAlternativeByPatternRequest, api),
  ]);
  yield all([takeLatest(addYarnAlternativeAction.type, addYarnAlternativeRequest, api)]);
  yield all([takeLatest(addYarnAlternativeWithItemAction.type, addYarnAlternativeWithItemRequest, api)]);
  yield all([takeLatest(updateAlternativeItemsAction.type, updateAlternativeItemRequest, api)]);
  yield all([takeLatest(updateYarnMeasurementAction.type, updateYarnMeasurementRequest, api)]);
  yield all([
    takeLatest(updateYarnMeasurementSizeAction.type, updateYarnMeasurementSizeActionRequest, api),
  ]);
  yield all([takeLatest(removeYarnAlternativeAction.type, removeYarnAlternativeRequest, api)]);
  yield all([takeLatest(addAlternativeItemsAction.type, addAlternativeItemRequest, api)]);
  yield all([takeLatest(removeAlternativeItemsAction.type, removeAlternativeItemsRequest, api)]);
  yield all([
    takeLatest(setAlternativeItemsColorAction.type, setAlternativeItemsColorRequest, api),
  ]);
  yield all([takeLatest(setQuantityWithSizeAction.type, setQuantityWithSize, api)]);
  yield all([takeLatest(getFibersByYarnIdAction.type, getFibersByYarnIdRequest, api)]);
  yield all([takeLatest(addFiberAction.type, addFiberRequest, api)]);
  yield all([takeLatest(getYarnByManufacturerAction.type, getYarnByManufacturerRequest, api)]);
  yield all([
    takeLatest(addYarnAlternativeItemAction.type, addYarnAlternativeItemActionRequest, api),
  ]);
  yield all([takeLatest(searchYarnManufacturerAction.type, searchYarnManufacturerRequest, api)]);
  yield all([takeLatest(removeFiberAction.type, removeFiberRequest, api)]);
  yield all([takeLatest(updateFiberAction.type, updateFiberRequest, api)]);
};
