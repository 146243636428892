import { styled } from '@mui/material/styles';
import { PageContainer } from '@/components/page-container/page-container';
import { TextEditor } from '@/components/text-editor/text-editor';
import { Input, ToggleButton } from '@mui/material';

export const PageContainerEl = styled(PageContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '20% 50% 30%',
  gridTemplateRows: 'auto',
  padding: 0,
  // borderTop: '1px solid #000',
  // [theme.breakpoints.down('tablet')]: {
  //   display: 'block',
  // },
}));

export const TopSection = styled('section')({
  borderTop: '1px solid #000',
  padding: '1.25rem 0',
});

export const TopContainer = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ContentEl = styled('div')({
  width: '100%',
  padding: '0 2rem 2rem 2rem',
  minHeight: '25rem',
});

export const StartContainer = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: '1rem 0',
  display: 'flex',
  alignItems: 'center',
  columnGap: '0.5rem',
}));

export const PatternName = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  textTransform: 'uppercase',
  fontSize: '1.9rem',
  fontWeight: 'bold',
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: '0 1 auto',
}));

export const TextEditorEl = styled(TextEditor)({
  width: '100%',
  minHeight: '15rem',
  display: 'flex',
  flexDirection: 'column',
  '.ql-container': {
    flexGrow: 1,
  },
});

export const StyledInput = styled(Input)(({ theme }) => ({
  ...theme.typography.h3,
  // marginBottom: '0.5rem',
  '& .MuiInputBase-input::placeholder': {
    fontStyle: 'italic',
  },
}));

export const StepName = styled('h3')(({ theme }) => ({
  display: 'inline-block',
  ...theme.typography.h3,
  margin: '2rem 0',
  paddingBottom: '0.5rem',
  paddingRight: '1rem',
  borderBottom: '1px solid #000',
}));

export const StyledButton = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  padding: '0.5rem',
  cursor: 'pointer',
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '1rem',
});

export const StepHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1rem',
  margin: '2rem 0',
});

export const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const DropdownContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: '1rem',
  fontSize: '1.125rem', // 18px in rem
});

export const LastContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  fontSize: '1.125rem',
  marginLeft: 'auto',
  flexShrink: 0,
});

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '5rem',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&:hover': {
    border: '1px solid #000',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const LinkButton = styled('button')(({ theme }) => ({
  ...theme.typography.body1,
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  width: 'fit-content',
  padding: 0,
  '&:disabled': {
    color: theme.palette.text.disabled,
    cursor: 'default',
  },
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 1.5rem',
  height: '3.5rem',
  borderBottom: '1px solid #000',
  gap: '3rem',
  [theme.breakpoints.down('tablet')]: {
    padding: '0 1rem',
    gap: '2rem',
  },
}));

export const ZoomContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  fontSize: '1.125rem',
  flexShrink: 0,
  whiteSpace: 'nowrap',
});