import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { TransportResponse } from '@directus/sdk';
import { DirectusFields } from '@/common/constants/directus-fields';
import {
  getUploadedYarnAction,
  getUploadedYarnErrorAction,
  getUploadedYarnSuccessAction,
} from '../reducers/yarn-uploaded.reducer';
import { YarnWithStage } from '../types/yarniverse';

function* getRecentYarnUploadsRequest(api: ApiType) {
  try {
    const response: TransportResponse<YarnWithStage[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Yarn,
      {
        fields: DirectusFields.Yarn,
        limit: 5,
        filter: {
          status: {
            _eq: 'published',
          },
          _or: [
            {
              main_image: {
                _nnull: true,
              },
            },
            {
              images: {
                _nnull: true,
              },
            },
          ],
          yarn_manufacturer: {
            isAvailableOnSite: {
              _eq: true,
            },
          },
        },
        sort: ['-date_created' as never],
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getUploadedYarnSuccessAction(response.data));
  } catch (error: any) {
    yield put(getUploadedYarnErrorAction());
  }
}

export const yarnUploadedSaga = function* (api: ApiType) {
  yield all([takeLatest(getUploadedYarnAction.type, getRecentYarnUploadsRequest, api)]);
};
