import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import s from './styles.module.css'; 


interface Props {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  onConfirm: () => void;
}

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '3.875rem',
  borderBottom: '1px solid #000',
  cursor: 'pointer',
  [theme.breakpoints.down('tablet')]: {
    height: '2.5rem',
  },
}));


const SearchIconContainer = styled('div')(({ theme }) => ({
  transform: 'rotate(90deg)',
  margin: '0 2rem',
  '& svg': {
    transform: 'scale(1.5)',
  },
  [theme.breakpoints.down('tablet')]: {
    margin: '0.5rem',
  },
}));


  const CloseIconContainer = styled('div')(({ theme }) => ({
    /* position: 'absolute',
    right: '0',
    zIndex: '100', */

  }));

const InputStyled = styled('input')(({ theme }) => ({
  flex: '1',
  height: 'calc(100% - 2px)',
  border: 'none',
  outline: 'none',
  width: '100%',
  //minWidth: '400px',
  ...theme.typography.body1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: 'transparent',
}));




export const SearchInput: FC<Props> = (props) => {
  const { value, onChange, onClear, onConfirm, placeholder } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
        if (inputRef.current) {
            inputRef.current.blur(); 
          }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    setTimeout(() => {
        if (!inputRef.current || document.activeElement === inputRef.current) {
          // Focus didn't move away, don't call onConfirm
          return;
        }
        onConfirm();
      }, 0);
  };  

  const handleClear = (event: React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<SVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClear) onClear();
    }; 

    const preventBlurOnMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
      };  

  return (
      <Container >
          <SearchIconContainer>
              <SearchIcon />
          </SearchIconContainer>
          <div className={`${s.clearableInput} ${isFocused || value ? s.focused : ''}`} onBlur={handleBlur}>
              <InputStyled
                  ref={inputRef}
                  placeholder={placeholder}
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                  onKeyDown={handleKeyDown}
                  onFocus={()=>setIsFocused(true)}
                  onBlur={()=>setIsFocused(false)}
              />
              {value && onClear &&
                  <CloseIconContainer onMouseDown={preventBlurOnMouseDown} onTouchStart={preventBlurOnMouseDown}>
                      <CloseIcon onClick={handleClear} onTouchStart={handleClear} />
                  </CloseIconContainer>
              }
          </div>


      </Container>
  );
};
