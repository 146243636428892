import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YarnWithStage } from '../types/yarniverse';

export interface YarnPromotedState {
  isPromotedYarnLoading: boolean;
  promotedYarn: YarnWithStage[];
  error: boolean;
}

const initialState: YarnPromotedState = {
  isPromotedYarnLoading: false,
  promotedYarn: [],
  error: false,
};

export const yarnPromotedSlice = createSlice({
  name: 'yarn-promoted',
  initialState,
  reducers: {
    // Get yarn by id
    getPromotedYarnAction: (state) => {
      state.isPromotedYarnLoading = true;
    },
    getPromotedYarnSuccessAction: (state, action: PayloadAction<YarnWithStage[]>) => {
      state.isPromotedYarnLoading = false;
      state.promotedYarn = action.payload;
    },
    getPromotedYarnErrorAction: (state) => {
      state.isPromotedYarnLoading = false;
    },
  },
});

export const { getPromotedYarnAction, getPromotedYarnSuccessAction, getPromotedYarnErrorAction } =
  yarnPromotedSlice.actions;

export const yarnPromotedReducer = yarnPromotedSlice.reducer;
