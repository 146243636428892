import {
  Accordion as MiAccordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import React, { CSSProperties, FC } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
  renderSummary: () => React.ReactNode;
  renderDetails: () => React.ReactNode;
  detailStyle?: CSSProperties;
  summaryStyle?: CSSProperties;
  className?: string;
}

const AccordionStyled = styled(MiAccordion)(({ theme }) => ({
  borderBottomLeftRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  borderTopLeftRadius: '0 !important',
  borderTopRightRadius: '0 !important',
  boxShadow: 'none',
  borderBottom: '1px solid #000',
  padding: '1rem 1.3rem',
  margin: '0 !important',
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem',
  },
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    padding: '0',
    '.MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    padding: '0',
  },
}));

const ExpandLessIconStyled = styled(ExpandLessIcon)({
  transform: 'rotate(90deg)',
  marginRight: '1rem',
  width: '2rem',
  height: '2rem',
});

const ExpandMoreIconStyled = styled(ExpandLessIcon)({
  transform: 'rotate(180deg)',
  marginRight: '1rem',
  width: '2rem',
  height: '2rem',
});

const SummaryText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

export const Accordion: FC<Props> = (props: Props) => {
  const { renderSummary, renderDetails, detailStyle, className } = props;
  const [expanded, setExpanded] = React.useState(false);
  const onExpand = () => setExpanded((p) => !p);
  return (
    <AccordionStyled expanded={expanded} onChange={onExpand} className={className}>
      <AccordionSummaryStyled>
        {expanded ? <ExpandMoreIconStyled /> : <ExpandLessIconStyled />}
        <SummaryText>{renderSummary()}</SummaryText>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled style={detailStyle}>{renderDetails()}</AccordionDetailsStyled>
    </AccordionStyled>
  );
};
