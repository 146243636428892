import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { PatternView } from '@/components/pattern-view/pattern-view';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { PageContainerStyled } from '../pattern-name';
import { NextButton } from '../next-button';

export interface ManualPatternStepProps {
  onPressNextButton: () => void;
}

export const ManualPatternStep: FC<ManualPatternStepProps> = ({ onPressNextButton }) => {
  const { currentStep } = useSelector(getPatternizerSelector);
  const pattern = useSelector(getPatternDataSelector);

  const tabIsOpened = PatternizerStepsKeys.MANUAL_PATTERN_STEP === currentStep;

  return (
    <>
      {tabIsOpened && pattern.id && (
        <>
          <PageContainerStyled borderBottom>
            <PatternView patternId={pattern.id} />
          </PageContainerStyled>
          <NextButton align='right' onPressNext={onPressNextButton} />
        </>
      )}
    </>
  );
};
