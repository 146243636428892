import React from "react";

const OurTeamPage: React.FC = () => {

    return (
        <>
            <p>OurTeamPage</p>
        </>
    )
};

export default OurTeamPage;