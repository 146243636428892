import React from "react";

const ProductPatternizerPage: React.FC = () => {

    return (
        <>
            <p>ProductPatternizerPage</p>
        </>
    )
};

export default ProductPatternizerPage;