import { WashAndCareCollectionItem } from '@/common/interfaces/collection.interface';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { FC } from 'react';
import { Cell, Checkbox, Circle } from './styles';

interface Props {
  item: WashAndCareCollectionItem;
  isSelected: boolean;
  handleSelect: (id: number) => void;
}

export const Item: FC<Props> = (props) => {
  const { item, isSelected, handleSelect } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '130px' }}>
      <Cell onClick={() => handleSelect(item.id)}>
        <img src={getAssetBackendUrl(item.image, 900)} alt={item.name} />
        <Checkbox
          style={{
            backgroundColor: isSelected ? '#F6F4ED' : 'transparent',
          }}
        >
          {isSelected && <Circle />}
        </Checkbox>
      </Cell>
      <div
        style={{
          whiteSpace: 'wrap',
        }}
      >
        {item.name}
      </div>
    </div>
  );
};
