// import Delete from '@mui/icons-material/Delete';
import Close from '@mui/icons-material/Close';
import { Input, styled, Typography } from '@mui/material';

export const Container = styled('div')({
  // display: 'flex',
  // flexDirection: 'column',
  // height: '100%',
  // width: '100%',
  // position: 'relative',
});

export const Title = styled(Typography)({
  margin: '0.5rem 0',
});

export const Inner = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '12.5rem',
});

export const InputStyled = styled(Input)(({ theme }) => ({
  ...theme.typography.body2,
  width: '70%',
  maxWidth: '100%',
  display: 'inline-block',
  // maxWidth: 'fit-content',
  // maxWidth: '100%',
  // maxWidth: 'fit-content',
  marginBottom: '0.5rem',
  // padding: '0.25rem',
}));

export const Photo = styled('img')({
  height: '100%',
  width: '12.5rem',
});

export const SizeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid black',
  width: '6.3125rem',
  margin: '0 0.5rem',

  '& div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%',
    width: '100%',
  },
});

export const SizeInput = styled(Input)(({ theme }) => ({
  ...theme.typography.body2,
  '& .MuiInputBase-input': {
    textAlign: 'center',
    border: 'none',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}));

export const PhotoContainer = styled('div')({
  position: 'relative',
});

export const EditColor = styled(Typography)({
  position: 'absolute',
  top: '0.5rem',
  left: '0.5rem',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const IconButtonWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '3rem',
});

export const DeleteIcon = styled(Close)({
  transform: 'scale(1.5)',
});

export const NoPhoto = styled('div')({
  height: '100%',
  width: '12.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F0F0F0',
});
