import { PATH } from '@/common/constants/path';
import { TermsPrivacyAboutPageType } from '@/pages/terms-privacy-about-page/terms-privacy-about-page';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../assets/logo-xl.svg';
import LogoMobile from '../../assets/logo.svg';
import {
  FooterContainer,
  ContentWrapper,
  LogoImage,
  LogoImageMobile,
  ResponsiveContainer,
  ListContainer,
  List,
  LastList,
  FooterText,
  GroupTitle,
  StyledRouterLink,
  CopyrightText,
  InnerContainer,
} from './styles';

export interface FooterProps { }

export const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <ContentWrapper>
        <ResponsiveContainer>
          <RouterLink to={`${PATH.HOME}`}>
            <LogoImage src={Logo} alt={t('main.title')} />
            <LogoImageMobile src={LogoMobile} alt={t('main.title')} />
          </RouterLink>
          <ListContainer>
            <InnerContainer>
              <List>
                <GroupTitle>{t('footer.products')}</GroupTitle>
                <StyledRouterLink to={`${PATH.PRODUCT_PATTERNIZER}`}>
                  <FooterText>{t('footer.patternizer')}</FooterText>
                </StyledRouterLink>
                <StyledRouterLink to={`${PATH.PRODUCT_YARNIVERSE}`}>
                  <FooterText>{t('footer.yarniverse')}</FooterText>
                </StyledRouterLink>
                <StyledRouterLink to={`${PATH.LOCAL_YARN_STORES}`}>
                  <FooterText>{t('footer.stores')}</FooterText>
                </StyledRouterLink>
                <StyledRouterLink to={`${PATH.LEARKNIT}`}>
                  <FooterText>{t('footer.learknit')}</FooterText>
                </StyledRouterLink>
              </List>
              <List>
                <GroupTitle>{t('footer.company')}</GroupTitle>
                <StyledRouterLink to={`${PATH.ABOUT_US}`}>
                  <FooterText>{t('footer.about')}</FooterText>
                </StyledRouterLink>
                <StyledRouterLink to={`${PATH.OUR_TEAM}`}>
                  <FooterText>{t('footer.team')}</FooterText>
                </StyledRouterLink>
              </List>
            </InnerContainer>
            <InnerContainer>
              <List>
                <GroupTitle>{t('footer.legal')}</GroupTitle>
                <StyledRouterLink to={`${PATH.PRIVACY_POLICY}`}>
                  <FooterText>{t('footer.privacyPolicy')}</FooterText>
                </StyledRouterLink>
                <StyledRouterLink to={`${PATH.TERMS_AND_CONDITIONS}`}>
                  <FooterText>{t('footer.termsConditions')}</FooterText>
                </StyledRouterLink>
              </List>
              <LastList>
                <GroupTitle>{t('footer.contact')}</GroupTitle>
                {/* <StyledRouterLink to='tel:+4793443099'>
                  <FooterText>+47 93443099</FooterText>
                </StyledRouterLink> */}
                {/* <StyledRouterLink to={`mailto:${t('footer.emailAddress')}`}>
                  <FooterText>{t('footer.emailAddress')}</FooterText>
                </StyledRouterLink> */}
                <StyledRouterLink to={`/help`}>
                  <FooterText>{t('footer.helpLink')}</FooterText>
                </StyledRouterLink>
              </LastList>
            </InnerContainer>
          </ListContainer>
        </ResponsiveContainer>
        <CopyrightText>Knitry {new Date().getFullYear()}</CopyrightText>
        {/* <StackLinksAndText>
        <List component='nav'>
          <ListItemStyled component='div'>
            <ListItemText primary={t('footer.contact')} />
          </ListItemStyled>
          <ListItemStyled component='div'>
            <RouterLink to={`mailto:${t('footer.emailAddress')}`}>{t('footer.emailAddress')}</RouterLink>
          </ListItemStyled>
        </List>
      </StackLinksAndText> */}
      </ContentWrapper>
    </FooterContainer>
  );
};
