import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { styled } from '@mui/material';
import { FC } from 'react';
import { ImgVideo } from '@/components/img-video/img-video';

interface Props {
  files: {
    file: string;
  }[];
  filesTitle: {
    [key: string]: string;
  };
  onChangeFile: (id: string) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '0 -1rem 1rem',
});

const Wrapper = styled('div')({
  width: '18.5rem',
  height: '12.625rem',
  margin: '0 1rem',
  cursor: 'pointer',
  border: '1px solid #000',
  overflow: 'hidden',
});

const Img = styled(ImgVideo)({
  display: 'block',
  width: '18.5rem',
  height: 'calc(100% - 2rem)',
  objectFit: 'cover',
});

const Title = styled('div')({
  fontSize: '1.125rem',
  overflow: 'hidden',
  padding: '0 0.5rem',
});

export const Preview: FC<Props> = (props) => {
  const { files, filesTitle, onChangeFile } = props;

  return (
    <Container>
      {files.map((el) => (
        <Wrapper key={el?.file} onClick={() => onChangeFile(el?.file)}>
          <Img src={getAssetBackendUrl(el?.file, 900)} naturalAspectRatio />
          <Title>{filesTitle[el?.file]}</Title>
        </Wrapper>
      ))}
    </Container>
  );
};
