import {
  API_COLLECTION,
  API_ROUTES,
  GPT_API_ROUTES,
  REQUEST_METHOD,
} from '@/common/constants/apiRoutes';
import { CreateUserDto } from '@/common/interfaces/api/create-user.dto';
import { CreateResourceRequest } from '@/common/interfaces/api/resource.dto';
import { UpdateUserDto } from '@/common/interfaces/api/update-user.dto';
import {
  AddDiagramsToStepRequest,
  CheckoutSessionRequest,
  CheckoutSessionResponse,
  CreatePatternStepsRequest,
  CreateStripeAccountRequest,
  CreateStripeAccountResponse,
  GetPatternViewRequest,
  GetPatternViewResponse,
  TranslatePatternRequest,
  TranslatePatternResponse,
  UpdateDiagramRequest,
  UpdatePatternStepRequest,
  UploadPatternResponse,
} from '@/common/interfaces/api/upload-pattern.dto';
import { Collections, FileCollectionItem } from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import {
  AuthResult,
  Directus,
  DirectusOptions,
  Item,
  ManyItems,
  OneItem,
  QueryMany,
  TransportRequestOptions,
  TransportResponse,
} from '@directus/sdk';
import { LoginDto } from '../../common/interfaces/api/login.dto';
import { FileFolders } from './../../common/constants/fileFolders';
import { CreatePaymentRequest } from '@/common/interfaces/api/payment.dto';

const config: DirectusOptions = {
  auth: {
    mode: 'json',
    autoRefresh: true,
    msRefreshBeforeExpires: 30000,
    staticToken: '',
  },
  storage: {
    prefix: '',
    mode: 'LocalStorage',
  },
};

const API_URL = process.env.REACT_APP_KNITRY_API_V2 || '';
const GPT_API_URL = process.env.REACT_APP_GPT_API_URL || '';
const ECOM_API_URL = process.env.REACT_APP_ECOM_API_URL || '';
const FILES_URL = process.env.REACT_APP_FILES_URL || '';
const WORKERS_URL = process.env.REACT_APP_WORKERS_URL || '';

export type ApiType = ReturnType<typeof createApi>;

export const createApi = () => {

  const cache = new Map<string, { data: any; expiry: number }>();

  const apiRequest = async <Res>(
    apiPath: string,
    path: string,
    body: FormData | object | null,
    options: RequestInit = {},
    cacheDuration = 300000
  ): Promise<Res> => {
    const { headers, ...restOptions } = options;
    const reqOptions: RequestInit = {
      body: body
        ? body instanceof FormData
          ? body
          : body instanceof File
          ? body
          : JSON.stringify(body)
        : undefined,
      method: REQUEST_METHOD.GET,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        ...(body instanceof FormData || body instanceof File
          ? {}
          : { 'Content-Type': 'application/json' }),
        ...headers,
      },
      ...restOptions,
    };

    const cacheKey = `${apiPath}${path}${JSON.stringify(body)}`;
    const cachedResponse = cache.get(cacheKey);

    // Return cached response if it exists and has not expired
    if (cachedResponse && Date.now() < cachedResponse.expiry) {
      return cachedResponse.data;
    }

    try {
      const response = await fetch(`${apiPath}${path}`, reqOptions);
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      if (
        response.status === 200 &&
        response.headers.get('Content-Type')?.includes('application/json')
      ) {
          const data = await response.json();
          // Cache the response with a specified expiry
          cache.set(cacheKey, { data, expiry: Date.now() + cacheDuration });
          return data;

      } else {
        return null as any;
      }
    } catch (e) {
      console.error(e);
      throw e as Error;
    }

  };

  const directus = new Directus(process.env.REACT_APP_API_URL as string, config);

  const directusWithoutRefresh = new Directus(process.env.REACT_APP_API_URL as string, {
    ...config,
    auth: { ...config.auth, autoRefresh: false },
  });

  const login = async ({ email, password }: LoginDto) => {
    try {
      await directus.auth.login({ email, password });
      return true;
    } catch (err) {}

    return false;
  };

  const logout = async () => {
    try {
      await directus.auth.logout();
    } catch (err) {}
  };

  const forgotRequest = async (email: string) => {
    try {
      return await directus.auth.password.request(email);
    } catch (err) {}
  };

  const checkIsLoggedIn = async (): Promise<boolean> => {
    try {
      const data: AuthResult | false = await directusWithoutRefresh.auth.refresh();

      if (data && typeof data === 'object' && 'access_token' in data) {
        localStorage.setItem('auth_token', data.access_token);
        return true;
      }
      return false;
    } catch (err) {
      throw new Error(err instanceof Error ? err.message : String(err));
    }
  };

  const getCurrentUser = (query?: string, options?: TransportRequestOptions) => {
    const authToken = localStorage.getItem('auth_token');

    // Set the Authorization header only if authToken is not null
    const updatedOptions: TransportRequestOptions = {
      ...options,
      headers: {
        ...options?.headers,
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      },
    };

    return directus.transport.get(
      `${API_ROUTES.USERS}/me${query ? '?' + query : ''}`,
      updatedOptions,
    );
  };

  const getUserById = (id: string, query?: any) => {
    return directus.transport.get(`${API_ROUTES.USERS}/${id}${query ? '?' + query : ''}`);
  };

  const updatedCurrentUser = (data: UpdateUserDto, options?: TransportRequestOptions) => {
    return directus.transport.patch(`${API_ROUTES.USERS}/me`, data, options);
  };

  const createUser = (data: CreateUserDto) => {
    return directus.transport.post(API_ROUTES.USERS, data);
  };

  const getRoles = (): Promise<ManyItems<any>> => {
    return directusWithoutRefresh.roles.readByQuery();
  };

  const getCollection = (
    collection: Collections,
    queryParams?: QueryMany<unknown>,
    limit: number = 500,
  ): Promise<ManyItems<Item>> => {
    return directus.items(API_COLLECTION[collection]).readByQuery({ limit, ...queryParams });
  };

  const getCollectionItemById = (
    collection: Collections,
    id: number,
    queryParams?: QueryMany<unknown>,
  ) => {
    return directus.items(API_COLLECTION[collection]).readOne(id, queryParams);
  };

  const getCollectionItemsByIds = (
    collection: Collections,
    ids: number[],
  ): Promise<ManyItems<Item>> => {
    return directus.items(API_COLLECTION[collection]).readMany(ids);
  };

  const updateCollectionItem = (
    collection: Collections,
    data: any,
    queryParams?: QueryMany<unknown>,
  ) => {
    console.log('updateCollectionItem', collection, data, queryParams);

    return directus.items(API_COLLECTION[collection]).updateOne(data.id, data, queryParams);
  };

  const updatedCollectionItems = (collection: Collections, data: any[]) => {
    return directus.items(API_COLLECTION[collection]).updateMany(
      data.map((el) => el.id),
      data,
    );
  };

  const removeCollectionItem = (collection: Collections, id: number) => {
    return directus.items(API_COLLECTION[collection]).deleteOne(id);
  };

  const createCollectionItem = (collection: Collections, data: any) => {
    return directus.items(API_COLLECTION[collection]).createOne(data);
  };

  const createCollectionItems = (collection: Collections, data: any[]) => {
    return directus.items(API_COLLECTION[collection]).createMany(data);
  };

  const createPattern = (pattern: any, queryParams?: QueryMany<unknown>) => {
    return directus.items('patterns').createOne(pattern, queryParams);
  };

  const updatePattern = (pattern: any, queryParams?: QueryMany<unknown>) => {
    return directus.items('patterns').updateOne(pattern.id, pattern, queryParams);
  };

  const updateResource = (resource: any, queryParams?: QueryMany<unknown>) => {
    return directus.items('resources').updateOne(resource.id, resource, queryParams);
  };

  const updatePatternKeywords = (
    patternId: any,
    keywords: string[],
    queryParams?: QueryMany<unknown>,
  ) => {
    return directus.items('patterns').updateOne(patternId, { keywords }, queryParams);
  };

  const updateStepSizes = (
    stepId: any,
    relevant_sizes: number[],
    queryParams?: QueryMany<unknown>,
  ) => {
    return directus.items('pattern_step').updateOne(
      stepId,
      {
        relevant_sizes: relevant_sizes.map((x) => ({ size_chart_id: x })),
      },
      queryParams,
    );
  };

  const createResource = (resource: CreateResourceRequest, queryParams?: QueryMany<unknown>) => {
    return directus.items(API_COLLECTION.Resources).createOne(resource, queryParams);
  };

  const uploadFile = (fileFolder: FileFolders, file: File | File[]) => {
    const formData = new FormData();

    (Array.isArray(file) ? file : [file]).forEach((file) => {
      formData.append('folder', fileFolder);
      formData.append('file', file);
    });

    return directus.files.createOne(formData);
  };

  const deleteFile = (fileId: string) => {
    return directus.files.deleteOne(fileId);
  };

  const getFile = (fileId: string) => {
    return directus.files.readOne(fileId, {});
  };

  const translateSteps = (
    body: TranslatePatternRequest,
  ): Promise<TranslatePatternResponse | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    return apiRequest<TranslatePatternResponse>(
      `${GPT_API_URL}/api`,
      GPT_API_ROUTES.TRANSLATE_STEPS,
      body,
      options,
    );
  };

  //api requests
  const uploadFiles = ({
    files,
  }: {
    files: File[];
  }): Promise<ManyItems<FileCollectionItem[]> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
    });

    return apiRequest<ManyItems<FileCollectionItem[]>>(`${API_URL}`, '/files', formData, options);
  };

  const createPayment = (req: CreatePaymentRequest): Promise<TransportResponse<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    return apiRequest<TransportResponse<any>>(
      `${ECOM_API_URL}`,
      '/stripe/checkout/create-checkout-session',
      req,
      options,
    );
  };

  const createConfirmPayment = (req: any): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, '/stripe/confirm-payment', req, options);
  };

  const createPatternStep = (req: CreatePatternStepsRequest): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, '/item/pattern_step', req, options);
  };

  const updatePatternStep = ({
    stepId,
    data,
  }: Partial<UpdatePatternStepRequest>): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.PATCH,
    };

    return apiRequest<OneItem<any>>(
      `${API_URL}`,
      `/items/pattern_step/${stepId}`,
      { ...data },
      options,
    );
  };

  const uploadPattern = (patternId: number, file: File) => {
    const options = {
      method: REQUEST_METHOD.POST,
    };

    const formData = new FormData();
    formData.append('files', file);

    return apiRequest<UploadPatternResponse>(
      `${FILES_URL}`,
      `/patterns/upload/${patternId}`,
      formData,
      options,
    );
  };

  const pollPdfProcessingStatus = (patternId: number): Promise<string> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.GET,
    };
    return apiRequest(WORKERS_URL, `/processing-status/${patternId}?cachebust=${Math.random().toFixed(5)}`, null, options);
  };

  const deletePatternStep = (id: number): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.DELETE,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, `/items/pattern_step/${id}`, null, options);
  };

  const deletePatternImage = (imageId: number): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.DELETE,
    };

    return apiRequest<OneItem<any>>(
      `${API_URL}`,
      `/items/pattern_images/${imageId}`,
      null,
      options,
    );
  };

  const updatePatternProps = ({ id, ...rest }: Partial<Pattern>): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.PATCH,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, `/items/patterns/${id}`, { ...rest }, options);
  };

  const addDiagramToStep = (diagrams: AddDiagramsToStepRequest): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, `/items/step_diagrams`, diagrams, options);
  };

  const updateDiagram = ({
    id,
    ...diagramData
  }: UpdateDiagramRequest): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.PATCH,
    };

    return apiRequest<OneItem<any>>(
      `${API_URL}`,
      `/items/step_diagrams/${id}`,
      diagramData,
      options,
    );
  };

  const getProduct = (id: number): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.GET,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, `/items/products/${id}?fields=*`, null, options);
  };

  const deleteDiagram = (id: number): Promise<OneItem<any> | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.DELETE,
    };

    return apiRequest<OneItem<any>>(`${API_URL}`, `/items/step_diagrams/${id}`, null, options);
  };

  const getPatternView = ({
    id,
    size,
  }: GetPatternViewRequest): Promise<GetPatternViewResponse | Error> => {
    return apiRequest<GetPatternViewResponse>(
      `${API_URL}`,
      `/the_patterns/pattern/step/${id}/${size}`,
      null,
    );
  };

  const checkoutSession = (
    req: CheckoutSessionRequest,
  ): Promise<CheckoutSessionResponse | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };
    return apiRequest<CheckoutSessionResponse>(
      `${API_URL}`,
      `/stripe/v1/checkout/sessions`,
      req,
      options,
    );
  };

  const createStripeAccount = (
    req: CreateStripeAccountRequest,
  ): Promise<CreateStripeAccountResponse | Error> => {
    const options: RequestInit = {
      method: REQUEST_METHOD.POST,
    };

    //Call account_links/create with the new api

    return apiRequest<CreateStripeAccountResponse>(
      `${ECOM_API_URL}`,
      `/stripe/v1/account_links/create`,
      req,
      options,
    );
  };

  const getCollectionWithoutRefresh = (
    collection: Collections,
    queryParams?: QueryMany<unknown>,
    limit: number = 500,
  ): Promise<ManyItems<Item>> => {
    return directus.items(API_COLLECTION[collection]).readByQuery({ limit, ...queryParams });
  };

  const getCollectionByFilter = (
    collection: Collections,
    queryParams?: QueryMany<unknown>,
    limit: number = 500,
  ): Promise<ManyItems<Item>> => {
    return directus.items(API_COLLECTION[collection]).readByQuery({ limit, ...queryParams });
  };

  return {
    directus,
    login,
    logout,
    forgotRequest,
    createUser,
    checkIsLoggedIn,
    getRoles,
    getCurrentUser,
    getCollection,
    createCollectionItem,
    updateCollectionItem,
    removeCollectionItem,
    createPattern,
    updatePattern,
    updatePatternKeywords,
    updateStepSizes,
    createPayment,
    createConfirmPayment,
    getCollectionItemById,
    getCollectionItemsByIds,
    updatedCollectionItems,
    createCollectionItems,
    uploadPattern,
    translateSteps,
    uploadFile,
    deleteFile,
    pollPdfProcessingStatus,
    updatedCurrentUser,
    getFile,
    createResource,
    updateResource,
    uploadFiles,
    createPatternStep,
    updatePatternStep,
    deletePatternStep,
    deletePatternImage,
    updatePatternProps,
    getUserById,
    addDiagramsToStep: addDiagramToStep,
    updateDiagram,
    deleteDiagram,
    getPatternView,
    checkoutSession,
    createStripeAccount,
    getCollectionWithoutRefresh,
    getCollectionByFilter,
    getProduct,
  };
};
