import { styled } from '@mui/material';

export const Text = styled('div')<{ fontSize: number }>(({ fontSize }) => ({
  fontSize: `${1.125 * (fontSize / 100)}rem`, // 18px base size (1.125rem) scaled by zoom level
  // whiteSpace: 'pre-wrap',
  '& span': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
