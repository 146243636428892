import { keyframes, styled } from '@mui/material';
import { Container } from './styles';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const ShimmerEffect = styled('div')({
    animation: `${shimmer} 1.5s infinite linear`,
    background: 'linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%)',
    backgroundSize: '800px 104px',
    position: 'relative',
    borderRadius: '4px',
});

const ImageSkeleton = styled(ShimmerEffect)({
    width: '175px',
    height: '175px',
});

const InputSkeleton = styled(ShimmerEffect)({
    width: '100%',
    height: '40px',
    marginTop: '8px',
});

const Spacer = styled('div')({
    height: '16px',
});

export const YarnColorAndEanLoadingSkeleton = () => {
    return (
        <Container>
            <ImageSkeleton /> {/* Color image skeleton */}
            <InputSkeleton /> {/* Name input skeleton */}
            <Spacer />
            <ImageSkeleton /> {/* EAN image skeleton */}
            <InputSkeleton /> {/* EAN input skeleton */}
            <Spacer />
            <InputSkeleton style={{ marginTop: '16px', height: '48px' }} /> {/* Remove button skeleton */}
        </Container>
    );
};
