import { ImgVideo } from '@/components/img-video/img-video';
import { FontDownload } from '@mui/icons-material';
import { Button, styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  /* borderBottom: '1px solid #000',
  padding: '2rem', */
  position: 'relative',
  padding: '0 2rem',
  [theme.breakpoints.down('laptop')]: {
    padding: '1rem',
  },
}));

export const Title = styled('div')(({ theme }) => ({
    marginTop: '20px',
    fontSize: '2.4rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1.4rem',
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  margin: '0 -1rem',
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '1rem',
  width: '210px',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    width: '33.3333%',
    padding: '1rem',
  },
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}));

export const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  paddingBottom: '100%',
  placeholder: {}
});

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
}));

export const FoundText = styled('div')({
    fontSize: '1rem',
    color: '#777',
    textTransform: 'uppercase',
    marginTop: '10px'
});

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '1rem',
  },
}));

export const Clear = styled(Button)(({ theme }) => ({
  fontSize: '1.125rem',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#000',
  padding: 0,
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem',
  },
}));

export const Name = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
});

export const SubName = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
  fontSize: 24
});

export const TopName = styled(Typography)({
  position: 'relative',
  right: '1rem',
  textAlign: 'left',
  paddingLeft: '1rem',
});

export const BarResultHeader = styled(Header)(({ theme }) => ({
    marginBottom: '0rem', 
    marginTop: '2rem', 
    [theme.breakpoints.down('laptop')]: {
      marginBottom: '0rem',
    },
  }));

export const BarResultTitle = styled('div')(({ theme }) => ({
    fontSize: '1.8rem',
    marginBottom: '10px',
    [theme.breakpoints.down('laptop')]: {
      fontSize: '1.8rem',
    },
  }));

  export const BarResultSubTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: '#777',
    textTransform: 'uppercase'
  }));  

  export const BarResultSeparator = styled('div')(({ theme }) => ({
    height: '1.5rem',
    width: '1px',
    backgroundColor: '#777',
    margin: '0 5px'
  })); 

  export const BarResultShowAll = styled('button')(({ theme }) => ({
    ...theme.typography.body1,
    padding: '0',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 'inherit',
    color: 'inherit',
    textTransform: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
        fontWeight: '700'
    },
  }));

  export const EmptyResultContainer = styled('div')(({ theme }) => ({
    borderBottom: '1px solid #000',
    zIndex: '2',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0',
    left: '0',
    width: '100%',
    
  }));
