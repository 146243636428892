import { ImgVideo } from '@/components/img-video/img-video';
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
  borderTop: '1px solid #000',
  [theme.breakpoints.up('laptop')]: {
    borderTop: 'none',
    borderLeft: '1px solid #000',
  },
}));

export const ColorsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: '2rem',
  gap: '1rem',
});

export const ColorContainer = styled('div')<{ $isSelected?: boolean }>(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '175px',
  cursor: 'pointer',
  '& > div:first-of-type': {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%', // Creates a square aspect ratio
  },
}));

export const ColorTitle = styled('div')({
  flexShrink: 0,
  paddingTop: '0.5rem',
  fontWeight: 'semibold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const ImageElement = styled(ImgVideo)<{ $isSelected?: boolean }>(({ $isSelected }) => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'all 0.2s ease-in-out',
  transformOrigin: 'center',
  border: $isSelected ? '1.5px solid #000' : '1px solid transparent',
  '&:hover': {
    border: '1.5px solid #000',
    transform: 'scale(1.02)',
  },
}));
