import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/base';
import { Menu, MenuItem, styled } from '@mui/material';

import { getCurrentUserAction } from '@/store/reducers/user.reducer';

import { Language, LanguageList } from '@/common/constants/languages';

const Li = styled('li')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const LanguageButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  cursor: 'pointer',
  border: 'none',
  padding: '0.5rem 0',
  margin: 0,
  backgroundColor: 'transparent',
}));

const LanguageButtonText = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

const LanguageButtonTextTablet = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}));

const LanguageButtonTextMobile = styled('span')(({ theme }) => ({
  // ...theme.typography.body3,
  [theme.breakpoints.up('tablet')]: {
    display: 'none',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'relative',
    borderRadius: 0,
    boxShadow: 'none',
    width: 'min-content',
    backgroundColor: 'transparent',
    paddingTop: '16px',
    '&::before': {
      content: '""',
      top: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '1px',
      height: '16px',
      backgroundColor: 'black',
    },
    [theme.breakpoints.down('tablet')]: {
      paddingTop: '14px',
      '&::before': {
        height: '14px',
      },
    },
  },
  '& .MuiMenu-list': {
    padding: 0,
    border: '1px solid black',
    backgroundColor: 'white',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body1,
  minHeight: 'auto',
  padding: '0.75rem',
  borderBottom: '1px solid black',
  '&:last-child': {
    borderBottom: 'none',
  },
  // Remove default focus and hover styles
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey background on hover
  },
  // Remove the default focus outline
  '&:focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '0.5rem',
  },
}));

export const LanguagePicker = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const [languageAnchorEl, setLanguageAnchorEl] = useState<HTMLElement | null>(null);
  
  const handleOpenLanguageMenu = (target: HTMLElement) => {
    setLanguageAnchorEl(target);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (language: Language) => {
    i18n.changeLanguage(language.value);
    handleCloseLanguageMenu();
  };

  const languages: Language[] = LanguageList.filter(language => language.active);

  useEffect(() => {
    dispatch(getCurrentUserAction());
  }, []);

  // const removeBodyPadding = () => {
  //   document.body.style.paddingRight = '0px';
  // };

  return (
    <Li>
      <LanguageButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleOpenLanguageMenu(event.currentTarget)}>
        <LanguageButtonText>{t('header.nav.selectLanguage')}</LanguageButtonText>
        <LanguageButtonTextTablet>{t('header.nav.selectLanguageShort')}</LanguageButtonTextTablet>
        <LanguageButtonTextMobile>{i18n.language.toUpperCase()}</LanguageButtonTextMobile>
      </LanguageButton>
      <StyledMenu
        id='language-menu'
        anchorEl={languageAnchorEl}
        open={Boolean(languageAnchorEl)}
        onClose={handleCloseLanguageMenu}
        MenuListProps={{
          'aria-labelledby': 'language-button',
          disablePadding: true,
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: 36,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // TransitionProps={{
        //   onEntering: removeBodyPadding,
        //   onExiting: removeBodyPadding,
        // }}
      >
        {languages.map((language) => (
          <StyledMenuItem key={language.value} onClick={() => handleLanguageChange(language)}>
            {language.name}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Li>
  );
};
