import { styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
const PlaceholderContainer = styled('div')(({ theme }) => ({
  width: '207px',
  padding: '16px',
  backgroundColor: theme.palette.secondary.main,
  opacity: 0.7,
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '36px',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const PlaceholderBox = styled('div')({
  width: '175px',
  height: '175px',
  border: '2px dashed #C3C0C0',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(195, 192, 192, 0.1)',
  padding: '16px',
});

const PlaceholderText = styled('div')({
  fontSize: '0.875rem',
  color: '#666',
  textAlign: 'center',
  maxWidth: '160px',
});

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  fontSize: '48px',
  color: theme.palette.grey[600],
  marginBottom: '8px',
}));

const Text = styled('p')(({ theme }) => ({
  color: theme.palette.grey[600],
  margin: 0,
  fontSize: '1rem',
  textAlign: 'center',
}));

interface Props {
  onClick?: () => void;
}

export const EmptyYarnColorPlaceholder: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <PlaceholderContainer onClick={onClick}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '72px', alignItems: 'center' }}>
        <PlaceholderBox>
          <PlaceholderText>{t('Yarniverse.colorImageGoesHere')}</PlaceholderText>
        </PlaceholderBox>
        <PlaceholderBox>
          <PlaceholderText>{t('Yarniverse.EANImageGoesHere')}</PlaceholderText>
        </PlaceholderBox>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <StyledAddIcon />
        <Text>{t('Yarniverse.addColor')}</Text>
      </div>
    </PlaceholderContainer>
  );
};
