import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  // height: '15rem',
  width: 'auto',
  border: '1px solid #000000',
});

export const Image = styled('img')({
  height: '17rem',
  width: '17rem',
  objectFit: 'cover',
  borderRight: '1px solid #000000',
});

export const Main = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const Info = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '20rem',
  padding: '1rem',
  borderLeft: '1px solid #000',
});

export const NameDescription = styled('div')({
  width: '100%',
  marginBottom: '1rem',
});

export const LastRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  margin: '1rem 0',
});

export const Left = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem',
  width: '75%',
});

export const Right = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem',
});

export const Price = styled(Typography)({
  fontWeight: 600,
});

export const SelectColor = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
});
