import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH } from '@/common/constants/path';
import { ResourceTypes } from '@/common/constants/resources';
import { ImgVideo } from '@/components/img-video/img-video';
import { ListEl, Image, Title, CopyBtn } from './styles';
import { ResourceWithStage } from '@/store/types/pattern';

const apiPath = process.env.REACT_APP_API_URL || '';

export interface ResourcesItemProps {
  resource: ResourceWithStage;
  onOpenPreview: (resourceId: number) => void;
}

export const ResourceItem: FC<ResourcesItemProps> = memo(({ resource, onOpenPreview }) => {
  const { t } = useTranslation();
  const { name, files, id } = resource;

  const fileId = files ? files[0]?.file : '';
  const fileSrc = `${apiPath}/assets/${fileId}`;

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(
      `https://${window.location.host}${PATH.RESOURCE_VIEWER}?id=${id}&type=${ResourceTypes.RESOURCE}`,
    );
  };

  const onOpenPreviewHandler = () => {
    resource.id && onOpenPreview(resource.id);
  };

  return (
    <ListEl>
      {!!fileId && <Image naturalAspectRatio src={fileSrc} onClick={onOpenPreviewHandler} />}
      <CopyBtn onClick={copyLinkHandler}>{t('common.copyLink')}</CopyBtn>
      <Title>{name}</Title>
    </ListEl>
  );
});
