import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatternizerStepsKeys } from '../../pages/patternizer-page/components/patternizer-steps';
import { logoutSuccessAction } from './authorization.reducer';
import { clearPatternDataAction } from './pattern.reducer';

export interface PatternizerState {
  currentStep: PatternizerStepsKeys;
}

const initialState: PatternizerState = {
  // currentStep: PatternizerStepsKeys.START,
  // currentStep: PatternizerStepsKeys.PATTERN_UPLOAD,
  currentStep: PatternizerStepsKeys.PATTERN_NAME,
};

export const patternizerSlice = createSlice({
  name: 'patternizer',
  initialState,
  reducers: {
    setStep: (state, { payload }: PayloadAction<PatternizerStepsKeys>) => {
      state.currentStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
    builder.addCase(clearPatternDataAction, () => initialState);
  },
});

export const { setStep } = patternizerSlice.actions;
export const patternizerReducer = patternizerSlice.reducer;
