import { FC } from "react";
import { styled } from "@mui/material";

const Wrapper = styled('div')(({ theme }) => ({
    maxWidth: '960px',
    display: 'flex',
    padding: '1rem 2rem',
    [theme.breakpoints.down('tablet')]: {
        padding: '0.5rem 1rem',
    },
}));

interface ContentContainerProps {
    content: ShortArticle;
}

interface ShortArticle {
    subtitle: string;
    slug: string;
    content: string;
}

export const ContentContainer: FC<ContentContainerProps> = (props) => {
    const { content } = props;

    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: content.content }} ></div>
        </Wrapper>
    );
};
