import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalButton, StyledDialogActions } from './styles';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  onConfirmText?: string;
  onCloseText?: string;
  isInfo?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  onConfirmText,
  onCloseText,
  isInfo
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <StyledDialogActions isInfo={isInfo}>
        <ModalButton onClick={onClose}>
          {onCloseText || t('common.cancel')}
        </ModalButton>
        {!isInfo && 
          <ModalButton onClick={onConfirm} remove>
            {onConfirmText || t('confirmationModal.confirm')}
          </ModalButton>   
        }
      </StyledDialogActions>
    </Dialog>
  );
};
