import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YarnWithStage } from '../types/yarniverse';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarnViewState {
  isLoading: boolean;
  data: YarnWithStage;
}

const initialState: YarnViewState = {
  isLoading: false,
  data: {
    thread_construction: [],
  },
};

export const yarnViewSlice = createSlice({
  name: 'yarn-view',
  initialState,
  reducers: {
    // Get yarn by id
    getYarnByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnByIdSuccessAction: (state, action: PayloadAction<YarnWithStage>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Clear
    clearYarnViewDataAction: () => initialState,
    // Error action
    yarnViewErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  getYarnByIdAction,
  getYarnByIdSuccessAction,
  yarnViewErrorAction,
  clearYarnViewDataAction,
} = yarnViewSlice.actions;

export const yarnViewReducer = yarnViewSlice.reducer;
