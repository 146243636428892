import React from 'react';
import { Container, Title, TitleContainer, InstructionsContainer, NavigationBar, NavigationGroup, StepNavigationText, FinishButton } from './styles';
import { PatternStepWithStage } from '@/store/types/pattern';
import { Step } from '../step';
import { ResourceTooltip } from '@/components/resource-tooltip/resource-tooltip';
import { useTranslation } from 'react-i18next';

interface StepInstructionsProps {
  currentStep: PatternStepWithStage;
  fontSize: number;
  selectedSizeId: number | null;
  onPrevStep: () => void;
  onNextStep: () => void;
  onFinish: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  onOpenResource: (resourceId: number, resourceType: 'resource' | 'diagram') => void;
  patternSizeModel: any;
  previewMode?: boolean;
  onExitPreview?: () => void;
}

export const StepInstructions: React.FC<StepInstructionsProps> = ({ 
  currentStep, 
  fontSize,
  selectedSizeId,
  onPrevStep,
  onNextStep, 
  onFinish, 
  isFirstStep,
  isLastStep,
  onOpenResource,
  patternSizeModel,
  previewMode,
  onExitPreview
}) => {
  const { t } = useTranslation();
  
  const handleFinishClick = () => {
    if (previewMode && onExitPreview) {
      onExitPreview();
    } else {
      onFinish();
    }
  };
  
  return (
    <Container>
      <TitleContainer>
        <Title>{currentStep.name}</Title>
      </TitleContainer>
      <InstructionsContainer fontSize={fontSize}>
        <ResourceTooltip>
          <Step 
            instructions={currentStep?.instructions || ''}
            fontSize={fontSize}
            stepId={currentStep.id}
            selectedSizeId={selectedSizeId}
            onOpenResource={onOpenResource}
            patternSizeModel={patternSizeModel}
          />
        </ResourceTooltip>
      </InstructionsContainer>
      <NavigationBar>
        <NavigationGroup>
          {!isFirstStep && (
            <StepNavigationText onClick={onPrevStep}>
              {t('knitmode.previousStep')}
            </StepNavigationText>
          )}
        </NavigationGroup>
        <NavigationGroup>
          {!isLastStep && (
            <StepNavigationText onClick={onNextStep}>
              {t('knitmode.nextStep')}
            </StepNavigationText>
          )}
          <FinishButton onClick={handleFinishClick} colored>
            {t('knitmode.finished')}
          </FinishButton>
        </NavigationGroup>
      </NavigationBar>
    </Container>
  );
};
