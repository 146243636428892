import { Button } from '@/components/button/button';
import {
  createMultipleYarnColorsAction,
  removeYarnColorAction,
  removeYarnColorImageAction,
  setYarnColorAction,
} from '@/store/reducers/yarniverse.reducer';
import {
  getYarniverseColorsSelector,
  getYarniverseDataSelector,
  getYarniverseLoadingSelector,
} from '@/store/selectors/yarniverse.selector';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonContainer, ButtonUploadStyled, ColorContainer, PageContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { YarnColorAndEanComponent } from '@/components/yarn-color-and-ean-component';
import { EmptyYarnColorPlaceholder } from '@/components/yarn-color-and-ean-component/placeholder';
import { YarnColorAndEanLoadingSkeleton } from '@/components/yarn-color-and-ean-component/loading';

interface Props {
  onPressNext: () => void;
}

export const Colors: FC<Props> = (props) => {
  const { onPressNext } = props;
  const yarn = useSelector(getYarniverseDataSelector);
  const yarnColors = useSelector(getYarniverseColorsSelector);
  const [numberOfLoadingColors, setNumberOfLoadingColors] = useState(1);
  const isLoading = useSelector(getYarniverseLoadingSelector);
  const [isAddingColors, setIsAddingColors] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setNumberOfLoadingColors(1);
      setIsAddingColors(false);
    }
  }, [isLoading]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addYarnColor = () => {
    setIsAddingColors(true);
    dispatch(setYarnColorAction({ yarnId: yarn.id as number }));
  };

  const handleSave = (color: {
    id: number;
    image?: File;
    eanImage?: File;
    ean?: string;
    name?: string;
  }) => {
    dispatch(setYarnColorAction({ yarnId: yarn.id as number, ...color }));
  };

  const handleRemoveImage = (fileId?: string) => {
    if (fileId) dispatch(removeYarnColorImageAction({ yarnId: yarn.id as number, fileId }));
  };

  const handleRemove = (id: number) => {
    dispatch(removeYarnColorAction({ yarnId: yarn.id as number, id }));
  };

  const handleMultiColorUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const multiColorFiles = Array.from(event.target.files).map((file) => ({
        name: file.name,
        image: file,
      }));
      setIsAddingColors(true);
      setNumberOfLoadingColors(multiColorFiles.length);
      dispatch(
        createMultipleYarnColorsAction({
          yarnId: yarn.id as number,
          yarnColors: multiColorFiles,
        }),
      );
    }
  };

  return (
    <PageContainer>
      <Grid>
        <Typography variant='h2'>{yarn.name}</Typography>
        <ColorContainer>
          {yarnColors.map((color) => (
            <YarnColorAndEanComponent
              key={color.id}
              name={color.name}
              ean={color.ean}
              colorImage={color.image}
              eanImage={color.eanImage}
              onSave={(c) =>
                handleSave({
                  id: color.id,
                  ean: c.ean,
                  name: c.name,
                  image: c.image,
                  eanImage: c.eanImage,
                })
              }
              onRemoveColorImage={() => handleRemoveImage(color.image)}
              onRemoveEanImage={() => handleRemoveImage(color.eanImage)}
              onRemove={() => handleRemove(color.id)}
            />
          ))}
          {isLoading && isAddingColors ? (
            [...Array(numberOfLoadingColors)].map((_, index) => (
              <YarnColorAndEanLoadingSkeleton key={index} />
            ))
          ) : (
            <EmptyYarnColorPlaceholder onClick={() => addYarnColor()} />
          )}
        </ColorContainer>
        <ButtonContainer>
          <ButtonUploadStyled onClick={addYarnColor}>
            + {t('Yarniverse.addColor')}
          </ButtonUploadStyled>

          <input
            type='file'
            multiple
            onChange={handleMultiColorUpload}
            style={{ display: 'none' }}
            id='multi-color-upload'
          />
          <label htmlFor='multi-color-upload'>
            <ButtonUploadStyled component='span'>
              + {t('Yarniverse.uploadMultipleColors')}
            </ButtonUploadStyled>
          </label>
        </ButtonContainer>
      </Grid>
      <Button style={{ marginTop: '2rem' }} colored onClick={onPressNext}>
        {t('common.next')}
      </Button>
    </PageContainer>
  );
};
