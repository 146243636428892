import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { Container, ColorContainer, ImageElement, ColorsContainer, ColorTitle } from './styles';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';

interface Props {
  yarnColors: YarnColorCollectionItem[];
  name: string;
  username: string;
  onColorSelect?: (id: number) => void;
  selectedColorId?: number;
}
export const Colors: FC<Props> = ({
  yarnColors,
  name,
  username,
  onColorSelect,
  selectedColorId,
}) => {
  return (
    <Container>
      <Typography variant='h3'>{name}</Typography>
      <Typography variant='h4' style={{ textDecoration: 'underline' }}>
        {username}
      </Typography>
      <ColorsContainer>
        {yarnColors.map((item) => (
          <ColorContainer
            key={item.id}
            onClick={() => onColorSelect?.(item.id)}
            $isSelected={selectedColorId === item.id}
          >
            <div>
              <ImageElement
                src={getAssetBackendUrl(item.image, 175)}
                objectFit='cover'
                $isSelected={selectedColorId === item.id}
              />
            </div>
            <ColorTitle>{item.name}</ColorTitle>
          </ColorContainer>
        ))}
      </ColorsContainer>
    </Container>
  );
};
