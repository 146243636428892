export interface Language {
  title: string;
  value: string;
  active: boolean;
  name: string;
  code: string;
}

export const LanguageList: Language[] = [
  {
    title: 'English',
    value: 'en',
    active: true,
    name: 'English',
    code: 'en-US',
  },
  {
    title: 'Spanish',
    value: 'es',
    active: false,
    name: 'Spanish',
    code: 'es-ES',
  },
  {
    title: 'French',
    value: 'fr',
    active: false,
    name: 'French',
    code: 'fr-FR',
  },
  {
    title: 'German',
    value: 'de',
    active: false,
    name: 'German',
    code: 'de-DE',
  },
  {
    title: 'Italian',
    value: 'it',
    active: false,
    name: 'Italian',
    code: 'it-IT',
  },
  {
    title: 'Portuguese',
    value: 'pt',
    active: false,
    name: 'Portuguese',
    code: 'pt-BR',
  },
  {
    title: 'Dutch',
    value: 'nl',
    active: false,
    name: 'Dutch',
    code: 'nl',
  },
  {
    title: 'Russian',
    value: 'ru',
    active: false,
    name: 'Russian',
    code: 'ru-RU',
  },
  {
    title: 'Ukrainian',
    value: 'ua',
    active: false,
    name: 'Ukrainian',
    code: 'ua',
  },
  {
    title: 'Polish',
    value: 'pl',
    active: false,
    name: 'Polish',
    code: 'pl',
  },
  {
    title: 'Lithuanian',
    value: 'lt',
    active: false,
    name: 'Lithuanian',
    code: 'lt',
  },
  {
    title: 'Latvian',
    value: 'lv',
    active: false,
    name: 'Latvian',
    code: 'lv',
  },
  {
    title: 'Swedish',
    value: 'sw',
    active: false,
    name: 'Swedish',
    code: 'sw',
  },
  {
    title: 'Norwegian',
    value: 'no',
    active: true,
    name: 'Norsk',
    code: 'nb-NO',
  },
  {
    title: 'Danish',
    value: 'da',
    active: false,
    name: 'Danish',
    code: 'da',
  },
  {
    title: 'Finnish',
    value: 'fi',
    active: false,
    name: 'Finnish',
    code: 'fi',
  },
  {
    title: 'Greek',
    value: 'gr',
    active: false,
    name: 'Greek',
    code: 'gr',
  },
  {
    title: 'Hungarian',
    value: 'hu',
    active: false,
    name: 'Hungarian',
    code: 'hu',
  },
  {
    title: 'Czech',
    value: 'cz',
    active: false,
    name: 'Czech',
    code: 'cz',
  },
  {
    title: 'Romanian',
    value: 'ro',
    active: false,
    name: 'Romanian',
    code: 'ro',
  },
  {
    title: 'Bulgarian',
    value: 'bg',
    active: false,
    name: 'Bulgarian',
    code: 'bg',
  },
  {
    title: 'Serbian',
    value: 'sr',
    active: false,
    name: 'Serbian',
    code: 'sr',
  },
  {
    title: 'Croatian',
    value: 'hr',
    active: false,
    name: 'Croatian',
    code: 'hr',
  },
  {
    title: 'Icelandic',
    value: 'is',
    active: false,
    name: 'Icelandic',
    code: 'is',
  },
  {
    title: 'Irish',
    value: 'ie',
    active: false,
    name: 'Irish',
    code: 'ie',
  },
  {
    title: 'Welsh',
    value: 'cy',
    active: false,
    name: 'Welsh',
    code: 'cy',
  },
  {
    title: 'Maltese',
    value: 'mt',
    active: false,
    name: 'Maltese',
    code: 'mt',
  },
  {
    title: 'Chinese (Simplified)',
    value: 'zh',
    active: false,
    name: 'Chinese (Simplified)',
    code: 'zh',
  },
  {
    title: 'Chinese (Traditional)',
    value: 'zh-CN',
    active: false,
    name: 'Chinese (Traditional)',
    code: 'zh-CN',
  },
  {
    title: 'Japanese',
    value: 'jp',
    active: false,
    name: 'Japanese',
    code: 'jp',
  },
  {
    title: 'Korean',
    value: 'ko',
    active: false,
    name: 'Korean',
    code: 'ko',
  },
  {
    title: 'Hindi',
    value: 'hi',
    active: false,
    name: 'Hindi',
    code: 'hi',
  },
  {
    title: 'Thai',
    value: 'th',
    active: false,
    name: 'Thai',
    code: 'th',
  },
  {
    title: 'Vietnamese',
    value: 'vi',
    active: false,
    name: 'Vietnamese',
    code: 'vi',
  },
  {
    title: 'Malay',
    value: 'ml',
    active: false,
    name: 'Malay',
    code: 'ml',
  },
  {
    title: 'Indonesian',
    value: 'id',
    active: false,
    name: 'Indonesian',
    code: 'id',
  },
  {
    title: 'Arabic',
    value: 'ar',
    active: false,
    name: 'Arabic',
    code: 'ar-SA',
  },
  {
    title: 'Turkish',
    value: 'tr',
    active: false,
    name: 'Turkish',
    code: 'tr',
  },
  {
    title: 'Persian (Farsi)',
    value: 'fa',
    active: false,
    name: 'Persian (Farsi)',
    code: 'fa',
  },
  {
    title: 'Hebrew',
    value: 'he',
    active: false,
    name: 'Hebrew',
    code: 'he',
  },
  {
    title: 'Zulu',
    value: 'zu',
    active: false,
    name: 'Zulu',
    code: 'zu',
  },
  {
    title: 'Afrikaans',
    value: 'af',
    active: false,
    name: 'Afrikaans',
    code: 'af',
  },
];
