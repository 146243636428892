import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { FC } from 'react';
import {
  ButtonText,
  Container,
  Header,
  MainCheckbox,
  MainCheckboxContainer,
  Next,
  Picture,
  PictureContainer,
  Prev,
} from './styles';
import { t } from 'i18next';

interface Props {
  mainImageId?: number;
  images: YarnColorCollectionItem[];
  onMain: (image: any) => void;
  currentImage: YarnColorCollectionItem | null;
  onImageChange: (image: YarnColorCollectionItem) => void;
}

export const PhotoViewer: FC<Props> = (props) => {
  const { mainImageId, images, onMain, currentImage, onImageChange, ...rest } = props;

  const setMainImage = () => {
    if (!currentImage) return;
    onMain(currentImage);
  };

  const onNextImage = () => {
    const currentIndex = images.findIndex((image) => image.id === currentImage?.id);
    if (currentIndex === images.length - 1) {
      onImageChange(images[0]);
    } else {
      onImageChange(images[currentIndex + 1]);
    }
  };

  const onPrevImage = () => {
    const currentIndex = images.findIndex((image) => image.id === currentImage?.id);
    if (currentIndex === 0) {
      onImageChange(images[images.length - 1]);
    } else {
      onImageChange(images[currentIndex - 1]);
    }
  };

  return (
    <Container {...rest}>
      {images.length > 0 && (
        <PictureContainer>
          <Header>
            <MainCheckboxContainer>
              <ButtonText>{t('Yarniverse.setAsMainColor')}</ButtonText>
              <MainCheckbox
                onClick={setMainImage}
                className={currentImage?.id === mainImageId ? 'checked' : ''}
              ></MainCheckbox>
            </MainCheckboxContainer>
          </Header>
          <Picture src={getAssetBackendUrl(currentImage?.image || '', 900)} />
          <Prev onClick={onPrevImage} />
          <Next onClick={onNextImage} />
        </PictureContainer>
      )}
    </Container>
  );
};
