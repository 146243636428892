import React from "react";

const LocalYarnStoresPage: React.FC = () => {

    return (
        <>
            <p>LocalYarnStoresPage</p>
        </>
    )
};

export default LocalYarnStoresPage;