import React from "react";

const PrivacyPolicyPage: React.FC = () => {

    return (
        <>
            <p>PrivacyPolicyPage</p>
        </>
    )
};

export default PrivacyPolicyPage;