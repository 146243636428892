import { DirectusFields } from '@/common/constants/directus-fields';
import { Collections } from '@/common/interfaces/collection.interface';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  loadItemFromBasketErrorAction,
  loadItemFromBasketSuccessAction,
  loadItemsFromBasketAction,
  incrementItemInBasketAction,
  incrementItemInBasketSuccessAction,
  incrementItemInBasketErrorAction,
  decrementItemInBasketAction,
} from '../reducers/basket.reducer';
import { getBasketDataSelector } from '../selectors/basket.selector';
import { BasketItem, BasketPatternResponse } from '../types/basket';

const EXCLUDED_FIELDS_BY_TYPE = {
  Pattern: ['prices'],
  Yarn: ['price'],
};

function* getBasketItemData(
  api: ApiType,
  item: BasketItem,
): Generator<any, BasketPatternResponse, any> {
  let response: BasketPatternResponse;

  switch (item.type) {
    case 'Pattern':
      response = yield call(api.getCollectionItemById, Collections.Patterns, item.id, {
        fields: DirectusFields.PatternBasket.filter(
          (field) => !EXCLUDED_FIELDS_BY_TYPE[item.type].includes(field),
        ),
      });
      break;
    case 'Yarn': // TODO refactor into one request
      // eslint-disable-next-line no-case-declarations
      const productResponse = yield call(api.getProduct, item.id);
      response = yield call(
        api.getCollectionItemById,
        Collections.Yarn,
        productResponse.data.connectedYarn,
        {
          fields: DirectusFields.PatternBasket.filter(
            (field) => !EXCLUDED_FIELDS_BY_TYPE[item.type].includes(field),
          ),
        },
      );
      response.price = productResponse.data.price;
      response.id = productResponse.data.id;
      break;
    default:
      throw new Error('Unknown item type');
  }

  return response;
}

function* loadItemsFromBasketRequest(api: ApiType) {
  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    let quantity;

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);

      if (item.data?.quantity) {
        quantity = item.data?.quantity;
      } else {
        quantity = 1;
      }

      result.push({
        type: item.type,
        id: item.id,
        data: {
          name: response.name,
          photo: response.main_image?.image,
          quantity: quantity,
          //ToFix
          price: response.price,
          // prices: response.prices.[0].price,
          id: response.id,
          username: response.user_created.username || '',
          vendor:
            (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ?? undefined,
        },
      });
    }

    yield put(loadItemFromBasketSuccessAction(result));
  } catch (error) {
    yield put(loadItemFromBasketErrorAction());
  }
}

function* incrementItemInBasketRequest(
  api: ApiType,
  action: ReturnType<typeof incrementItemInBasketAction>,
) {
  const { data } = action.payload;

  let maxValue;

  if (data?.quantity) {
    maxValue = data?.quantity + 1;
  }

  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);

      if (item.id == data?.id) {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            photo: response.main_image.image,
            quantity: maxValue,
            //ToFix
            price:
              item.type == 'Pattern'
                ? response.price
                : response.prices?.[0].price
                ? response.prices?.[0].price
                : 0,
            // prices: response.prices.[0].price,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      } else {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            photo: response.main_image.image,
            quantity: item.data?.quantity,
            //quantity: maxValue,
            //ToFix
            price:
              item.type == 'Pattern'
                ? response.price
                : response.prices?.[0].price
                ? response.prices?.[0].price
                : 0,
            // prices: response.prices.[0].price,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      }
    }

    yield put(incrementItemInBasketSuccessAction(result));
  } catch (error) {
    yield put(incrementItemInBasketErrorAction());
  }
}

function* decrementItemInBasketRequest(
  api: ApiType,
  action: ReturnType<typeof decrementItemInBasketAction>,
) {
  const { data } = action.payload;

  let maxValue;

  if (data?.quantity) {
    maxValue = data?.quantity - 1;
  }

  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);

      if (item.id == data?.id) {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            photo: response.main_image.image,
            quantity: maxValue,
            //ToFix
            price:
              item.type == 'Pattern'
                ? response.price
                : response.prices?.[0].price
                ? response.prices?.[0].price
                : 0,
            // prices: response.prices.[0].price,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      } else {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            photo: response.main_image.image,
            quantity: item.data?.quantity,
            //ToFix
            price:
              item.type == 'Pattern'
                ? response.price
                : response.prices?.[0].price
                ? response.prices?.[0].price
                : 0,
            // prices: response.prices.[0].price,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      }
    }

    yield put(incrementItemInBasketSuccessAction(result));
  } catch (error) {
    yield put(incrementItemInBasketErrorAction());
  }
}

export const basketSaga = function* (api: ApiType) {
  yield all([takeLatest(loadItemsFromBasketAction.type, loadItemsFromBasketRequest, api)]);
  yield all([takeLatest(incrementItemInBasketAction.type, incrementItemInBasketRequest, api)]);
  yield all([takeLatest(decrementItemInBasketAction.type, decrementItemInBasketRequest, api)]);
};
