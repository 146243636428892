import { RootState } from '..';

export const getDesignersSelector = (state: RootState) => state.designers.data;

export const getDesignersFilterSelector = (state: RootState) => state.designers.designersFilter;

export const getDesignersSortSelector = (state: RootState) => state.designers.designersSort;

export const getDesignersLoadingSelector = (state: RootState) => state.designers.isDesignersLoading;

export const getDesignersErrorSelector = (state: RootState) => state.designers.error;