import { Link } from '@/components/link/link';
import { ResourceUpload } from '@/components/resource-upload';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';
import { ResourcesBrowse } from '@/components/resources-browse';
// import { deleteStepDiagramAction, getPatternStepsAction, updateStepDiagramAction, uploadPatternStepDiagramAction } from '@/store/reducers/pattern.reducer';
import { getPatternStepsAction, uploadPatternStepDiagramAction } from '@/store/reducers/pattern.reducer';
import { getUserResourcesAction } from '@/store/reducers/user.reducer';
import { getCurrentUserSelector, getUserResourcesSelector } from '@/store/selectors/user.selector';
import { PatternStepWithStage, ResourceWithStage } from '@/store/types/pattern';
import { styled } from '@mui/material/styles';
// import { FC, useEffect, useMemo, useState } from 'react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { Diagrams } from '../diagrams/diagrams';
import { BrowseResourcesModal } from './components/browse-resources-modal/browse-resources-modal';
import { ResourceUploadModal } from './components/resource-upload-modal/resource-upload-modal';
import { getKnitryResourcesSelector, getPatternStepsSelector } from '@/store/selectors/pattern.selector';
import { Resources } from '@/components/pattern-view/components/resources-sidebar/components/resources/resources';
import { getKnitryResourcesAction } from '@/store/reducers/resource-all.reducer';

export interface ResourcesSidebarProps {
  patternId: number;
  stepResources?: ResourceWithStage[];
  onResourceSelect: (selectedResources: number[]) => void;
  onResourceDeselect: (deselectedResource: number) => void;
  currentStep: PatternStepWithStage | null;
}

const SidebarContainer = styled('div')(({ theme }) => ({
  // padding: '1rem',
  width: '100%',
  // minWidth: '22rem',
  borderLeft: '1px solid #000',
  // [theme.breakpoints.down('laptop')]: {
  //   width: '40%',
  //   minWidth: '40%',
  // },
  // [theme.breakpoints.down('tablet')]: {
  //   width: '100%',
  //   minWidth: 'auto',
  //   padding: 0,
  //   borderRight: 'none',
  // },
}));

export const ListTitle = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: '700',
  margin: 0,
  // fontWeight: '500',
  // margin: '0 0 1.5rem',
  // fontSize: '1.25rem',
}));

export const ResourceList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

export const SidebarCellContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: '1rem',
  rowGap: '1rem',
  borderBottom: '1px solid #000',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1rem',
  rowGap: '1rem',
});

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.h4,
  textDecoration: 'none',
  fontWeight: '700',
}));

export const ResourcesSidebar: FC<ResourcesSidebarProps> = ({
  patternId,
  stepResources = [],
  onResourceSelect,
  onResourceDeselect,
  currentStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resourceUploadOpened, setResourceUploadOpened] = useState(false);
  const [browseResourcesOpened, setBrowseResourcesOpened] = useState(false);
  const [browseDiagramsOpened, setBrowseDiagramsOpened] = useState(false);
  const [browseKnitryResourcesOpened, setBrowseKnitryResourcesOpened] = useState(false);
  const [resourceViewId, setResourceViewId] = useState<number | null>(null);
  const allUserResources = useSelector(getUserResourcesSelector);
  const user = useSelector(getCurrentUserSelector);
  const steps = useSelector(getPatternStepsSelector);
  const allKnitryResources = useSelector(getKnitryResourcesSelector);

  const stepsCount = steps.length;

  const allSortedKnitryResources = [...allKnitryResources]?.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const userDiagrams = allUserResources
    ?.filter((resource) => resource.ResourceType?.some((type) => type.resource_type_id?.id === 3))
    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const userResources = allUserResources
    ?.filter((resource) => !userDiagrams.includes(resource))
    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const stepKnitryResources = stepResources?.filter((resource) => resource.isOfficial === true);

  const stepDiagrams = stepResources?.filter(
    (resource) => 
      resource.isOfficial !== true && 
      resource.ResourceType?.some((type) => type.resource_type_id?.id === 3)
  );

  const stepPatternResources = stepResources?.filter(
    (resource) => 
      !stepDiagrams.includes(resource) && 
      !stepKnitryResources.includes(resource)
  );

  const getUserResources = () => {
    if (user) {
      dispatch(getUserResourcesAction(user?.id));
    }
  };

  useEffect(() => {
    getUserResources();
  }, []);

  useEffect(() => {
    dispatch(getKnitryResourcesAction());
  }, []);

  const resourceModalCloseHandler = () => {
    setResourceUploadOpened(false);
  };
  const resourceModalSuccessHandler = () => {
    getUserResources();
    setResourceUploadOpened(false);
  };

  // const userResourcesList = useMemo(() => {
  //   return user ? userResources : [];
  // }, [allUserResources]);

  // const userDiagramsList = useMemo(() => {
  //   return user ? userDiagrams : [];
  // }, [allUserResources]);

  const browseResourcesCloseHandler = () => {
    setBrowseResourcesOpened(false);
    setBrowseDiagramsOpened(false);
    setBrowseKnitryResourcesOpened(false);
  };

  // const stepDiagrams = currentStep?.step_diagrams || [];
  const diagrams = steps.flatMap((step) => step.step_diagrams);

  const getUpdatedPatternSteps = () => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
    }
  };

  const onDiagramFileChange = (file: File) => {
    if (!currentStep || !patternId) {
      return;
    }
    dispatch(
      uploadPatternStepDiagramAction({
        stepId: currentStep.id,
        file,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  return (
    <>
      <SidebarContainer>
        <Resources
          summary={t('resourcesSidebar.patternResources')}
          browseText={t('resourcesSidebar.browseMyResources')}
          creationMode={true}
          createText={t('resourcesSidebar.createNewResource')}
          resources={stepPatternResources}
          setResourceViewId={setResourceViewId}
          setBrowseResourcesOpened={setBrowseResourcesOpened}
          setResourceUploadOpened={setResourceUploadOpened}
          onResourceDeselect={onResourceDeselect}
        />
        <Resources 
          summary={t('resourcesSidebar.charts')}
          browseText={t('resourcesSidebar.browseMyCharts')}
          creationMode={true}
          createText={t('resourcesSidebar.createNewChart')}
          resources={stepDiagrams}
          setResourceViewId={setResourceViewId}
          setBrowseResourcesOpened={setBrowseDiagramsOpened}
          setResourceUploadOpened={setResourceUploadOpened}
          onResourceDeselect={onResourceDeselect}
        />
        {/* <Diagrams
          diagrams={diagrams}
          onFileChange={onDiagramFileChange}
          onDiagramDelete={(diagramId) => {
            dispatch(
              deleteStepDiagramAction({
                diagramId,
                callback: getUpdatedPatternSteps,
              }),
            );
          }}
          onDiagramSave={(diagram) => {
            dispatch(
              updateStepDiagramAction({
                diagram,
                callback: getUpdatedPatternSteps,
              }),
            );
          }}
        /> */}
        <Resources
          summary={t('resourcesSidebar.knitryResources')}
          browseText={t('resourcesSidebar.browseKnitryResources')}
          creationMode={false}
          resources={stepKnitryResources}
          setResourceViewId={setResourceViewId}
          setBrowseResourcesOpened={setBrowseKnitryResourcesOpened}
          setResourceUploadOpened={setResourceUploadOpened}
          onResourceDeselect={onResourceDeselect}
        />
      </SidebarContainer>
      <ResourceUploadModal open={resourceUploadOpened} onClose={resourceModalCloseHandler}>
        <ResourceUpload onSuccess={resourceModalSuccessHandler} />
      </ResourceUploadModal>
      <BrowseResourcesModal open={browseResourcesOpened} onClose={browseResourcesCloseHandler}>
        <ResourcesBrowse
          browseText={t('resourcesSidebar.myResources')}
          onResourceSelect={onResourceSelect}
          selectedResources={stepResources.map(({ id }) => id) as number[]}
          resourcesCollection={userResources}
          stepsCount={stepsCount}
        />
      </BrowseResourcesModal>
      <BrowseResourcesModal open={browseDiagramsOpened} onClose={browseResourcesCloseHandler}>
        <ResourcesBrowse
          browseText={t('resourcesSidebar.myCharts')}
          onResourceSelect={onResourceSelect}
          selectedResources={stepResources.map(({ id }) => id) as number[]}
          resourcesCollection={userDiagrams}
          stepsCount={stepsCount}
        />
      </BrowseResourcesModal>
      <BrowseResourcesModal open={browseKnitryResourcesOpened} onClose={browseResourcesCloseHandler}>
        <ResourcesBrowse
          browseText={t('resourcesSidebar.knitryResources')}
          onResourceSelect={onResourceSelect}
          selectedResources={stepResources.map(({ id }) => id) as number[]}
          resourcesCollection={allSortedKnitryResources}
          stepsCount={stepsCount}
        />
      </BrowseResourcesModal>
      <ResourceViewDrawer
        resourceType='resource'
        resourceId={resourceViewId}
        onClose={() => setResourceViewId(null)}
      />
    </>
  );
};
