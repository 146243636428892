import { Dropdown } from '@/components/dropdown';
import { Input } from '@/components/input/input';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarniverse';
import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  yarn: Yarn;
}
const WEIGHT_UNITS = ['g', 'ounce'];
const LENGTH_UNITS = ['cm ', 'm', 'in', 'yards'];

export const Amount: FC<Props> = (props) => {
  const { yarn } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Units } = useSelector(getCollectionDataSelector);

  const UnitOptions = useMemo(() => {
    return Units?.map((el) => ({ label: el.name, value: el.id }));
  }, [Units]);

  // Weight
  const handleTypeWeightChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, amount_weight: value }));
  };

  const [typeWeight, setTypeWeight] = useInputWithDelay(
    yarn.amount_weight || '',
    handleTypeWeightChange,
    1000,
  );

  const handleAmountWeightUnitChange = (value: string | number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        amount_weight_unit: value as number,
      }),
    );
  };

  // Length
  const handleTypeLengthChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, amount_lenght: value }));
  };

  const [typeLength, setTypeLength] = useInputWithDelay(
    yarn.amount_lenght || '',
    handleTypeLengthChange,
    1000,
  );

  const handleAmountLengthUnitChange = (value: string | number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        amount_lenght_unit: value as number,
      }),
    );
  };

  return (
    <Container>
      <Grid container direction='row'>
        <Input
          placeholder={t('Yarniverse.typeWeight')}
          value={typeWeight}
          onChange={(e) => setTypeWeight(e.target.value)}
        />
        <Dropdown
          value={yarn?.amount_weight_unit}
          options={UnitOptions.filter((el) => WEIGHT_UNITS.includes(el.label))}
          onChange={handleAmountWeightUnitChange}
          placeholder={t('Yarniverse.selectUnitOfMeasurement')}
        />
      </Grid>

      <Grid container direction='row'>
        <Input
          placeholder={t('Yarniverse.typeLength')}
          value={typeLength}
          onChange={(e) => setTypeLength(e.target.value)}
        />
        <Dropdown
          value={yarn?.amount_lenght_unit}
          options={UnitOptions.filter((el) => LENGTH_UNITS.includes(el.label))}
          onChange={handleAmountLengthUnitChange}
          placeholder={t('Yarniverse.selectUnitOfMeasurement')}
        />
      </Grid>
    </Container>
  );
};
