import React, { FC } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';

export interface TextEditorProps extends ReactQuillProps {
  zoom?: number;
}

const EditorWrapper = styled('div')<{ $zoom?: number }>(({ $zoom = 100 }) => ({
  '& .ql-container': {
    fontSize: `${1.125 * ($zoom / 100)}rem`, // Base size 1.125rem (18px) scaled by zoom
    minHeight: '15rem',
  },
}));

export const TextEditor: FC<TextEditorProps> = ({ zoom = 100, ...props }) => {
  return (
    <EditorWrapper $zoom={zoom}>
      <ReactQuill theme="snow" {...props} />
    </EditorWrapper>
  );
};
