import { Box, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

export const StepsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  width: '100%',
  padding: '1rem',
  // borderRight: '1px solid #000',
  // [theme.breakpoints.down('laptop')]: {
  //   width: '100%',
  // },
}));

export const TabsEl = styled(Tabs)({
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.MuiTabs-flexContainer': {
    flexDirection: 'column',
  },
});

export const PatternStepsTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.body1,
  padding: '1rem',
  borderBottom: '1px solid #000',
  borderRight: '1px solid #000',
  width: '50%',
  textAlign: 'center',
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.text,
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
  },
  '&:last-of-type': {
    borderRight: 'none',
  },
}));

export const OutterBox = styled(Box)({
  borderRight: '1px solid #000',
});