import { Dropdown } from '@/components/dropdown';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getYarniverseDataSelector } from '@/store/selectors/yarniverse.selector';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { styled } from '@mui/material';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';

export const DropdownStyled = styled(Dropdown)({
  border: 'none',
  fontSize: '1.375rem',
});
export const Construction = () => {
  const dispatch = useDispatch();

  const { ThreadConstruction } = useSelector(getCollectionDataSelector);
  const yarn = useSelector(getYarniverseDataSelector);
  const [selectedThreadConstruction, setSelectedThreadConstruction] = useState<number | undefined>(
    yarn.thread_construction?.[0],
  );
  const handleChange = (value: number) => {
    setSelectedThreadConstruction(value);
    dispatch(
      setYarnDataAction({
        ...yarn,
        thread_construction: [value],
      }),
    );
  };

  return (
    <Container>
      <DropdownStyled
        name={'test'}
        onChange={(value) => handleChange(value as number)}
        options={ThreadConstruction.map((x) => {
          return { label: x.name, value: x.id };
        })}
        value={selectedThreadConstruction}
        variant='filled'
        style={{
          margin: 0,
          background: 'transparent',
        }}
      />
    </Container>
  );
};
