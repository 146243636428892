import { Payment } from '@/common/interfaces/payment.interface';
import { User } from '@/common/interfaces/user.interface';
import { OneItem, TransportResponse } from '@directus/sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { ApiType } from '../../services/api';
import {
  setConfirmPaymentDataAction,
  setConfirmPaymentDataErrorAction,
  setConfirmPaymentDataSuccessAction,
  setPaymentDataAction,
  setPaymentDataErrorAction,
  setPaymentDataSuccessAction,
} from '../reducers/payment.reducer';
import { displayErrorAction } from '../reducers/system.reducer';

function* setPaymentDataRequest(api: ApiType, action: ReturnType<typeof setPaymentDataAction>) {
  const { products, callback } = action.payload;
  console.log('REST API: ', products);

  try {
    const currentUser: User | null = yield select((state: RootState) => state.user.currentUser);

    const responsePayment: TransportResponse<any> = yield call(api.createPayment, {
      products: products,
      customerId: currentUser?.id,
      currency: 'nok', // TODO FIX
    });

    if (responsePayment) {
      yield put(setPaymentDataSuccessAction(responsePayment));
      // yield call(callback, responsePayment);
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(setPaymentDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* setConfirmPaymentRequest(
  api: ApiType,
  action: ReturnType<typeof setConfirmPaymentDataAction>,
) {
  const confirm_payment = action.payload;
  try {
    const response: OneItem<Payment> = yield call(api.createConfirmPayment, confirm_payment);

    if (response) {
      yield put(setConfirmPaymentDataSuccessAction(response));
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(setConfirmPaymentDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

export const paymentSaga = function* (api: ApiType) {
  yield all([takeLatest(setPaymentDataAction.type, setPaymentDataRequest, api)]);
  yield all([takeLatest(setConfirmPaymentDataAction.type, setConfirmPaymentRequest, api)]);
};
