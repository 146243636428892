import React, { FC } from "react";
import { StyledAccordion } from "@/components/pattern-step-viewer/components/resources-panel/styles";
import { ResourceWithStage } from "@/store/types/pattern";
import { ButtonContainer, ListTitle, ResourceList } from "../../resources-sidebar";
import { ResourceItem } from "./resource-item";
import { AddButton } from "../../../pattern-steps/components/add-step/add-step";

interface ResourcesProps {
  summary: string;
  browseText: string;
  creationMode?: boolean;
  createText?: string;
  resources: ResourceWithStage[];
  setResourceViewId: (id: number) => void;
  setBrowseResourcesOpened: (opened: boolean) => void;
  setResourceUploadOpened: (opened: boolean) => void;
  onResourceDeselect?: (deselectedResource: number) => void;
}

export const Resources: FC<ResourcesProps> = ({ 
  summary,
  browseText,
  creationMode,
  createText,
  resources, 
  setResourceViewId, 
  setBrowseResourcesOpened, 
  setResourceUploadOpened,
  onResourceDeselect
}) => {

  return (
    <StyledAccordion 
      renderSummary={() => (
        <ListTitle>{summary}</ListTitle>
      )}
      renderDetails={() => (
        <>
          <ButtonContainer>
            <AddButton onClick={() => setBrowseResourcesOpened(true)}>
              {browseText}
            </AddButton>
            {creationMode && createText?.length !== 0 && (
              <AddButton onClick={() => setResourceUploadOpened(true)}>
                {'+ '}
                {createText}
              </AddButton>
            )}
          </ButtonContainer>
          <ResourceList>
            {resources.map((resource) => (
              <ResourceItem
                resource={resource}
                key={resource.id}
                onOpenPreview={setResourceViewId}
                onResourceDeselect={onResourceDeselect}
              />
            ))}
          </ResourceList>
        </>
      )}
    />
  );
};
