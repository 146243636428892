/**
 * SizeSelect component provides a dropdown menu for selecting pattern sizes.
 * 
 * @component
 * @param {Object} props
 * @param {Size[]} props.availableSizes - Array of available sizes with id and size_name
 * @param {number|null} props.selectedSizeId - Currently selected size ID
 * @param {Function} props.onSizeChange - Callback function when size selection changes
 * @param {boolean} [props.highlight=false] - Highlight the select input if no size is selected
 * 
 */

import React from 'react';
import { StyledSelect, StyledMenuItem } from './styles';
import { useTranslation } from 'react-i18next';

interface Size {
  id: number;
  size_name: string;
}

interface SizeSelectProps {
  availableSizes: Size[];
  selectedSizeId: number | null;
  onSizeChange: (sizeId: number) => void;
  highlight?: boolean;
}

export const SizeSelect: React.FC<SizeSelectProps> = ({
  availableSizes,
  selectedSizeId,
  onSizeChange,
  highlight = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSelect
      value={selectedSizeId || ''}
      onChange={(e) => onSizeChange(Number(e.target.value))}
      displayEmpty
      $highlight={highlight}
    >
      <StyledMenuItem value="">{t('knitmode.selectSize')}</StyledMenuItem>
      {availableSizes.map((size) => (
        <StyledMenuItem key={size.id} value={size.id}>
          {size.size_name}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
}; 