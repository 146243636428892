import { spawn } from 'redux-saga/effects';
import { createApi } from '../../services/api';
import { authorizationSaga } from './authorization.saga';
import { basketSaga } from './basket.saga';
import { collectionSaga } from './collection.saga';
import { patternViewSaga } from './pattern-view.saga';
import { patternSaga } from './pattern.saga';
import { resourceSaga } from './resource.saga';
import { searchSaga } from './search.saga';
import { userSaga } from './user.saga';
import { yarnSupplierSaga } from './yarn-supplier.saga';
import { yarnViewSaga } from './yarn-view.saga';
import { needleSaga } from './needle.saga';
import { designersSaga } from './designers.saga';
import { yarniverseSaga } from './yarniverse.saga';
import { pagesContentSaga } from './pages-content.saga';
import { paymentSaga } from './payment.saga';
import { yarnPromotedSaga } from './yarn-promoted.saga';
import { yarnUploadedSaga } from './yarn-uploaded.saga';
import { yarnManufactureWeekSaga } from './yarn-manufacturer-week.saga';

export const rootSaga = function* () {
  const api = createApi();
  yield spawn(authorizationSaga, api);
  yield spawn(userSaga, api);
  yield spawn(collectionSaga, api);
  yield spawn(patternSaga, api);
  yield spawn(paymentSaga, api);
  yield spawn(searchSaga, api);
  yield spawn(patternViewSaga, api);
  yield spawn(resourceSaga, api);
  yield spawn(basketSaga, api);
  yield spawn(needleSaga, api);
  yield spawn(designersSaga, api);
  yield spawn(yarniverseSaga, api);
  yield spawn(yarnViewSaga, api);
  yield spawn(yarnSupplierSaga, api);
  yield spawn(pagesContentSaga, api);
  yield spawn(yarnPromotedSaga, api);
  yield spawn(yarnUploadedSaga, api);
  yield spawn(yarnManufactureWeekSaga, api);
};
