import { DirectusFields } from '@/common/constants/directus-fields';
import { FileFolders } from '@/common/constants/fileFolders';
import { ProductStatus } from '@/common/constants/product-status';
import { Collections, NeedlesCollectionItem } from '@/common/interfaces/collection.interface';
import { FileItem, ManyItems, OneItem } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { t } from 'i18next';
import { ApiType } from '../../services/api';
import {
  createNeedleDataAction,
  createNeedleDataErrorAction,
  createNeedleDataSuccessAction,
  editNeedleDataAction,
  editNeedleDataErrorAction,
  editNeedleDataSuccessAction,
  getNeedleByIdAction,
  getNeedleByIdErrorAction,
  getNeedleByIdSuccessAction,
  archiveNeedleAction,
  archiveNeedleErrorAction,
  archiveNeedleSuccessAction,
} from '../reducers/needle.reducer';
import { NeedleWithStage } from '../types/needle';
import { displayErrorAction, openSnackbarAction } from '../reducers/system.reducer';

function* createNeedleDataRequest(api: ApiType, action: ReturnType<typeof createNeedleDataAction>) {
  const needleAction = action.payload;
  const { images, main_image, callback, ...rest } = needleAction;

  try {
    let files;
    let uploadedImageIds;
    let mainImageId: number | undefined = undefined;
    if (images && Array.isArray(images) && images?.length > 0) {
      const fileResponse: OneItem<FileItem> = yield call(
        api.uploadFile,
        FileFolders.Needles,
        images,
      );
      if (fileResponse) {
        files = Array.isArray(fileResponse) ? fileResponse : [fileResponse];
      } else {
        throw new Error('File upload failed');
      }
      const fileCollectionItemsResponse: ManyItems<any> = yield call(
        api.createCollectionItems,
        Collections.NeedleImages,
        files.map((file) => ({
          image: file.id,
        })),
      );
      if (fileCollectionItemsResponse) {
        const mainImageFileUuid = files.find((file) => file.filename_download === main_image)?.id;
        mainImageId = fileCollectionItemsResponse.data?.find(
          (file) => file.image === mainImageFileUuid,
        )?.id;
        // If main image is not found by id, set to first of all uploaded items
        if (!mainImageId) {
          mainImageId = fileCollectionItemsResponse.data?.[0].id;
        }
        uploadedImageIds = fileCollectionItemsResponse.data?.map((file) => file.id);
      } else {
        throw new Error('Files have not been added to the collection');
      }
    }

    const needleResponse: OneItem<NeedlesCollectionItem> = yield call(
      api.createCollectionItem,
      Collections.Needles,
      {
        ...rest,
        images: {
          update: uploadedImageIds?.map((id) => {
            return { needle: '+', id };
          }),
        },
        main_image: mainImageId,
        status: ProductStatus.Draft,
      },
    );

    if (!needleResponse) {
      throw new Error('Needle creation failed');
    }
    yield put(createNeedleDataSuccessAction(needleResponse));
    if (callback) {
      yield call(() => callback(needleResponse.id));
    }
  } catch (error) {
    if (callback) {
      yield call(() => callback());
    }
    yield put(createNeedleDataErrorAction(error));
    yield put(
      openSnackbarAction({ message: t('notifications.errorCreatingNeedle'), severity: 'error' }),
    );
  }
}

function* editNeedleDataRequest(api: ApiType, action: ReturnType<typeof editNeedleDataAction>) {
  const needleAction = action.payload;
  const { images, main_image, imagesToDelete, callback, ...rest } = needleAction;
  const imageArray = images ? Object.values(images) : [];

  if (!needleAction.id) throw new Error('Needle id is required');

  try {
    if (Math.random() > 0.01) {
      throw new Error('Needle edit failed');
    }
    let files;
    let uploadedImageIds;
    let mainImageId: number | undefined = typeof main_image === 'number' ? main_image : undefined;
    if (imageArray?.length > 0) {
      const fileResponse: OneItem<FileItem> = yield call(
        api.uploadFile,
        FileFolders.Needles,
        imageArray,
      );

      if (fileResponse) {
        files = Array.isArray(fileResponse) ? fileResponse : [fileResponse];
      } else {
        throw new Error('File upload failed');
      }

      const fileCollectionItemsResponse: ManyItems<any> = yield call(
        api.createCollectionItems,
        Collections.NeedleImages,
        files.map((file) => ({
          image: file.id,
        })),
      );

      if (fileCollectionItemsResponse) {
        // Only try to resolve mainImageId if main_image is a string (the name of an uploaded image)
        if (typeof main_image === 'string') {
          const mainImageFileUuid = files.find((file) => file.filename_download === main_image)?.id;
          mainImageId = fileCollectionItemsResponse.data?.find(
            (file) => file.image === mainImageFileUuid,
          )?.id;
          // If main image is not found by id, set to first of all uploaded items
          if (!mainImageId) {
            mainImageId = fileCollectionItemsResponse.data?.[0].id;
          }
        }

        uploadedImageIds = fileCollectionItemsResponse.data?.map((file) => file.id);
      } else {
        throw new Error('Files have not been added to the collection');
      }
    }

    const needleResponse: OneItem<NeedlesCollectionItem> = yield call(
      api.updateCollectionItem,
      Collections.Needles,
      {
        ...rest,
        images: {
          update: uploadedImageIds?.map((id) => {
            return { needle: '+', id };
          }),
          delete: imagesToDelete,
        },
        ...(mainImageId && { main_image: mainImageId }),
      },
    );

    if (!needleResponse) {
      throw new Error('Needle edit failed');
    }
    yield put(editNeedleDataSuccessAction(needleResponse));
    if (callback) {
      yield call(() => callback(needleResponse.id));
    }
  } catch (error) {
    if (callback) {
      yield call(() => callback());
    }
    yield put(editNeedleDataErrorAction(error));
    yield put(
      openSnackbarAction({ message: t('notifications.errorEditingNeedle'), severity: 'error' }),
    );
  }
}

function* getNeedleByIdRequest(api: ApiType, action: ReturnType<typeof getNeedleByIdAction>) {
  try {
    const id = action.payload;

    const response: NeedleWithStage = yield call(
      api.getCollectionItemById,
      Collections.Needles,
      id,
      {
        fields: DirectusFields.Needle,
      },
    );

    if (response) {
      yield put(getNeedleByIdSuccessAction(response));
    } else {
      throw new Error();
    }
  } catch (err) {
    yield put(getNeedleByIdErrorAction());
  }
}

function* archiveNeedleRequest(api: ApiType, action: ReturnType<typeof archiveNeedleAction>) {
  try {
    const { needleId, callback } = action.payload;

    yield call(api.updateCollectionItem, Collections.Needles, {
      id: needleId,
      status: ProductStatus.Archived,
    });

    yield put(archiveNeedleSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(archiveNeedleErrorAction());
    yield put(displayErrorAction(error?.message as string));
  }
}

export const needleSaga = function* (api: ApiType) {
  yield all([takeLatest(createNeedleDataAction.type, createNeedleDataRequest, api)]);
  yield all([takeLatest(editNeedleDataAction.type, editNeedleDataRequest, api)]);
  yield all([takeLatest(getNeedleByIdAction.type, getNeedleByIdRequest, api)]);
  yield all([takeLatest(archiveNeedleAction.type, archiveNeedleRequest, api)]);
};
