import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { ImgVideo } from '@/components/img-video/img-video';

export const ListEl = styled('li')({
  borderBottom: '1px solid #000',
  marginBottom: '1.5rem',
  position: 'relative',
  ':hover .MuiButtonBase-root': {
    visibility: 'visible',
  },
});

export const Image = styled(ImgVideo)({
  display: 'block',
  width: '100%',
});

export const Title = styled('p')({
  margin: 0,
  fontSize: '1.125rem', // 18px
  lineHeight: '1.5rem',
});

export const CopyBtn = styled(Button)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  backgroundColor: '#fff',
  color: '#000',
  visibility: 'hidden',
  ':hover': {
    backgroundColor: '#F6F4ED',
  },
});