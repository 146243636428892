import { styled } from '@mui/material/styles';

export const TitleContainer = styled('div')(({ theme }) => ({
  padding: '1rem 1.75rem',
  textTransform: 'uppercase',
  fontSize: '5rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #000',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '3rem',
    padding: '1rem 1.6rem',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '1rem 0.9rem',
  },
}));
