import { theme } from '@/common/mui-theme';
import { MenuItem, Select, SelectChangeEvent, SelectProps, styled, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface Props extends Omit<SelectProps, 'onChange'> {
  onChange: (id: number | string) => void;
  options: DropdownOptionsItem[];
  style?: CSSProperties;
  placeholder?: string;
  className?: string;
  minWidth?: string;
}

export interface DropdownOptionsItem {
  label: string;
  value: number | string;
}

export const Dropdown: FC<Props> = (props) => {
  const { value, onChange, options, style, placeholder, minWidth, ...rest } = props;

  const onChangeHandler = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string | number);
  };

  return (
    <Select
      size='small'
      displayEmpty={!!placeholder}
      value={value || 'placeholder'}
      onChange={onChangeHandler}
      inputProps={{
        'aria-label': 'Without label',
      }}
      style={{
        borderRadius: 0,
        margin: '0 0.625rem',
        minWidth: minWidth ? minWidth : '15rem',
        ...style,
      }}
      sx={{
        '& .MuiSelect-select': {
          padding: '0.5rem',
          '&:focus': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000',
        },
        '& .MuiSvgIcon-root': {
          color: '#000',
        },
      }}
      {...rest}
    >
      {!!placeholder && (
        <MenuItem disabled value='placeholder'>
          <Typography>{placeholder}</Typography>
        </MenuItem>
      )}
      {options.map((option) => (
        <StyledMenuItem key={option.value} value={option.value}>
          {option.label}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
