import { Errors } from '@/common/constants/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpenSnackbarPayload, SnackbarData } from '../types/systme';
import { logoutSuccessAction } from './authorization.reducer';

export interface SystemState {
  snackbar: SnackbarData;
  searchbar: string;
}

const initialState: SystemState = {
  snackbar: {
    open: false,
    severity: 'info',
    duration: 3000,
    message: '',
  },
  searchbar: '',
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    openSnackbarAction(state, action: PayloadAction<OpenSnackbarPayload>) {
      state.snackbar = {
        ...action.payload,
        open: true,
      };
    },
    closeSnackbarAction(state) {
      state.snackbar = {
        ...state.snackbar,
        open: false,
      };
    },
    setSearchbarAction: (state, action: PayloadAction<string>) => {
        state.searchbar = action.payload;
    },
    clearSearchbarAction: (state) => {
        state.searchbar = '';
    },

    displayErrorAction(state, action: PayloadAction<any>) {
      state.snackbar = {
        open: true,
        severity: 'error',
        duration: 3000,
        message: action.payload || Errors.Default,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const { openSnackbarAction, closeSnackbarAction, displayErrorAction, setSearchbarAction, clearSearchbarAction } = systemSlice.actions;

export const systemReducer = systemSlice.reducer;
