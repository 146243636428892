import { RootState } from '..';

export const getYarniverseCurrentStepSelector = (state: RootState) => state.yarniverse.currentStep;

export const getYarniverseDataSelector = (state: RootState) => state.yarniverse.data;

export const getYarniverseColorsSelector = (state: RootState) => state.yarniverse.colors;

export const getYarniverseLoadingSelector = (state: RootState) => state.yarniverse.isLoading;

export const getYarnImagesSelector = (state: RootState) => state.yarniverse.images;

export const getYarnPricesSelector = (state: RootState) => state.yarniverse.prices;
