import { ThemeProvider } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@/common/constants/path';
import { theme } from '@/common/mui-theme';

import { usePagesContent } from '@/hooks/usePagesContent.hook';
import usePrelaunchState from '@/hooks/usePrelaunchState';

// import withPrelaunchRedirect from '@/hoc/withPrelaunchRedirect';

import { getBasketLoadingSelector } from '@/store/selectors/basket.selector';
import { getCollectionLoadingSelector } from '@/store/selectors/collection.selector';
import { getPatternViewLoadingSelector } from '@/store/selectors/pattern-view.selector';
import { getSearchLoadingSelector } from '@/store/selectors/search.selector';
import { getUserLoadingSelector } from '@/store/selectors/user.selector';
import { getYarnSupplierLoadingSelector } from '@/store/selectors/yarn-supplier.selector';
import { getYarniverseLoadingSelector } from '@/store/selectors/yarniverse.selector';

import { ForgotPasswordLazy } from '@/pages/forgot-password-page/forgot-password-page-lazy';
import { HomePageLazy } from '@/pages/home-page/home-page-lazy';
import { NewPaymentPageLazy } from '@/pages/new-payment-wall-page/new-payment-payment-wall-page-lazy';
import { PatternPageLazy } from '@/pages/pattern-page/pattern-page-lazy';
import { PatternizerPageLazy } from '@/pages/patternizer-page/patternizer-page-lazy';
import { PatternizerStartPageLazy } from '@/pages/patternizer-start-page/patternizer-start-page-lazy';
import { PaymentSuccessPageLazy } from '@/pages/payment-callback/payment-callback-page-lazy';
import { PaymentErrorPageLazy } from '@/pages/payment-error-page/payment-error-page-lazy';
import { PaymentPageLazy } from '@/pages/payment-page/payment-page-lazy';
import { PrelaunchPageLazy } from '@/pages/prelaunch-page/prelaunch-page-lazy';
import { ProfilePageLazy } from '@/pages/profile-page/profile-page-lazy';
import { ProfileSettingPageLazy } from '@/pages/profile-setting-page/profile-setting-page-lazy';
import { PromotedPatternPageLazy } from '@/pages/promoted-patterns/promoted-patterns-page-lazy';
import { PromotedYarnPageLazy } from '@/pages/promoted-yarn/promoted-yarn-page-lazy';
import { PromotedDesignersPageLazy } from '@/pages/promoted-designers/promoted-designers-page-lazy';
import { ResourceUploadPageLazy } from '@/pages/resource-upload-page/resource-upload-page-lazy';
import { ResourceViewerPageLazy } from '@/pages/resource-viewer-page/resource-viewer-page-lazy';
import { SearchPageLazy } from '@/pages/search-page/search-page-lazy';
import { SignInPageLazy } from '@/pages/sign-in-page/sign-in-page-lazy';
import { SignUpPageLazy } from '@/pages/sign-up-page/sign-up-page-lazy';
import { StepsViewerPageLazy } from '@/pages/steps-viewer-page/steps-viewer-page-lazy';
import { SuccessPageLazy } from '@/pages/success-page/success-page-lazy';
import { TermsPrivacyAboutPageLazy } from '@/pages/terms-privacy-about-page/terms-privacy-about-page-lazy';
import { YarnPageLazy } from '@/pages/yarn-page/yarn-page-lazy';
import { YarniversePageLazy } from '@/pages/yarniverse-page/yarniverse-page-lazy';
import { YarniverseStartPageLazy } from '@/pages/yarniverse-start-page/yarniverse-start-page-lazy';
import { NeedleCreatePageLazy } from './pages/needle-pages/needle-create-page-lazy';
import { NeedleEditPageLazy } from './pages/needle-pages/needle-edit-page-lazy';
import { NeedlePageLazy } from './pages/needle-pages/needle-page-lazy';

import { Basket } from '@/components/basket';
import { Footer } from '@/components/footer/footer';
import { Header } from '@/components/header/header';
import { PageWithLoading } from '@/components/page-with-loading';
import { ProtectedRoute } from '@/components/protected-route/protected-route';

import { GradientBackground } from '@/components/gradient-background';
import { Snackbar } from '@/components/snackbar';
import { RedirectWrapper } from './components/redirect-wrapper';
import { CheckoutSuccessPageLazy } from './pages/checkout-callback/checkout-callback-page-lazy';
import { NeedleStartPageLazy } from './pages/needle-start-page/needle-start-page-lazy';
import ProductPatternizerPage from './pages/footer/product-patternizer-page';
import ProductYarniversePage from './pages/footer/product-yarniverse-page';
import LocalYarnStoresPage from './pages/footer/local-yarn-stores-page';
import AboutUsPage from './pages/footer/about-us-page';
import OurTeamPage from './pages/footer/our-team-page';
import PrivacyPolicyPage from './pages/footer/privacy-policy';
import TermsAndConditionsPage from './pages/footer/terms-and-conditions-page';
import { DesignersPageLazy } from './pages/designers-page/designers-page-lazy';
import { PublicAssetsPageLazy } from './pages/public-assets-page/public-assets-page-lazy';
import { HelphPageLazy } from './pages/help/help-page-lazy';
import Zendesk from "react-zendesk";

const App = () => {
  const isCollectionLoading = useSelector(getCollectionLoadingSelector);
  const isSearchLoading = useSelector(getSearchLoadingSelector);
  const isPatternLoading = useSelector(getPatternViewLoadingSelector);
  const isBasketLoading = useSelector(getBasketLoadingSelector);
  const isUserLoading = useSelector(getUserLoadingSelector);
  const isYarniverseLoading = useSelector(getYarniverseLoadingSelector);
  const isYarnSupplierLoading = useSelector(getYarnSupplierLoadingSelector);
  const isPagesContentLoading = usePagesContent();
  const prelaunch = usePrelaunchState(PATH.NOT_FOUND);

  const isLoading = useMemo(() => {
    return (
      isPagesContentLoading ||
      isCollectionLoading ||
      isSearchLoading ||
      isPatternLoading ||
      isBasketLoading ||
      isUserLoading ||
      isYarniverseLoading ||
      isYarnSupplierLoading
    );
  }, [
    isPagesContentLoading,
    isCollectionLoading,
    isSearchLoading,
    isPatternLoading,
    isBasketLoading,
    isUserLoading,
    isYarniverseLoading,
    isYarnSupplierLoading,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <PageWithLoading isLoading={isLoading}>
        <Wrapper prelaunch={prelaunch} />
        <Snackbar />
        {!prelaunch && <Basket />}
      </PageWithLoading>
    </ThemeProvider>
  );
};

interface WrapperProps {
  prelaunch: boolean;
}



const Wrapper: FC<WrapperProps> = ({ prelaunch }) => {


  const setting = {
    color: {
      theme: "#000"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } }
      ]
    }
  };

   return (
    <>
     <Zendesk defer zendeskKey={'e69dc811-20ca-4aea-874d-dc678639b6f0'} {...setting} onLoaded={() => console.log('is loaded')} />
      <Header />
      <section className='page-content'>
        <Routes>
          <Route path={'/help'} element={<HelphPageLazy/>} />
          <Route path={PATH.HOME} element={<HomePageLazy />} />
          <Route path={PATH.PRELAUNCH} element={<PrelaunchPageLazy />} />
          <Route path={PATH.SIGNIN} element={<SignInPageLazy />} />
          <Route path={PATH.SIGNUP} element={<SignUpPageLazy />} />
          <Route path={PATH.SUCCESS} element={<SuccessPageLazy />} />
          <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordLazy />} />
          <Route path={PATH.PROFILE} element={<ProfilePageLazy />} />
          <Route
            path={PATH.PROFILE_SETTING}
            element={
              <ProtectedRoute>
                <ProfileSettingPageLazy />
              </ProtectedRoute>
            }
          />
          <Route path={PATH.PAYMENT_WALL} element={<NewPaymentPageLazy />}></Route>
          <Route
            path={PATH.PAYMENT_WALL_STRIPE_SUCCESS}
            element={
              <ProtectedRoute>
                <PaymentSuccessPageLazy />
              </ProtectedRoute>
            }
          />
          <Route path={PATH.PAYMENT_PAGE} element={<PaymentPageLazy />}></Route>
          <Route path={PATH.PAYMENT_SUCCESS} element={<PaymentSuccessPageLazy />}></Route>
          <Route path={PATH.PAYMENT_ERROR} element={<PaymentErrorPageLazy />}></Route>
          <Route
            path={PATH.PROMOTED_DESIGNERS}
            element={
              <RedirectWrapper path={PATH.PROMOTED_DESIGNERS}>
                <PromotedDesignersPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.PROMOTED_PATTERNS}
            element={
              <RedirectWrapper path={PATH.PROMOTED_PATTERNS}>
                <PromotedPatternPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.PROMOTED_YARN}
            element={
              <RedirectWrapper path={PATH.PROMOTED_YARN}>
                <PromotedYarnPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.PATTERN}
            element={
              <RedirectWrapper path={PATH.PATTERN}>
                <PatternPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.PATTERNS_START}
            element={
              <RedirectWrapper path={PATH.PATTERNS_START}>
                <ProtectedRoute>
                  <GradientBackground />
                  <PatternizerStartPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.PATTERNS}
            element={
              <RedirectWrapper path={PATH.PATTERNS}>
                <ProtectedRoute>
                  <PatternizerPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.STEPS_VIEWER}
            element={
              <RedirectWrapper path={PATH.STEPS_VIEWER}>
                <ProtectedRoute>
                  <StepsViewerPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          ></Route>
          <Route
            path={PATH.RESOURCE_VIEWER}
            element={
              <RedirectWrapper path={PATH.RESOURCE_VIEWER}>
                <ProtectedRoute>
                  <ResourceViewerPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          ></Route>
          <Route
            path={PATH.RESOURCE_UPLOAD}
            element={
              <RedirectWrapper path={PATH.RESOURCE_UPLOAD}>
                <ProtectedRoute>
                  <ResourceUploadPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          ></Route>
          <Route
            path={PATH.YARNIVERSE_START}
            element={
              <RedirectWrapper path={PATH.YARNIVERSE_START}>
                <ProtectedRoute>
                  <GradientBackground />
                  <YarniverseStartPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.YARNIVERSE}
            element={
              <RedirectWrapper path={PATH.YARNIVERSE}>
                <ProtectedRoute>
                  <YarniversePageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.YARN}
            element={
              <RedirectWrapper path={PATH.YARN}>
                <YarnPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.NEEDLE_START}
            element={
              <RedirectWrapper path={PATH.NEEDLE_START}>
                <ProtectedRoute>
                  <GradientBackground />
                  <NeedleStartPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.NEEDLE_CREATE}
            element={
              <RedirectWrapper path={PATH.NEEDLE_CREATE}>
                <ProtectedRoute>
                  <NeedleCreatePageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.NEEDLE}
            element={
              <RedirectWrapper path={PATH.NEEDLE}>
                <ProtectedRoute>
                  <NeedlePageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.NEEDLE_EDIT}
            element={
              <RedirectWrapper path={PATH.NEEDLE_EDIT}>
                <ProtectedRoute>
                  <NeedleEditPageLazy />
                </ProtectedRoute>
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.DESIGNERS}
            element={
              <RedirectWrapper path={PATH.DESIGNERS}>
                <DesignersPageLazy />
              </RedirectWrapper>
            }
          />
          <Route
            path={PATH.YARNS}
            element={
              <RedirectWrapper path={PATH.YARNS}>
                <PublicAssetsPageLazy />
              </RedirectWrapper>
            }
          />
          <Route path={PATH.SEARCH} element={<SearchPageLazy/>} />
          <Route path={PATH.CHECKOUT_SUCCESS} element={<CheckoutSuccessPageLazy />}></Route>
          <Route path={PATH.TERMS_PRIVACY_ABOUT} element={<TermsPrivacyAboutPageLazy />}></Route>
          <Route
            path={PATH.NOT_FOUND}
            element={prelaunch ? <Navigate to={PATH.PRELAUNCH} /> : <Navigate to={PATH.HOME} />}
          />
          <Route path={PATH.PRODUCT_PATTERNIZER} element={<ProductPatternizerPage />} />
          <Route path={PATH.PRODUCT_YARNIVERSE} element={<ProductYarniversePage />} />
          <Route path={PATH.LOCAL_YARN_STORES} element={<LocalYarnStoresPage />} />
          <Route path={PATH.ABOUT_US} element={<AboutUsPage />} />
          <Route path={PATH.OUR_TEAM} element={<OurTeamPage />} />
          <Route path={PATH.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={PATH.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
        </Routes>
      </section>
      <Footer />
    </>
  );
};
export default App;
