export enum ResourceSteps {
  RESOURCE = 'RESOURCE',
  CATEGORIZATION = 'CATEGORIZATION',
  COMPLETE = 'COMPLETE',
}

export enum ResourceDataFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  FILES = 'files',
}

export enum ResourceCategorizationItems {
  CRAFT = 'craft',
  RESOURCE_TYPE = 'resourceType',
  CONTAINS = 'contains',
  TECHNIQUE = 'technique',
  YARN = 'yarn',
}

export type FilesToUpload = { [fileId: string]: File };

export type ResourceData = {
  [ResourceDataFields.NAME]: string;
  [ResourceDataFields.DESCRIPTION]: string;
  [ResourceDataFields.FILES]: FilesToUpload;
};

export interface ArchiveResourcePayload {
  resourceId: number;
  callback: (isSuccess: boolean) => void;
}
