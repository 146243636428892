import React from 'react';
import { Container, Item, PatternName, LogoImage, ZoomContainer, ExitPreviewButton } from './styles';
import { ByUser } from '@/components/by-user';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/common/constants/path';
import Logo from '@/assets/logo.svg';
import { ZoomSlider } from '@/components/zoom-slider';
import { useTranslation } from 'react-i18next';
import { SizeSelect } from '@/components/size-select';

interface KnitmodeHeaderProps {
  username: string;
  userId: string;
  language: string;
  patternSize: string;
  zoom: number;
  onZoomChange: (zoom: number) => void;
  patternName: string;
  availableSizes: Array<{id: number, size_name: string}>;
  selectedSizeId: number | null;
  onSizeChange: (sizeId: number) => void;
  previewMode?: boolean;
  onExitPreview?: () => void;
}

export const KnitmodeHeader: React.FC<KnitmodeHeaderProps> = ({
  username,
  userId,
  language,
  patternSize,
  zoom,
  onZoomChange,
  patternName,
  availableSizes,
  selectedSizeId,
  onSizeChange,
  previewMode,
  onExitPreview
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const shouldHighlightSize = !selectedSizeId && availableSizes.length > 0;

  return (
    <Container>
      <LogoImage src={Logo} alt='Knitry' onClick={() => navigate(PATH.HOME)} />
      <PatternName>{patternName}</PatternName>
      {!previewMode && <ByUser name={username} userId={userId} />}
      <Item>
        {t('knitmode.patternSize')}: 
        <SizeSelect
          availableSizes={availableSizes}
          selectedSizeId={selectedSizeId}
          onSizeChange={onSizeChange}
          highlight={shouldHighlightSize}
        />
      </Item>
      <ZoomContainer>
        <Item>{t('knitmode.zoom')}:</Item>
        <ZoomSlider value={zoom} onChange={onZoomChange} />
      </ZoomContainer>
      {previewMode && (
        <ExitPreviewButton 
          onClick={onExitPreview}
          colored
          remove
        >
          {t('knitmode.exitPreview')}
        </ExitPreviewButton>
      )}
    </Container>
  );
};
