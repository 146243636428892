import { Button } from '@/components/button/button';
import { Grid, styled, Typography } from '@mui/material';

export const Title = styled(Typography)({
  padding: '2rem',
  borderRight: '1px solid #000',
});

export const SelectorContainer = styled(Grid)({
  height: 'calc(48rem - 1px)',
  overflow: 'scroll',
});

export const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: '2rem',
  backgroundColor: theme.palette.primary.main,
}));

export const PageContainer = styled('section')(() => ({
  maxWidth: '1600px',
  margin: '0 auto',
  borderRight: '1px solid #000',
  borderLeft: '1px solid #000',
}));
