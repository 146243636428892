import { Pattern } from '@/common/interfaces/pattern.interface';
import { setPatternDataAction } from '@/store/reducers/pattern.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Content,
  Block,
  TextArea,
  PriceInput,
  PriceSet,
  PriceTitle,
  Currency,
  Name,
  // Description,
  Keywords,
  KeywordsTitle,
  Designer,
  MiddleBlock,
} from './styles';
import { ByUser } from '@/components/by-user';

interface Props {
  pattern: Pattern;
}

export const Info: FC<Props> = (props) => {
  const { pattern } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Technics } = useSelector(getCollectionDataSelector);
  const user = useSelector(getCurrentUserSelector);

  const [price, setPrice] = useState(pattern.price?.toString() || '');
  const [description, setDescription] = useState(pattern.description || '');

  const handlePriceChange = (value: string) => {
    if (/^[0-9]*$/.test(value)) {
      setPrice(value);
    }
  };

  const handlePriceBlur = () => {
    dispatch(setPatternDataAction({ ...pattern, price: Number(price) }));
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const handleDescriptionBlur = () => {
    dispatch(setPatternDataAction({ ...pattern, description }));
  };

  const keywords = useMemo(
    () => pattern.keywords?.map((el) => el).join(', '),
    [pattern, Technics],
  );

  useEffect(() => {
    if (pattern.price) setPrice(pattern.price.toString());
    if (pattern.description) setDescription(pattern.description);
  }, [pattern.price, pattern.description]);

  const userName = user?.display_name || user?.username || `${user?.first_name || ''} ${user?.last_name || ''}`.trim();

  return (
    <Content>
      <Block>
        <Name>{pattern.name}</Name>
        <Designer><span style={{ textTransform: 'capitalize' }}>{t('common.by')}</span> {userName}</Designer>
        {/* <Description>{t('patternizerSteps.advertisement.descriptionLanguage')}</Description> */}
      </Block>
      <MiddleBlock>
        <TextArea
          value={description}
          onChange={(ev) => handleDescriptionChange(ev.target.value)}
          placeholder={t('patternizerSteps.advertisement.descriptionPlaceholder')}
          onBlur={handleDescriptionBlur}
        />
        <Keywords>
          <KeywordsTitle>{t('patternizerSteps.advertisement.keywords')}:</KeywordsTitle>
          <div>{keywords}</div>
        </Keywords>
      </MiddleBlock>
      <Block>
        <PriceTitle>{t('patternizerSteps.advertisement.price')}</PriceTitle>
        <PriceSet>
          <PriceInput
            value={price}
            onChange={(ev) => handlePriceChange(ev.target.value)}
            placeholder={t('patternizerSteps.advertisement.setPrice')}
            type='number'
            onBlur={handlePriceBlur}
            style={{ width: price.length > 0 ? (price.length * 2) + 'rem' : '8rem' }}
          />
          <Currency>,- NOK</Currency>
        </PriceSet>
      </Block>
    </Content>
  );
};
