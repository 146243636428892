import {
  AvailableSizeCollectionItem,
  DiagramCollectionItem,
  GaugeCollectionItem,
  LanguageCollectionItem,
  ProjectCollectionItem,
  SuitableCollectionItem,
  TechnicsCollectionItem,
  DesignElementItemCollectionItem,
} from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { PatternStepWithStage, PatternWithStage } from '@/store/types/pattern';
import { isNumber } from 'util';

export interface PatternWithCollection {
  hand_work_technique: {
    hand_work_technique_id: TechnicsCollectionItem;
  }[];
  language: {
    language_id: LanguageCollectionItem;
  }[];
  masketett: {
    masketett_id: GaugeCollectionItem;
  }[];
  passer_til: {
    passer_til_id: SuitableCollectionItem;
  }[];
  technique: {
    technique_id: TechnicsCollectionItem;
  }[];
  type_of_project: {
    type_of_project_id: ProjectCollectionItem;
  }[];
  pattern_steps: {
    date_created: string;
    step_diagrams: DiagramCollectionItem[];
    id: number;
    instructions: string;
    name: string;
    pattern: number;
    ressources: {
      resources_id: any;
    }[];
    relevant_sizes: number[];
    sort: null;
    status: string;
    user_created: string;
    description_size?: string;
  }[];
  available_sizes: {
    size_chart_id: AvailableSizeCollectionItem;
  }[];
  isManualPattern: boolean;
  design_elements: {
    design_element_item_id: DesignElementItemCollectionItem;
  }[];
}

export interface BoughtPatternWithCollection {
  amount: string;
  date_created: string;
  date_updated: string;
  id: number;
  payment_ref: string;
  status: string;
  user_created: string;
  user_updated: string;
  pattern: PatternWithCollection;
}

export const normalizePattern = (pattern: PatternWithCollection): PatternWithStage => {
  const result: PatternWithStage = {
    ...pattern,
    hand_work_technique: pattern?.hand_work_technique?.map((item) => item?.hand_work_technique_id),
    language: pattern?.language?.map((item) => item?.language_id),
    masketett: pattern?.masketett?.map((item) => item?.masketett_id),
    passer_til: pattern?.passer_til?.map((item) => item?.passer_til_id),
    technique: pattern?.technique?.map((item) => item?.technique_id),
    type_of_project: pattern?.type_of_project?.map((item) => item?.type_of_project_id),
    pattern_steps: normalizePatternSteps(pattern?.pattern_steps),
    available_sizes: pattern?.available_sizes?.map((item) => item?.size_chart_id),
    design_elements: pattern?.design_elements?.map((item) => item?.design_element_item_id),
  };
  return result;
};

export interface PatternStepWithCollection {
  date_created: string;
  step_diagrams: DiagramCollectionItem[];
  id: number;
  instructions: string;
  name: string;
  pattern: number;
  ressources: {
    resources_id: any;
  }[];
  relevant_sizes: number[];
  sort: null;
  status: string;
  user_created: string;
}

export const normalizePatternSteps = (
  steps: PatternStepWithCollection[],
): PatternStepWithStage[] => {
  return steps?.map((item) => ({
    ...item,
    ressources: item?.ressources?.map((r) => ({
      ...r?.resources_id,
    })),
    relevant_sizes: item?.relevant_sizes
      ?.map((x: any) => x?.size_chart_id)
      ?.filter(x => typeof x === 'number')
  }));
};
