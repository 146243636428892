import { Errors } from '@/common/constants/errors';
import { FileFolders } from '@/common/constants/fileFolders';
import { ProductStatus } from '@/common/constants/product-status';
import {
  Collections,
  FileCollectionItem,
  YarnColorCollectionItem,
  YarnImageCollectionItem,
  YarnManufacturerCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { User } from '@/common/interfaces/user.interface';
import { ApiType } from '@/services/api';
import {
  createMultipleYarnColorsAction,
  createMultipleYarnColorsSuccessAction,
  createYarnColorAction,
  createYarnColorSuccessAction,
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  getYarnImagesAction,
  getYarnImagesSuccessAction,
  getYarniverseDataAction,
  getYarniverseDataSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  removeYarnColorAction,
  removeYarnColorImageAction,
  setYarnPriceAction,
  setYarnWashAndCareAction,
  setYarnWashAndCareSuccessAction,
  uploadYarnImageAction,
  uploadYarnImageSuccessAction,
  archiveYarnAction,
  archiveYarnErrorAction,
  archiveYarnSuccessAction,
} from '@/store/reducers/yarniverse.reducer';
import { TransportResponse } from '@directus/sdk';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  setYarnColorAction,
  setYarnColorSuccessAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  yarniverseErrorAction,
} from '../reducers/yarniverse.reducer';
import { getCurrentUserSelector } from '../selectors/user.selector';
import { Yarn } from '../types/yarniverse';

function* setYarnDataRequest(api: ApiType, action: ReturnType<typeof setYarnDataAction>) {
  try {
    const data = action.payload;
    const response: Yarn = yield call(
      data.id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.Yarn,
      data,
    );

    if (!response) {
      throw new Error('Something went wrong');
    }

    if (!response.yarn_manufacturer) {
      const user: User = yield select(getCurrentUserSelector);

      const responseManufacturer: TransportResponse<YarnManufacturerCollectionItem[]> = yield call(
        api.getCollection,
        Collections.YarnManufacturer,
        {
          filter: {
            user_created: {
              _eq: user.id,
            },
          },
        },
      );

      let manufactureId = null;

      if (!responseManufacturer.data?.length) {
        const responseNewManufacturer: YarnManufacturerCollectionItem = yield call(
          api.createCollectionItem,
          Collections.YarnManufacturer,
          {
            name: user.username || 'Unknown',
            status: ProductStatus.Published,
          },
        );

        manufactureId = responseNewManufacturer.id;
      } else {
        manufactureId = responseManufacturer.data[0].id;
      }

      yield call(api.updateCollectionItem, Collections.Yarn, {
        id: response.id,
        yarn_manufacturer: manufactureId,
      });
    }

    yield put(setYarnDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnColorsRequest(api: ApiType, action: ReturnType<typeof getYarnColorsAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnColorCollectionItem[]> = yield call(
      // @ts-expect-error - TS is not able to infer the correct type
      api.getCollection,
      Collections.YarnColor,
      {
        filter: {
          yarn: yarnId,
        },
        sort: 'id',
      },
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    }
    yield put(getYarnColorsSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createMultipleYarnColorsRequest(
  api: ApiType,
  action: ReturnType<typeof createMultipleYarnColorsAction>,
) {
  try {
    const { yarnId, yarnColors } = action.payload;

    for (const yarnColor of yarnColors) {
      const { name, image } = yarnColor;

      const responseFile: FileCollectionItem = yield call(api.uploadFile, FileFolders.Yarns, image);

      if (!responseFile) {
        throw new Error('Failed to upload image');
      }

      const response: YarnColorCollectionItem = yield call(
        api.createCollectionItem,
        Collections.YarnColor,
        {
          yarn: yarnId,
          name,
          image: responseFile.id,
        },
      );

      if (!response) {
        throw new Error('Failed to create yarn color');
      }
    }

    yield put(createMultipleYarnColorsSuccessAction());
    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createYarnColorRequest(api: ApiType, action: ReturnType<typeof createYarnColorAction>) {
  try {
    const yarnId = action.payload;

    yield delay(500);

    if (!yarnId) {
      throw new Error('Yarn ID is missing');
    }

    const response: YarnColorCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnColor,
      {
        yarn: yarnId,
      },
    );

    if (!response) {
      throw new Error('Failed to create yarn color');
    }

    yield put(getYarnColorsAction(yarnId));
    yield put(createYarnColorSuccessAction(response));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnColorRequest(api: ApiType, action: ReturnType<typeof setYarnColorAction>) {
  try {
    const { id, yarnId, name, ean, image, eanImage } = action.payload;
    let reponseColorImageFile: FileCollectionItem | undefined = undefined;
    let reponseEanImageFile: FileCollectionItem | undefined = undefined;
    if (image) {
      reponseColorImageFile = yield call(api.uploadFile, FileFolders.Yarns, image);
    }

    if (eanImage) {
      reponseEanImageFile = yield call(api.uploadFile, FileFolders.Yarns, eanImage);
    }
    const response: YarnColorCollectionItem = yield call(
      id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.YarnColor,
      {
        id,
        yarn: yarnId,
        name,
        ean,
        image: reponseColorImageFile?.id,
        eanImage: reponseEanImageFile?.id,
      },
    );
    if (!response) {
      throw new Error('Something went wrong');
    }
    yield put(setYarnColorSuccessAction());
    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* uploadYarnImageRequest(api: ApiType, action: ReturnType<typeof uploadYarnImageAction>) {
  try {
    const { yarn, image } = action.payload;
    const responseFile: FileCollectionItem = yield call(api.uploadFile, FileFolders.Yarns, image);

    if (!responseFile) {
      throw new Error(Errors.Default);
    }

    const collectionResponse: YarnImageCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnImages,
      {
        yarn: yarn.id,
        image: responseFile.id,
      },
    );

    if (!collectionResponse) {
      throw new Error(Errors.Default);
    }

    const payload = {
      images: [...(yarn.images || []), collectionResponse.id],
      main_image: yarn.main_image,
    };

    if (!yarn.main_image) {
      payload.main_image = collectionResponse.id;
    }

    yield put(
      setYarnDataAction({
        id: yarn.id,
        ...payload,
      }),
    );
    yield put(uploadYarnImageSuccessAction());
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnImagesRequest(api: ApiType, action: ReturnType<typeof getYarnImagesAction>) {
  try {
    const { yarn } = action.payload;
    const response: TransportResponse<YarnImageCollectionItem[]> = yield call(
      api.getCollectionItemsByIds,
      Collections.YarnImages,
      yarn.images || [],
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    } else {
      yield put(getYarnImagesSuccessAction(response.data));
    }
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnColorRequest(api: ApiType, action: ReturnType<typeof removeYarnColorAction>) {
  try {
    const { yarnId, id } = action.payload;

    yield call(api.removeCollectionItem, Collections.YarnColor, id);
    yield put(setYarnColorSuccessAction());

    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnColorImageRequest(
  api: ApiType,
  action: ReturnType<typeof removeYarnColorImageAction>,
) {
  try {
    const { yarnId, fileId } = action.payload;

    yield call(api.deleteFile, fileId);

    yield put(setYarnColorSuccessAction());

    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnWashAndCareRequest(
  api: ApiType,
  action: ReturnType<typeof setYarnWashAndCareAction>,
) {
  try {
    const { yarnId, washAndCare } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      wash_and_care: washAndCare,
    });

    yield put(setYarnWashAndCareSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarniverseDataRequest(
  api: ApiType,
  action: ReturnType<typeof getYarniverseDataAction>,
) {
  try {
    const yarnId = action.payload;
    const response: Yarn = yield call(api.getCollectionItemById, Collections.Yarn, yarnId);

    if (!response) {
      throw new Error('Something went wrong');
    }

    yield put(getYarnColorsAction(response.id as number));
    if (response.images?.length) {
      yield put(getYarnImagesAction({ yarn: response }));
    }

    yield put(getYarniverseDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnPriceRequest(api: ApiType, action: ReturnType<typeof setYarnPriceAction>) {
  try {
    const { yarnId, priceId, value } = action.payload;

    const response: YarnPriceCollectionItem = yield call(
      priceId ? api.updateCollectionItem : api.createCollectionItem,
      Collections.YarnPrice,
      {
        id: priceId,
        price: value,
        yarn: yarnId,
        currency: 1,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(setYarnDataAction({ id: yarnId, prices: [response.id] }));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnPricesRequest(api: ApiType, action: ReturnType<typeof getYarnPricesAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnPriceCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnPrice,
      {
        filter: {
          yarn: yarnId,
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }

    yield put(getYarnPricesSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* publishYarnRequest(api: ApiType, action: ReturnType<typeof publishYarnAction>) {
  try {
    const { yarnId, callback } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      status: ProductStatus.Published,
    });

    console.log(response);

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield call(callback, true);
    yield put(publishYarnSuccessAction());
  } catch (error: any) {
    console.log(error);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* archiveYarnRequest(api: ApiType, action: ReturnType<typeof archiveYarnAction>) {
  try {
    const { yarnId, callback } = action.payload;

    yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      status: ProductStatus.Archived,
    });

    yield put(archiveYarnSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(archiveYarnErrorAction());
    yield put(displayErrorAction(error?.message as string));
  }
}

export const yarniverseSaga = function* (api: ApiType) {
  yield all([takeLatest(setYarnDataAction.type, setYarnDataRequest, api)]);
  yield all([takeLatest(setYarnColorAction.type, setYarnColorRequest, api)]);
  yield all([takeLatest(createYarnColorAction.type, createYarnColorRequest, api)]);
  yield all([takeLatest(getYarnColorsAction.type, getYarnColorsRequest, api)]);
  yield all([takeLatest(uploadYarnImageAction.type, uploadYarnImageRequest, api)]);
  yield all([takeLatest(getYarnImagesAction.type, getYarnImagesRequest, api)]);
  yield all([takeLatest(removeYarnColorImageAction.type, removeYarnColorImageRequest, api)]);
  yield all([takeLatest(removeYarnColorAction.type, removeYarnColorRequest, api)]);
  yield all([takeLatest(setYarnWashAndCareAction.type, setYarnWashAndCareRequest, api)]);
  yield all([takeLatest(getYarniverseDataAction.type, getYarniverseDataRequest, api)]);
  yield all([takeLatest(setYarnPriceAction.type, setYarnPriceRequest, api)]);
  yield all([takeLatest(getYarnPricesAction.type, getYarnPricesRequest, api)]);
  yield all([takeLatest(publishYarnAction.type, publishYarnRequest, api)]);
  yield all([
    takeLatest(createMultipleYarnColorsAction.type, createMultipleYarnColorsRequest, api),
  ]);
  yield all([takeLatest(archiveYarnAction.type, archiveYarnRequest, api)]);
};
