/*
 * catch exception and return null if it fails
 * useful for situations where you want to use a default value
 */
export const tryto = <U>(f: () => U): U | null => {
  try {
    return f();
  } catch {
    return null;
  }
}
