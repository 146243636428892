import { PATH } from '@/common/constants/path';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@/components/button/button';
import {
  getYarnColorsAction,
  getYarnPricesAction,
  publishYarnAction,
  setYarnDataAction,
} from '@/store/reducers/yarniverse.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import {
  getYarniverseColorsSelector,
  getYarniverseCurrentStepSelector,
  getYarniverseDataSelector,
  getYarnPricesSelector,
} from '@/store/selectors/yarniverse.selector';
import { styled, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { YarniverseStepsKeys } from '../steps';
import { Colors } from './components/colors';
import { Info } from './components/info';
import { WashAndCare } from './components/wash-and-care';
import {
  Buttons,
  ButtonsWrapper,
  GridContainer,
  GridItem,
  Header,
  HeaderItem,
  PageContainer,
  PromotedButton,
  TabsSection,
  UpperCaseTitle,
} from './style';
import { useTranslation } from 'react-i18next';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getFibersByYarnIdAction } from '@/store/reducers/collection.reducer';
import React from 'react';
import { FiberCollectionItemWithStage } from '@/store/types/collection';
import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { PhotoViewer } from './components/photo';

const FiberGrid = ({ fibers }: { fibers: Array<FiberCollectionItemWithStage> }) => {
  const { t } = useTranslation();

  const getNumGridRows = (items: Array<FiberCollectionItemWithStage>) => {
    return Math.ceil((items.length + 3) / 4) * 3; // Ensure rows are in multiples of 3
  };

  const getGridItemStyle = (index: number) => {
    const column = (Math.floor(index / 3) % 4) + 1;
    const row = (index % 3) + 1 + Math.floor(index / 12) * 3;
    return { column, row };
  };

  return (
    <GridContainer rows={getNumGridRows(fibers)}>
      <HeaderItem {...getGridItemStyle(0)}>{t('search.yarnFilter.fiber')}</HeaderItem>
      <HeaderItem {...getGridItemStyle(1)}>{t('search.yarnFilter.amount')}</HeaderItem>
      <HeaderItem {...getGridItemStyle(2)}>{t('Yarniverse.origin')}</HeaderItem>
      {fibers.map((item, index) => {
        if (!item) return null;
        return (
          <React.Fragment key={item?.id}>
            <GridItem {...getGridItemStyle((index + 1) * 3)}>{item?.type?.name}</GridItem>
            <GridItem {...getGridItemStyle((index + 1) * 3 + 1)}>{item?.pourcent}</GridItem>
            <GridItem {...getGridItemStyle((index + 1) * 3 + 2)}>{item?.country?.name}</GridItem>
          </React.Fragment>
        );
      })}
    </GridContainer>
  );
};

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

const Block = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '2rem',
  border: '1px solid #000',
  borderTop: 'none',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('laptop')]: {
    '&:first-child': {
      borderRight: 'none',
    },
  },
}));

const AccordionTitle = styled('div')({
  fontSize: '40px',
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  cursor: 'pointer',
});

const TechnicalInformationTitle = styled('h4')({
  fontSize: '22px',
  fontWeight: 'normal',
});

const TechnicalInformationValue = styled('h2')({
  fontSize: '50px',
  fontWeight: 'normal',
});

export const Advertisement = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showTechnicalInformation, setShowTechnicalInformation] = useState(false);
  const yarn = useSelector(getYarniverseDataSelector);
  const { Gauge, Units, NeedleSize, Fiber } = useSelector(getCollectionDataSelector);
  const user = useSelector(getCurrentUserSelector);
  const yarnColors = useSelector(getYarniverseColorsSelector);
  const prices = useSelector(getYarnPricesSelector);
  const currentStep = useSelector(getYarniverseCurrentStepSelector);
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState<YarnColorCollectionItem | null>(null);

  useEffect(() => {
    if (yarnColors.length > 0) {
      setCurrentImage(yarnColors.find((x) => x.id === yarn.main_color) || yarnColors[0]);
    }
  }, [yarnColors, yarn.main_color]);

  const setMainImage = (color: YarnColorCollectionItem) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        main_color: color.id,
      }),
    );
  };

  const GAUGE = useMemo(() => {
    return Gauge.map((gauge) => {
      return yarn.gauge?.find((x) => x === gauge.id) ? gauge.thickness : undefined;
    }).filter((x) => !!x);
  }, [Gauge, yarn.gauge]);

  useEffect(() => {
    if (yarn.prices?.length && yarn.id) {
      dispatch(getYarnPricesAction(yarn.id));
    }
  }, [yarn.prices]);

  useEffect(() => {
    if (currentStep === YarniverseStepsKeys.Advertisement && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
      dispatch(getFibersByYarnIdAction(yarn.id));
    }
  }, [currentStep, yarn.id]);

  const handlePublish = () => {
    dispatch(
      publishYarnAction({
        yarnId: yarn.id as number,
        callback() {
          navigate(PATH.PROFILE);
        },
      }),
    );
  };

  const handleSelectColor = (id: number) => {
    const color = yarnColors.find((x) => x.id === id);
    if (color) {
      setCurrentImage(color);
    }
  };

  return (
    <>
      <TabsSection>
        <UpperCaseTitle>{yarn.name}</UpperCaseTitle>
      </TabsSection>

      <PageContainer>
        <Header>
          <PhotoViewer
            mainImageId={yarn.main_color}
            images={yarnColors}
            onMain={setMainImage}
            currentImage={currentImage}
            onImageChange={setCurrentImage}
          />
          <Colors
            name={yarn.name || ''}
            yarnColors={yarnColors}
            username={user?.display_name || user?.first_name || user?.username || ''}
            onColorSelect={handleSelectColor}
            selectedColorId={currentImage?.id}
          />
        </Header>

        <Row>
          <Info yarn={yarn} prices={prices || []} />
        </Row>

        <Row>
          <Block>
            <Typography>
              <AccordionTitle
                onClick={() => setShowTechnicalInformation(!showTechnicalInformation)}
              >
                {t('Yarniverse.technicalInformation')}
                <ArrowForwardIosIcon
                  style={{
                    transform: showTechnicalInformation ? 'rotate(90deg)' : 'none',
                    width: '30px',
                    height: '30px',
                  }}
                />
              </AccordionTitle>
              {showTechnicalInformation && (
                <>
                  <TechnicalInformationTitle>{t('common.weight')}</TechnicalInformationTitle>
                  <TechnicalInformationValue>
                    {yarn.amount_weight}
                    {Units?.find((x) => x.id === yarn.amount_weight_unit)?.name || '-'}
                  </TechnicalInformationValue>
                </>
              )}
            </Typography>
          </Block>
          <Block>
            {showTechnicalInformation && (
              <div
                style={{
                  marginTop: 'auto',
                }}
              >
                <TechnicalInformationTitle>
                  {t('search.yarnFilter.gauge')}
                </TechnicalInformationTitle>
                <TechnicalInformationValue>
                  {GAUGE.length > 0 ? GAUGE.join('/') : '-'}
                </TechnicalInformationValue>
              </div>
            )}
          </Block>
        </Row>
        {showTechnicalInformation && (
          <>
            <Row>
              <Block>
                <TechnicalInformationTitle>{t('common.length')}</TechnicalInformationTitle>

                <TechnicalInformationValue>
                  {yarn.amount_lenght}
                  {Units?.find((x) => x.id === yarn.amount_lenght_unit)?.name || '-'}
                </TechnicalInformationValue>
              </Block>
              <Block>
                <TechnicalInformationTitle>
                  {t('search.yarnFilter.needleSize')}
                </TechnicalInformationTitle>
                <TechnicalInformationValue>
                  {yarn.needle_size?.length
                    ? yarn.needle_size
                      .map((id) => NeedleSize?.find((x) => x.id === id)?.size)
                      .join('/')
                    : '-'}
                </TechnicalInformationValue>
              </Block>
            </Row>
            <Row>
              <Block>
                <FiberGrid fibers={Fiber} />
              </Block>
              <Block>
                <WashAndCare yarn={yarn} />
              </Block>
            </Row>
          </>
        )}
        <ButtonsWrapper>
          <Buttons>
            <PromotedButton>{t('Yarniverse.promoteYarn')}</PromotedButton>
            <Button colored onClick={handlePublish}>
              {t('Yarniverse.publish')}
            </Button>
          </Buttons>
        </ButtonsWrapper>
      </PageContainer>
    </>
  );
};
