import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DesignerWithStage } from '../types/designer';

export interface DesignersState {
  isDesignersLoading: boolean;
  data: DesignerWithStage[];
  error: boolean;
  designersFilter: object;
  designersSort: string[];
}

const initialState: DesignersState = {
    isDesignersLoading: false,
    data: [],
    error: false,
    designersFilter: {},
    designersSort: ['display_name']
};

export const designersSlice = createSlice({
  name: 'designers',
  initialState,
  reducers: {
    setDesignersFilterAction: (state, action: PayloadAction<object>) => {
        state.designersFilter = action.payload;
      },
      setDesignersSortAction: (state, action: PayloadAction<string[]>) => {
        state.designersSort = action.payload;
      },  
    getDesignersAction: (state) => {
      state.isDesignersLoading = true;
    },
    getDesignersSuccessAction: (state, action: PayloadAction<DesignerWithStage[]>) => {
      state.isDesignersLoading = false;
      state.data = action.payload;
    },
    getDesignersErrorAction: (state) => {
      state.isDesignersLoading = false;
    },
  },
});

export const { getDesignersAction, getDesignersSuccessAction, getDesignersErrorAction, setDesignersFilterAction, setDesignersSortAction } =
  designersSlice.actions;

export const designersReducer = designersSlice.reducer;
