
export const haveSameElements = (xs: number[], ys: number[]) => {
  const setA = new Set([...xs]);
  const setB = new Set([...ys]);

  if (setA.size !== setB.size) {
    return false;
  }

  let haveThemAll = true;
  setA.forEach(element => {
    if (!setB.has(element)) {
      haveThemAll = false;
    }
  });

  return haveThemAll;
}
