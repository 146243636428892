import { Button } from '@/components/button/button';
import {
  createPatternStepsAction,
  deletePatternStepAction,
  getPatternStepsAction,
  updatePatternStepAction,
} from '@/store/reducers/pattern.reducer';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
  getPatternStepsSelector,
} from '@/store/selectors/pattern.selector';
import { PatternStepWithStage, ResourceWithStage } from '@/store/types/pattern';
import { DeltaStatic, Sources } from 'quill';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PatternSteps } from './components/pattern-steps/pattern-steps';
import { ResourcesSidebar } from './components/resources-sidebar/resources-sidebar';
import {
  ContentEl,
  PageContainerEl,
  HeaderContainer,
  PatternName,
  TextEditorEl,
  TopContainer,
  TopSection,
  StyledButton,
  StepHeader,
  ButtonsContainer,
  StyledInput,
  ButtonContainer,
  LinkButton,
  LastContainer,
  ZoomContainer,
} from './styles';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import SaveIcon from '@mui/icons-material/Save';
import { Tooltip, Typography } from '@mui/material';
import { theme } from '@/common/mui-theme';
import { PatternStepViewer } from '../pattern-step-viewer';
import { getPatternViewDataSelector } from '@/store/selectors/pattern-view.selector';
import { getPatternByIdAction } from '@/store/reducers/pattern-view.reducer';
import { renderFormatString } from '@/pages/patternizer-page/components/categorization/components/size-format-edit';
import { tryto } from '@/utils/exceptions';
import { ZoomSlider } from '@/components/zoom-slider';

export interface PatternViewProps {
  patternId?: number;
  onCancel?: () => void;
}

export const PatternView: FC<PatternViewProps> = memo(({ patternId, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState<number | null>(null);
  const [currentStep, setCurrentStep] = useState<PatternStepWithStage | null>(null);
  const [stepContent, setStepContent] = useState('');
  const [stepTitle, setStepTitle] = useState<string>('');
  const [selectedZoom, setSelectedZoom] = useState<number>(100);
  const [previewMode, setPreviewMode] = useState(false);
  const [selectedSizeId, setSelectedSizeId] = useState<number | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const collections = useSelector(getCollectionDataSelector);
  const steps = useSelector(getPatternStepsSelector);
  const patternData = useSelector(getPatternDataSelector);
  const patternSelector = useSelector(getPatternViewDataSelector);
  const patternAvailableSizes = useSelector(getPatternAvailableSizesSelector);
  const { AvailableSize } = collections;

  const patternAvailableSizeNames =
    tryto(() => renderFormatString(patternData.pattern_size_model, currentStep?.relevant_sizes ?? patternAvailableSizes, AvailableSize)) ??
    AvailableSize?.filter((x) =>
      patternAvailableSizes.includes(x.id),
    )
      ?.map((x) => x.size_name)
      .join(', ');

  useEffect(() => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
      dispatch(getPatternByIdAction(patternId));
    }
  }, [patternId]);

  useEffect(() => {
    setCurrentStep(steps.find(({ id }) => currentStepId === id) || null);
  }, [steps, currentStepId]);

  useEffect(() => {
    setStepTitle(currentStep?.name || '');
    setStepContent(currentStep?.instructions || '');
  }, [currentStep]);

  const onCancelHandler = () => {
    dispatch(
      deletePatternStepAction({
        stepId: steps.map((step) => step.id),
        callback: () => patternData.id && dispatch(getPatternStepsAction(patternData.id)),
      }),
    );
    if (onCancel) {
      onCancel();
    }
  };

  const stepResources = currentStep?.ressources || [];

  const getUpdatedPatternSteps = () => {
    if (patternId) {
      dispatch(getPatternStepsAction(patternId));
      dispatch(getPatternByIdAction(patternId));
    }
  };

  const onContentChange = (value: string, _: DeltaStatic, source: Sources) => {
    if (source === 'api') {
      return;
    }

    setStepContent(value);
  };

  const onContentBlur = () => {
    if (!patternId || !currentStep) {
      return;
    }
    dispatch(
      updatePatternStepAction({
        stepId: currentStep.id,
        data: {
          instructions: stepContent,
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onResourceSelect = (selectedResources: number[]) => {
    if (!patternId || !currentStep) {
      return;
    }
    dispatch(
      updatePatternStepAction({
        stepId: currentStep.id,
        data: {
          ressources: selectedResources.map((resources_id) => ({
            resources_id,
          })),
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onResourceDeselect = (deselectedResource: number) => {
    if (!patternId || !currentStep) {
      return;
    }

    const updatedResources = stepResources.filter((resource) => resource.id !== deselectedResource);

    dispatch(
      updatePatternStepAction({
        stepId: currentStep.id,
        data: {
          ressources: updatedResources
            .filter((resource): resource is ResourceWithStage & { id: number } => resource.id !== undefined)
            .map((resource) => ({
              resources_id: resource.id,
            })),
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  if (!patternId) {
    return null;
  }

  const sizeOptions = AvailableSize
    ?.filter((size) => patternAvailableSizes.includes(size.id))
    .map((size) => ({ 
      label: size.size_name, 
      value: size.id 
    }));

  // const handleSizeChange = (sizeId: string | number) => {
  //   setSelectedSizeId(Number(sizeId));
  // };

  const handlePreviewModeChange = () => {
    setPreviewMode(!previewMode);
    if (sizeOptions && sizeOptions.length > 0 && selectedSizeId === null) {
      setSelectedSizeId(Number(sizeOptions[0].value));
    }
  };

  const handleStepChange = (stepId: number) => {
    onContentBlur();
    setCurrentStepId(stepId);
  };

  const onStepNameChangeHandler = (stepId: number, name: string) => {
    dispatch(
      updatePatternStepAction({
        stepId,
        data: {
          name,
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepRemoveHandler = (stepId: number) => {
    dispatch(
      deletePatternStepAction({
        stepId,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const addFirstStepHandler = (stepTitle?: string, stepContent?: string) => {
    dispatch(
      createPatternStepsAction({
        pattern: { id: patternId },
        sort: steps.length,
        relevant_sizes: patternAvailableSizes,
        name: stepTitle,
        instructions: stepContent,
        callback: (newStepId) => {
          getUpdatedPatternSteps();
          handleStepChange(newStepId);
        }
      }),
    );
  };

  return (
    <>
      {!!onCancel && (
        <TopSection>
          <TopContainer>
            <span>{/* <LangSelector /> */}</span>
            <Button onClick={onCancelHandler}>{t('common.cancel')}</Button>
          </TopContainer>
        </TopSection>
      )}
      <HeaderContainer>
        <PatternName>{patternData.name}</PatternName>
        <Typography variant='body1' color={theme.palette.text.secondary}>
          {patternAvailableSizeNames}
        </Typography>

        <ZoomContainer>
          <Typography variant='body1' color={theme.palette.text.secondary}>
            {t('patternView.zoom')}
          </Typography>
          <ZoomSlider value={selectedZoom} onChange={(newZoom) => setSelectedZoom(newZoom)} />
        </ZoomContainer>
        {steps.length > 0 && (
          <LastContainer>
            <Button
              onClick={handlePreviewModeChange}
              colored
              style={{ padding: '0.5rem 1.5rem', fontSize: '1.125rem' }}
            >
              {t('patternizerSteps.preview')}
            </Button>
          </LastContainer>
        )}
      </HeaderContainer>
      {previewMode ? (
        <PatternStepViewer
          pattern={patternSelector}
          zoom={selectedZoom}
          selectedSizeId={selectedSizeId}
          previewMode={previewMode}
          onZoomChange={(newZoom) => {
            setSelectedZoom(newZoom);
          }}
          onSizeChange={(sizeId) => setSelectedSizeId(sizeId)}
          onExitPreview={() => {
            setPreviewMode(false);
          }}
          initialStepIndex={currentStepIndex}
          onStepChange={(index) => {
            setCurrentStepIndex(index);
            if (steps[index]) {
              setCurrentStepId(steps[index].id);
            }
          }}
        />
      ) : (
        <PageContainerEl>
          <PatternSteps
            patternId={patternId}
            onStepChange={handleStepChange}
            currentStepId={currentStepId}
            setCurrentStepId={setCurrentStepId}
          />
          {steps.length === 0 ? (
            <ContentEl>
              <StepHeader>
                <LinkButton disabled>
                  {t('patternView.deleteStep')}
                </LinkButton>
                <ButtonsContainer>
                  <StyledInput
                    value={stepTitle}
                    onChange={(e) => setStepTitle(e.target.value)}
                    onBlur={() => addFirstStepHandler(stepTitle)}
                    placeholder={t('patternView.untitled')}
                    multiline
                    fullWidth
                  />
                  <StyledButton
                    onClick={() => addFirstStepHandler(stepTitle)}
                    disabled={!stepTitle}
                  >
                    <Tooltip title={t('patternView.saveTitle')} placement='top'>
                      <SaveIcon fontSize='large' />
                    </Tooltip>
                  </StyledButton>
                </ButtonsContainer>
              </StepHeader>
              <TextEditorEl
                zoom={selectedZoom}
                value={stepContent}
                placeholder={t('patternView.start')}
                onChange={onContentChange}
                onBlur={(_range, _source, editor) => {
                  setTimeout(() => {
                    const fixRange = editor.getSelection();
                    if (!fixRange) {
                      addFirstStepHandler(undefined, stepContent);
                    }
                  }, 50);
                }}
              />
              <ButtonContainer>
                <StyledButton
                  onClick={() => addFirstStepHandler(undefined, stepContent)}
                  disabled={!stepContent}
                >
                  <Tooltip title={t('patternView.saveInstruction')} placement='top'>
                    <SaveIcon fontSize='large' />
                  </Tooltip>
                </StyledButton>
              </ButtonContainer>
            </ContentEl>
          ) : (
            <ContentEl>
              <StepHeader>
                <LinkButton
                  onClick={() => {
                    if (currentStep?.id !== undefined) {
                      onStepRemoveHandler(currentStep.id);
                    }
                  }}
                >
                  {t('patternView.deleteStep')}
                </LinkButton>
                <ButtonsContainer>
                  <StyledInput
                    value={stepTitle}
                    onChange={(e) => setStepTitle(e.target.value)}
                    onBlur={() => onStepNameChangeHandler(currentStep?.id || 0, stepTitle)}
                    placeholder={t('patternView.untitled')}
                    multiline
                    fullWidth
                  />
                  <StyledButton
                    onClick={() => onStepNameChangeHandler(currentStep?.id || 0, stepTitle)}
                    disabled={!stepTitle || stepTitle === currentStep?.name}
                  >
                    <Tooltip title={t('patternView.saveTitle')} placement='top'>
                      <SaveIcon fontSize='large' />
                    </Tooltip>
                  </StyledButton>
                </ButtonsContainer>
              </StepHeader>
              <TextEditorEl
                zoom={selectedZoom}
                value={stepContent}
                placeholder={t('patternView.start')}
                onChange={onContentChange}
                onBlur={(_range, _source, editor) => {
                  setTimeout(() => {
                    const fixRange = editor.getSelection();
                    if (!fixRange) {
                      onContentBlur();
                    }
                  }, 50);
                }}
              />
              <ButtonContainer>
                <StyledButton
                  onClick={onContentBlur}
                  disabled={!stepContent || stepContent === currentStep?.instructions}
                >
                  <Tooltip title={t('patternView.saveInstruction')} placement='top'>
                    <SaveIcon fontSize='large' />
                  </Tooltip>
                </StyledButton>
              </ButtonContainer>
            </ContentEl>
          )}
          <ResourcesSidebar
            patternId={patternId}
            stepResources={stepResources}
            onResourceSelect={onResourceSelect}
            onResourceDeselect={onResourceDeselect}
            currentStep={currentStep}
          />
        </PageContainerEl>
      )}
    </>
  );
});
