import { Dropdown } from '@/components/dropdown';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getYarniverseDataSelector } from '@/store/selectors/yarniverse.selector';
import { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup, styled } from '@mui/material';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';
import { RadioInput } from '@/components/radio-checkbox-input/radio-checkbox-input';
import { InputButtonSelect } from '@/components/input-button-select';

export const DropdownStyled = styled(Dropdown)({
  border: 'none',
  fontSize: '1.375rem',
});
export const NeedleSize = () => {
  const dispatch = useDispatch();

  const [systemOfMeasurement, setSystemOfMeasurement] = useState<'EU' | 'US'>('EU');

  const { NeedleSize } = useSelector(getCollectionDataSelector);
  const yarn = useSelector(getYarniverseDataSelector);

  const NEEDLE_SIZE = useMemo(() => {
    return NeedleSize.map(({ id, eu_size_in_mm, us_size }) => ({
      label:
        systemOfMeasurement === 'EU'
          ? `${Number.parseFloat(Number(eu_size_in_mm).toFixed(4))} mm`
          : us_size,
      value: id,
    }));
  }, [NeedleSize, systemOfMeasurement]);

  const onChangeSystemOfMeasurement = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSystemOfMeasurement(value as 'EU' | 'US');
  };
  const handleChange = (value: number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        needle_size: yarn.needle_size?.includes(value)
          ? yarn.needle_size.filter((id) => id !== value)
          : [...(yarn.needle_size || []), value],
      }),
    );
  };
  return (
    <>
      <RadioGroup
        onChange={onChangeSystemOfMeasurement}
        name={'test'}
        value={systemOfMeasurement}
        style={{ display: 'block', paddingLeft: '10px' }}
      >
        <RadioInput label='EU' value='EU' />
        <RadioInput label='US' value='US' />
      </RadioGroup>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {NEEDLE_SIZE.map((el) => (
            <InputButtonSelect
              key={el.value + el.label}
              label={el.label}
              value={el.value}
              checked={yarn.needle_size?.includes(el.value) || false}
              onChange={() => handleChange(el.value)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
