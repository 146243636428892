import React from "react";

const ProductYarniversePage: React.FC = () => {

    return (
        <>
            <p>ProductYarniversePage</p>
        </>
    )
};

export default ProductYarniversePage;