import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Drawer, useMediaQuery, useTheme } from '@mui/material';

import {
  clearBasketAction,
  closeBasketAction,
  loadItemsFromBasketAction,
} from '@/store/reducers/basket.reducer';
import { getBasketDataSelector, getBasketOpenSelector } from '@/store/selectors/basket.selector';

import { PATH } from '@/common/constants/path';
import { Button } from '../button/button';
import { MobileMenu } from '../header/components/header-mobile/styles';
import {
  CloseButton,
  HeaderLabelStyle,
  MenuContainer,
  MenuHeader,
} from '../header/components/slide-in-menu/styles';
import { ProductCard } from '../product-card';
import {
  DeliveryCostContainer,
  DeliveryPrice,
  Description,
  EmptyBasket,
  EmptyLink,
  FinalContainer,
  Footer,
  Inner,
  ProductContainer,
  ProductTitle,
  TotalContainer,
  TotalPrice,
} from './style';

export const Basket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector(getBasketOpenSelector);
  const data = useSelector(getBasketDataSelector);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('tablet'));

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(closeBasketAction());
  };

  const handleCheckout = async () => {
    dispatch(closeBasketAction());
    navigate(PATH.PAYMENT_PAGE);
  };

  // const groupItemsByItemType = (items: any) => {
  //   return items.reduce((acc: any, item: any) => {
  //     const type = item.type;
  //     if (!acc[type]) {
  //       acc[type] = [];
  //     }
  //     acc[type].push(item);
  //     return acc;
  //   }, {});
  // };

  const groupItemsByType = (items: any) => {
    return items.reduce((acc: any, item: any) => {
      const type = item.type === 'Pattern' ? t('Basket.patterns') : t('Basket.supplies');
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (open) {
      dispatch(loadItemsFromBasketAction());
    }
  }, [open]);

  const onEmptyBasketPress = () => {
    dispatch(clearBasketAction());
  };

  const deliveryPrice = 0;

  const totalPrice = useMemo(() => {
    return (
      data.reduce((acc, crr) => {
        const price = parseFloat(crr.data?.price as string) || 0;
        const quantity = crr.data?.quantity || 0;
        return acc + price * quantity;
      }, 0) + deliveryPrice
    );
  }, [data, deliveryPrice]);

  const groupedItems = useMemo(() => groupItemsByType(data), [data]);

  const responsiveBasket = (
    <>
      <MenuHeader>
        <HeaderLabelStyle>{t('Basket.title')}</HeaderLabelStyle>
        <CloseButton variant='text' onClick={handleClose}>
          {t('common.close')}
        </CloseButton>
      </MenuHeader>
      <Inner>
        {data && data.length == 0 && <EmptyBasket>{t('Basket.empty')}</EmptyBasket>}
        {Object.keys(groupedItems).map((type) => (
          <ProductContainer key={type}>
            <ProductTitle>{type}</ProductTitle>
            {groupedItems[type].map((el: any) => (
              <ProductCard key={`${el.type}-${el.id}`} id={el.id} type={el.type} data={el.data} />
            ))}
          </ProductContainer>
        ))}
        {data && data.length > 0 && (
          <EmptyLink onClick={onEmptyBasketPress}>{t('Basket.emptyBasket')}</EmptyLink>
        )}
      </Inner>
      <Footer>
        <DeliveryCostContainer>
          <Description>{t('Basket.delivery')}</Description>
          <DeliveryPrice>
            {deliveryPrice} - {t('common.currency')}
          </DeliveryPrice>
        </DeliveryCostContainer>
        <FinalContainer>
          <TotalContainer>
            <Description>{t('Basket.total')}</Description>
            <TotalPrice>
              {totalPrice} - {t('common.currency')}
            </TotalPrice>
          </TotalContainer>
          <Button onClick={handleCheckout} colored disabled={data && data.length == 0}>
            {t('Basket.checkout')}
          </Button>
        </FinalContainer>
      </Footer>
    </>
  );

  return (
    <>
      {isMobileView ? (
        open && <MobileMenu>{responsiveBasket}</MobileMenu>
      ) : (
        <Drawer anchor='right' open={open} onClose={handleClose}>
          <MenuContainer>{responsiveBasket}</MenuContainer>
        </Drawer>
      )}
    </>
  );
};
