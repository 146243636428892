import { useNavigate } from 'react-router-dom';
import { YarnPriceCollectionItem } from '@/common/interfaces/collection.interface';
import { PageContainer } from '@/components/page-container/page-container';
import {
  getMeasurementsByPatternAction,
  getYarnAlternativeByPatternAction,
  setAlternativeItemsColorAction,
  setQuantityWithSizeAction,
} from '@/store/reducers/collection.reducer';
import { getYarnManufacturerBySupplierAction } from '@/store/reducers/yarn-supplier.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
  getPatternImagesSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { getYarnManufacturerBySupplierSelector } from '@/store/selectors/yarn-supplier.selector';
import { QuantityWithSizeCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Alternative } from './components/alternative';
import { Info } from './components/info';
import { Item } from './components/item';
import { MeasurementSize } from './components/measurement-size';
import { Photo } from './components/photo';
import {
  Container,
  Header,
  ItemContainer,
  NeedleDescription,
  NeedleTitle,
  Row,
  TotalQuantity,
  TotalTitle,
} from './styles';
import { ButtonContainer, StyledButton } from '../next-button';
import { publishPatternAction, unpublishPatternAction, archivePatternAction } from '@/store/reducers/pattern.reducer';
import { PATH } from '@/common/constants/path';
import { YarnItem } from './components/yarn-item';
import { Tooltip } from '@mui/material';

interface Props {
  onPressNextButton: () => void;
}

export const Advertisement: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pattern = useSelector(getPatternDataSelector);
  const availableSizes = useSelector(getPatternAvailableSizesSelector);
  const yarnManufacturer = useSelector(getYarnManufacturerBySupplierSelector);

  const { currentStep } = useSelector(getPatternizerSelector);

  const { YarnAlternative, Measurement, UnitMeasurement } = useSelector(getCollectionDataSelector);
  const { Needle } = useSelector(getCollectionDataSelector);
  const orderedSizes = availableSizes.slice().sort((a, b) => a - b);
  const firstSize = orderedSizes[0];
  const firstYarnAlternative = YarnAlternative[0]?.id;
  const images = useSelector(getPatternImagesSelector);

  const [selectedSize, setSelectedSize] = useState<number | undefined>(undefined);
  const [selectedYarnAlternative, setSelectedYarnAlternative] = useState<number | undefined>(undefined);

  const isTabOpen = useMemo(
    () => currentStep === PatternizerStepsKeys.ADVERTISEMENT,
    [currentStep],
  );

  // const mockPriceForNeedle: YarnPriceCollectionItem = {
  //   id: 1,
  //   price: 100,
  //   currency: {
  //     id: 1,
  //     name: 'NOK',
  //     code: 'NOK',
  //     flag: null,
  //   },
  // };

  // const yarnTotal = useMemo(() => {
  //   return YarnAlternative.reduce(
  //     (acc, crr) =>
  //       (acc += crr.alternativ_items.reduce(
  //         (a, c, i, arr) =>
  //           (a +=
  //             c?.yarn?.prices[0]?.price *
  //               arr[i].quantity_with_size.reduce((aq, cq) => (aq += cq.quantity || 0), 0) || 0),
  //         0,
  //       )),
  //     0,
  //   );
  // }, [YarnAlternative]);

  const yarnTotal = useMemo(() => {
    const selectedYarn = YarnAlternative.find(yarn => yarn.id === selectedYarnAlternative);
    if (!selectedYarn) return 0;

    const total = selectedYarn.alternativ_items.reduce((acc, item) => {
      const quantityForSelectedSize = item.quantity_with_size
        .filter(q => q.size_chart === selectedSize)
        .reduce((sum, q) => sum + (q.quantity || 0), 0);

        const itemPrice = item.yarn?.prices[0]?.price || 0;
        return acc + (quantityForSelectedSize * itemPrice);
      }, 0);
  
      return total;
    }, [YarnAlternative, selectedYarnAlternative, selectedSize]);

    const formattedYarnTotal = new Intl.NumberFormat('nb-NO').format(yarnTotal);

  // const needleTotal = useMemo(() => {
  //   return Needle.reduce(
  //     (acc, crr, i, arr) => (acc += mockPriceForNeedle.price * arr[i].quantity || 0),
  //     0,
  //   );
  // }, [Needle]);

  // const total = useMemo(
  //   () => needleTotal + yarnTotal + (pattern.price || 0),
  //   [needleTotal, yarnTotal, pattern.price],
  // );

  const total = useMemo(
    () => yarnTotal + (pattern.price || 0),
    [yarnTotal, pattern.price],
  );

  const formattedTotal = new Intl.NumberFormat('nb-NO').format(total);

  const getQuantity = useCallback(
    (id: number) => {
      const quantity = YarnAlternative.find((el) => el.id === selectedYarnAlternative)
        ?.alternativ_items.find((el) => el.id === id)
        ?.quantity_with_size.find((el) => el.size_chart === selectedSize);
      return {
        quantityId: quantity?.id,
        value: quantity?.quantity || 0,
      };
    },
    [YarnAlternative, selectedYarnAlternative, selectedSize],
  );

  const handleColorChange = (colorId: number, alternativeId: number) => {
    dispatch(setAlternativeItemsColorAction({ color: colorId, alternative: alternativeId }));
  };

  const handleQuantityChange = (alternativeId: number, quantityId: number, value: number) => {
    dispatch(
      setQuantityWithSizeAction({
        alternativeItemId: alternativeId,
        quantityId,
        sizeId: selectedSize,
        value,
      }),
    );
  };

  const calculateYarnAlternativeQuantity = () => {
    for (const alternative of YarnAlternative) {
      for (const item of alternative.alternativ_items) {
        for (const measurement of item.yarn_measurement) {
          const quantityId = (
            item.quantity_with_size as QuantityWithSizeCollectionItemWithStage[]
          ).find((el) => el.size_chart === measurement.size)?.id;
          if (!quantityId) {
            dispatch(
              setQuantityWithSizeAction({
                alternativeItemId: item.id,
                quantityId,
                sizeId: measurement.size,
                value: Math.ceil((+measurement?.value || 0) / item.yarn.amount_weight),
              }),
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isTabOpen) {
      calculateYarnAlternativeQuantity();
    }
  }, [isTabOpen]);

  useEffect(() => {
    if (isTabOpen && pattern.id) {
      dispatch(getYarnAlternativeByPatternAction());
      dispatch(getMeasurementsByPatternAction(pattern.id));
    }
  }, [isTabOpen, pattern.id]);

  useEffect(() => {
    if (availableSizes.length > 0 && selectedSize === undefined) {
      setSelectedSize(firstSize);
    }
  }, [availableSizes, firstSize, selectedSize]);

  useEffect(() => {
    if (YarnAlternative.length > 0 && selectedYarnAlternative === undefined) {
      setSelectedYarnAlternative(firstYarnAlternative);
    }
  }, [YarnAlternative, firstYarnAlternative, selectedYarnAlternative]);

  useEffect(() => {
    if (!YarnAlternative.length && isTabOpen) {
      setSelectedSize(firstSize);
      setSelectedYarnAlternative(YarnAlternative[0]?.id);
    }
  }, [isTabOpen, YarnAlternative]);

  useEffect(() => {
    if (!pattern.user_created) return;
    dispatch(
      getYarnManufacturerBySupplierAction({
        userId: pattern.user_created,
      }),
    );
  }, [isTabOpen, pattern]);

  const handlePublish = () => {
    dispatch(
      publishPatternAction({
        patternId: pattern.id as number,
        callback: (isSuccess) => {
          if (isSuccess) {
            navigate(PATH.PROFILE);
          }
        },
      }),
    );
  };

  const handleUnpublish = () => {
    dispatch(
      unpublishPatternAction({
        patternId: pattern.id as number,
        callback: (isSuccess) => {
          if (isSuccess) {
            navigate(PATH.PROFILE);
          }
        },
      }),
    );
  };

  const handleArchive = () => {
    dispatch(
      archivePatternAction({
        patternId: pattern.id as number,
        callback: (isSuccess) => {
          if (isSuccess) {
            navigate(PATH.PROFILE);
          }
        },
      }),
    );
  };

  return (
    <PageContainer>
      <ButtonContainer align='right'>
        {pattern.status?.toLowerCase() === 'published' 
          ? <StyledButton colored onClick={handleUnpublish}>{t('patternizerSteps.advertisement.unpublish')}</StyledButton>
          : <Tooltip title={images.length === 0 ? t('patternizerSteps.advertisement.noImage') : ''}>
              <span>
                <StyledButton disabled={images.length === 0} onClick={onPressNextButton}>{t('patternizerSteps.advertisement.publish')}</StyledButton>
              </span>
            </Tooltip>
        }
      </ButtonContainer>
      <Header>
        <Photo images={images} pattern={pattern} />
        <Info pattern={pattern} />
      </Header>
      <Container>
        <MeasurementSize
          availableSizes={availableSizes}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          measurements={Measurement}
          measurement_units={UnitMeasurement}
          pattern={pattern}
        />
        <Alternative
          yarnAlternative={YarnAlternative}
          selectedYarnAlternative={selectedYarnAlternative}
          setSelectedYarnAlternative={setSelectedYarnAlternative}
        />
        <ItemContainer>
          {YarnAlternative.find((el) => el?.id === selectedYarnAlternative)?.alternativ_items.map(
            (el) => (
              <YarnItem
                key={el.id + '_alternative'}
                name={el?.yarn?.name}
                description={el?.yarn?.description}
                image={el?.color?.image ?? ""}
                price={el?.yarn?.prices[0]?.price || 0}
                quantity={getQuantity(el.id)?.value}
                patternColor={el?.color?.name}
                colors={el?.yarn?.colors}
                manufacturerId={el?.yarn?.yarn_manufacturer}
                onColorChange={(colorId) => handleColorChange(colorId, el.id)}
                onQuantityChange={(value) =>
                  handleQuantityChange(el.id, getQuantity(el.id)?.quantityId as number, value)
                }
                shipmentMethods={yarnManufacturer?.shipment_methods || []}
                from={yarnManufacturer?.estimated_delivery_day_from}
                to={yarnManufacturer?.estimated_delivery_day_to}
              />
            ),
          )}
        </ItemContainer>
        <TotalTitle>{t('patternizerSteps.advertisement.yarnTotal')}</TotalTitle>
        <TotalQuantity>
          {formattedYarnTotal},- NOK
        </TotalQuantity>
        {/* <NeedleTitle>{t('patternizerSteps.advertisement.requiredNeedles')}</NeedleTitle>
        <NeedleDescription>{t('patternizerSteps.advertisement.needlesDescription')}</NeedleDescription>
        <ItemContainer>
          {Needle.map((el, i) => (
            <Item
              id={el.id}
              key={el.id}
              type='needle'
              name={'Needle ' + (i + 1)}
              image={getAssetBackendUrl('7b53f5a6-75b2-4598-9034-f806cbfad6c9')}
              prices={[mockPriceForNeedle]}
              description={'item description'}
              selectedSize={selectedSize}
              quantities={el.quantity}
              pattern={pattern}
            />
          ))}
        </ItemContainer> */}
        <Row>
          <div>
            <TotalTitle>{t('patternizerSteps.advertisement.total')}</TotalTitle>
            <TotalQuantity>{formattedTotal},- NOK</TotalQuantity>
          </div>
          <StyledButton disabled={images.length === 0} colored onClick={handlePublish}>{t('patternizerSteps.advertisement.publish')}</StyledButton>
        </Row>
      </Container>
    </PageContainer>
  );
};
