import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#fff',
  zIndex: 1000,
});

export const Content = styled('div')({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
});

export const MainContent = styled('div')({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
});

export const CategoryInfoRow = styled('div')({
  borderTop: '1px solid #000',
  padding: '1rem',
});
