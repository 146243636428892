import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { MeasurementWithStage } from '@/store/types/collection';
// import { getPatternAvailableSizesSelector } from '@/store/selectors/pattern.selector';
import { TableBody, TableHead, TableRow } from '@mui/material';
import {
  TableContainer,
  TableDeleteIcon,
  TableCellDeleteStyled,
  TableCellNameStyled,
  TableCellStyled,
  TableStyled,
  TextFieldStyled,
  TableCellStyledItem,
  TableFirstCellStyled,
  StyledButton,
  TableButton,
} from './styles';
// import { useSelector } from 'react-redux';
import { AvailableSizeCollectionItem } from '@/common/interfaces/collection.interface';

interface Props {
  patternSizes: number[];
  AvailableSize: AvailableSizeCollectionItem[];
  Measurement: MeasurementWithStage[];
  onAddMeasurement: () => void;
  onAddMeasurementWithName: (value: string) => void;
  onSizeValueChange: (id: number, value: string) => void;
  onNameChange: (id: number, name: string) => void;
  onFirstNameChange: () => void;
  onRemoveMeasurement: (id: number) => void;
}

export const Table: FC<Props> = (props) => {
  const { patternSizes, AvailableSize, Measurement, onAddMeasurement, onAddMeasurementWithName, onSizeValueChange, onNameChange, onFirstNameChange, onRemoveMeasurement } =
    props;
  const { t } = useTranslation();
  // const patternSizes = useSelector(getPatternAvailableSizesSelector);

  const filteredAndSortedMeasurements = useMemo(() => {
    return Measurement.map((measurement) => {
      const filteredValues = measurement.measurement_values
        .filter((value) => patternSizes.includes(value.size_chart.id))
        .sort((a, b) => (a?.size_chart?.sort ?? 0) - (b?.size_chart?.sort ?? 0));
      return {
        ...measurement,
        measurement_values: filteredValues,
      };
    });
  }, [Measurement, patternSizes]);

  const headerMemo = useMemo(() => {
    return AvailableSize
      .filter((size) => patternSizes.includes(size.id))
      .map((size) => ({ id: size.id, size_name: size.size_name }));
  }, [patternSizes, AvailableSize]);

  return (
    <>
    <TableContainer>
      <TableStyled>
        {!!headerMemo?.length && (
          <TableHead>
            <TableRow>
              <TableFirstCellStyled></TableFirstCellStyled>
              {headerMemo.map((el) => (
                <TableCellStyled key={el.id + '_size'}>{el.size_name}</TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
        {Measurement.length === 0 && (
          <TableRow>
            <TableCellNameStyled>
              {/* <TableButton onClick={() => onFirstNameChange()}> {t('patternizerSteps.typeSpecificMeasurement')}</TableButton> */}
              <InputWithDelay
                  name=''
                  placeholder={t('patternizerSteps.typeSpecificMeasurement')}
                  onChange={(value) => onAddMeasurementWithName(value)}
                  textAlign='left'
                />
            </TableCellNameStyled>
            {headerMemo.map((_) => (
              <TableCellStyledItem key={_.id}>
              </TableCellStyledItem>
            ))}
              <TableCellDeleteStyled></TableCellDeleteStyled>
          </TableRow>
        )}
          {filteredAndSortedMeasurements?.map((el) => (
            <TableRow key={el.id}>
              <TableCellNameStyled>
                <InputWithDelay
                  name={el?.name}
                  placeholder={t('patternizerSteps.typeSpecificMeasurement')}
                  onChange={(value) => onNameChange(el.id, value)}
                  textAlign='left'
                />
              </TableCellNameStyled>
              {el.measurement_values.map((_) => (
                <TableCellStyledItem key={_.id}>
                  <InputWithDelay
                    name={_?.value}
                    placeholder='-'
                    onChange={(value) => onSizeValueChange(_.id, value)}
                  />
                </TableCellStyledItem>
              ))}
              <TableCellDeleteStyled>
                <TableDeleteIcon onClick={() => onRemoveMeasurement(el.id)} />
              </TableCellDeleteStyled>
            </TableRow>
          ))}
        </TableBody>
      </TableStyled>
    </TableContainer>
    <StyledButton onClick={onAddMeasurement}>
      {t('patternizerSteps.addMeasurement')}
    </StyledButton>
  </>
  );
};

interface InputWithDelayProps {
  name: string;
  placeholder?: string;
  onChange: (value: string) => void;
  textAlign?: 'center' | 'left' | 'right';
}

export const InputWithDelay: FC<InputWithDelayProps> = (props) => {
  const { name, placeholder, onChange, textAlign = 'center' } = props;

  const [value, setValue] = useInputWithDelay(name, onChange, 500);

  return (
    <TextFieldStyled
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      multiline
      textAlign={textAlign}
    />
  );
};
