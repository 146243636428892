import { FC } from 'react';
import { TitleContainer } from './styling';

interface Props {
  title: string;
  className?: string;
}

export const BigPageTitle: FC<Props> = ({ title, className }) => {
  return <TitleContainer className={className}>{title}</TitleContainer>;
};
