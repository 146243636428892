import { InputButtonSelect } from "@/components/input-button-select";
import useResource from "@/hooks/api/useResource";
import { createApi } from "@/services/api";
import { addPatternStepSize, removePatternStepSize } from "@/store/reducers/pattern.reducer";
import { getCollectionDataSelector } from "@/store/selectors/collection.selector";
import { getAvailableSizesForStep, getPatternAvailableSizesSelector, getPatternStepsSelector } from "@/store/selectors/pattern.selector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface Props {
  currentStepId: number;
}

export const RelevantSizes = ({ currentStepId }: Props) => {
  const dispatch = useDispatch();

  const availableSizes = useSelector(getPatternAvailableSizesSelector);
  const { AvailableSize: sizeData } = useSelector(getCollectionDataSelector);
  const orderedSizes = availableSizes.slice().sort((a, b) => a - b);

  const relevantSizes = useSelector(getAvailableSizesForStep(currentStepId));
  if (!relevantSizes) return null;

  return <>
    {orderedSizes.map(sizeId => {
      const checked = relevantSizes.includes(sizeId);
      return <InputButtonSelect
        key={sizeId}
        label={sizeData.find(y => y.id === sizeId)?.size_name}
        value={sizeId}
        checked={checked}
        onChange={() => {
            if (checked) {
              dispatch(removePatternStepSize({stepId: currentStepId, size: sizeId}));
            } else {
              dispatch(addPatternStepSize({stepId: currentStepId, size: sizeId}));
            }
        }}
      />}
    )}
  </>
};
