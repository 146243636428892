import { styled } from '@mui/material';
import { Button } from '@/components/button/button';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
});

export const TitleContainer = styled('div')({
  // No need right now
});

export const Title = styled('h2')({
  padding: '1rem 3rem 0rem 0rem',
  margin: '0 2rem',
  fontSize: '1.375rem', // 22px
  display: 'inline-block',
  borderBottom: '1px solid #000',
  fontStyle: 'italic',
  height: '3.5rem',
});

export const InstructionsContainer = styled('div')<{ fontSize: number }>(({ fontSize }) => ({
  padding: '2rem 2rem 0rem 2rem',
  overflowY: 'auto',
  flex: 1,
}));

export const NavigationBar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderTop: '1px solid #000',
  height: '3.5rem',
  fontSize: '1.125rem', // 18px
});

export const NavigationGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StepNavigationText = styled('span')({
  cursor: 'pointer',
  textDecoration: 'underline',
});

export const FinishButton = styled(Button)({
  padding: '0.5rem 1.5rem',
  marginLeft: '1rem',
  fontSize: '1.125rem', // 18px
});
