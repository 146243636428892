import { styled } from '@mui/material';
import { Accordion } from '@/components/accordion';

export const Spacer = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  transition: 'width 0.3s ease-in-out',
  width: isVisible ? '50%' : '0',
  flexShrink: 0,
}));

export const Container = styled('div')<{ isVisible: boolean; isMenuVisible: boolean }>(({ isVisible, isMenuVisible }) => ({
  position: 'absolute',
  right: 0,
  width: `calc(50% - ${isMenuVisible ? '6.5rem' : '0px'})`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
  background: '#fff',
  borderLeft: '1px solid #000',
}));

export const ResourceList = styled('div')({
  margin: 0,
  overflowY: 'auto',
  flex: 1,
});

export const ToggleButton = styled('button')({
  position: 'absolute',
  left: '-30px',
  top: '50%',
  transform: 'translateY(-50%)',
  background: '#fff',
  border: '1px solid #000',
  borderRight: 'none',
  padding: '10px 5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AccordionTitle = styled('h3')({
  fontSize: '1.25rem',
  margin: 0,
  fontWeight: 600,
});

export const StyledAccordion = styled(Accordion)({
  padding: '0.5rem',
  '& .MuiAccordionSummary-root': {
    padding: '0',
    margin: '0',
    height: '4rem',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionDetails-root': {
    padding: '0',
  }
});
