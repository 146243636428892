import { styled } from '@mui/material';
import { Button } from '@/components/button/button';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0rem 1.5rem',
  borderBottom: '1px solid #000',
  gap: '3rem',
  height: '3.5rem',
});

export const Item = styled('span')({
  fontSize: '1.125rem', // 18px
  whiteSpace: 'nowrap', // Prevent wrapping
});

export const PatternName = styled('span')({
  textTransform: 'uppercase',
  fontSize: '1.9rem',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: '0 1 auto', // Allow shrinking but not growing
});

export const LogoImage = styled('img')({
  width: '10.5rem',
  cursor: 'pointer',
  flexShrink: 0, // Prevent logo from shrinking
  '&:hover': {
    opacity: 0.8,
  },
});

export const ZoomContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  fontSize: '1.125rem', // 18px
  flexShrink: 0, // Prevent zoom controls from shrinking
  whiteSpace: 'nowrap',
});

export const ExitPreviewButton = styled(Button)({
  marginLeft: 'auto',
  padding: '0.5rem 1.5rem',
  fontSize: '1.125rem',
  flexShrink: 0, // Prevent button from shrinking
});

