import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '../../components/lazy-fallback/lazy-fallback';

const PatternizerStartPage = lazy(() => import('./patternizer-start-page'));

export const PatternizerStartPageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <PatternizerStartPage />
  </Suspense>
);
