import { YarnPriceCollectionItem } from '@/common/interfaces/collection.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setYarnDataAction, setYarnPriceAction } from '@/store/reducers/yarniverse.reducer';
import { Yarn } from '@/store/types/yarniverse';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Currency, PriceInput, PriceSet, PriceTitle, Textarea } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  yarn: Yarn;
  prices: YarnPriceCollectionItem[];
}

export const Info: FC<Props> = (props) => {
  const { yarn, prices } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDescriptionChange = (value: string) => {
    dispatch(setYarnDataAction({ ...yarn, description: value }));
  };

  const [description, setDescription] = useInputWithDelay(
    yarn?.description || '',
    handleDescriptionChange,
    1000,
  );

  const setYarnPrice = (value: string) => {
    dispatch(setYarnPriceAction({ yarnId: yarn.id as number, value: +value }));
  };

  const [price, setPrice] = useInputWithDelay(
    prices?.[0]?.price?.toString() || '',
    setYarnPrice,
    1000,
  );

  useEffect(() => {
    if (!prices.length) return;
    setPrice(prices[0].price?.toString() || '');
  }, [prices]);

  useEffect(() => {
    if (!yarn.description) return;
    setDescription(yarn.description || '');
  }, [yarn.description]);

  return (
    <Container>
      <div>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t('Yarniverse.insertDescriptionText')}
        />
      </div>
      <div>
        <PriceTitle>{t('Yarniverse.price')}</PriceTitle>
        <PriceSet>
          <PriceInput
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            placeholder={t('Yarniverse.setPrice')}
            type='number'
          />
          <Currency>, - NOK</Currency>
        </PriceSet>
      </div>
    </Container>
  );
};
