import { Collections } from '@/common/interfaces/collection.interface';
import { Button } from '@/components/button/button';
import { HorizontalTabs } from '@/components/horizontal-tabs';
import { getYarnColorsAction, setYarnDataAction } from '@/store/reducers/yarniverse.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseCurrentStepSelector,
  getYarniverseDataSelector,
} from '@/store/selectors/yarniverse.selector';
import { Grid, styled, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YarniverseStepsKeys } from '../steps';
import { Amount } from './components/amount';
import { NeedleSize } from './components/needle-size';
import { Fiber } from './components/fiber';
import { Selectors } from './components/selectors';
import { useTranslation } from 'react-i18next';
import { Construction } from './components/construction';
import { CountryOfOrigin } from './components/country-of-origin';
import { PageContainer } from './styles';

interface Props {
  onPressNext: () => void;
}

const TabPanel = styled('div')({
  padding: '1.5rem',
});

export const Categorization: FC<Props> = (props) => {
  const { onPressNext } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const yarn = useSelector(getYarniverseDataSelector);

  const currentStep = useSelector(getYarniverseCurrentStepSelector);

  const { Gauge, Texture } = useSelector(getCollectionDataSelector);

  const MENU_ITEMS = [
    {
      id: Collections.Fiber,
      label: t('search.yarnFilter.fiber'),
    },
    {
      id: Collections.Texture,
      label: t('search.yarnFilter.texture'),
    },
    {
      id: Collections.ThreadConstruction,
      label: t('search.yarnFilter.construction'),
    },
    {
      id: Collections.Gauge,
      label: t('search.yarnFilter.gauge'),
    },
    {
      id: Collections.NeedleSize,
      label: t('search.yarnFilter.needleSize'),
    },

    {
      id: 'Amount',
      label: t('search.yarnFilter.amount'),
    },
    {
      id: 'CountryOfOrigin',
      label: t('search.yarnFilter.country'),
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    id: string;
    label: string;
  }>(MENU_ITEMS[0]);

  const handleMenuChange = (item: { id: string; label: string }) => setSelectedMenuItem(item);

  const GAUGE = useMemo(() => {
    return Gauge.map((el) => ({ label: el.thickness, value: el.id }));
  }, [Gauge]);

  const TEXTURE = useMemo(() => {
    return Texture.map((el) => ({ label: el.name, value: el.id }));
  }, [Texture]);

  const handleChangeCollections = (key: string, value: number) => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        //@ts-ignore
        [key]: yarn[key]?.includes(value)
          ? //@ts-ignore
          yarn[key].filter((id) => id !== value)
          : //@ts-ignore
          [...(yarn[key] || []), value],
      }),
    );
  };

  useEffect(() => {
    if (currentStep === YarniverseStepsKeys.Colors && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
    }
  }, [currentStep]);

  if (currentStep !== YarniverseStepsKeys.Categorization) return null;

  return (
    <PageContainer>
      <HorizontalTabs
        menuItems={MENU_ITEMS}
        selectedId={selectedMenuItem.id}
        onSelect={handleMenuChange}
        title={<Typography variant='h2'>Categorization</Typography>}
        contentContainerStyles={{ borderBottom: '1px solid #000' }}
        heightByContent={true}
      >
        {selectedMenuItem.id === Collections.Fiber && <Fiber />}
        {selectedMenuItem.id === Collections.Gauge && (
          <TabPanel>
            <div style={{ paddingLeft: '10px' }}>{t('patternizerSteps.gaugeDescription')}</div>
            <Selectors
              items={GAUGE}
              selectedItems={yarn.gauge || []}
              onChange={(value) => handleChangeCollections('gauge', value)}
            />
          </TabPanel>
        )}
        {selectedMenuItem.id === Collections.Texture && (
          <TabPanel>
            <Selectors
              items={TEXTURE}
              selectedItems={yarn.texture || []}
              onChange={(value) => handleChangeCollections('texture', value)}
            />
          </TabPanel>
        )}
        {selectedMenuItem.id === Collections.ThreadConstruction && (
          <TabPanel>
            <Construction />
          </TabPanel>
        )}
        {selectedMenuItem.id === Collections.NeedleSize && (
          <TabPanel>
            <NeedleSize />
          </TabPanel>
        )}
        {selectedMenuItem.id === 'Amount' && (
          <TabPanel>
            <Amount yarn={yarn} />
          </TabPanel>
        )}
        {selectedMenuItem.id === 'CountryOfOrigin' && (
          <TabPanel>
            <CountryOfOrigin />
          </TabPanel>
        )}
      </HorizontalTabs>

      <Grid container width='100%' p='2rem'>
        <Button colored onClick={onPressNext}>
          {t('common.next')}
        </Button>
      </Grid>
    </PageContainer>
  );
};
