import { styled } from '@mui/material';

export const Cell = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '5.975rem',
  border: '1px solid #000000',
  marginRight: '1rem',
  cursor: 'pointer',
  marginBottom: '1rem',

  'img ': {
    width: '90%',
    height: '90%',
    objectFit: 'contain',
    marginRight: '1rem',
  },
});

export const Checkbox = styled('div')({
  width: '1.5rem',
  height: '1.5rem',
  border: '1px solid #000000',
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Circle = styled('div')({
  width: '0.5rem',
  height: '0.5rem',
  borderRadius: '50%',
  backgroundColor: '#000000',
});
