import { Drawer } from '@mui/material';
import { styled } from '@mui/material';
import { FC } from 'react';
import { ResourceView } from '@/components/resource-view';
import { CloseBtn } from '@/components/close-btn';

interface ResourceViewDrawerProps {
  resourceId: number | null;
  resourceType: 'resource' | 'diagram';
  onClose: () => void;
  showInPanel?: boolean;
}

const DrawerContainer = styled('div')<{ showInPanel?: boolean }>(({ showInPanel }) => ({
  ...(showInPanel ? {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#fff',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
  } : {
    width: '70vw',
    '@media (max-width: 768px)': {
      width: '100vw',
    },
  }),
}));

const ResourceContainer = styled('div')<{ showInPanel?: boolean }>(({ showInPanel }) => ({
  width: '100%',
  height: '100%',
  ...(showInPanel && {
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
}));

const StyledCloseBtn = styled(CloseBtn)<{ showInPanel?: boolean }>(({ showInPanel }) => ({
  ...(showInPanel ? {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    zIndex: 1001,
  } : {
    position: 'fixed',
    top: '1rem',
    right: '1rem',
  }),
}));

export const ResourceViewDrawer: FC<ResourceViewDrawerProps> = (props) => {
  const { resourceId, resourceType, onClose, showInPanel } = props;

  if (!resourceId) return null;

  const content = (
    <>
      <ResourceContainer showInPanel={showInPanel}>
        <ResourceView resourceId={resourceId} resourceType={resourceType} />
      </ResourceContainer>
      <StyledCloseBtn onClick={onClose} showInPanel={showInPanel} />
    </>
  );

  if (showInPanel) {
    return <DrawerContainer showInPanel={showInPanel}>{content}</DrawerContainer>;
  }

  return (
    <Drawer anchor="right" onClose={onClose} open={!!resourceId}>
      <DrawerContainer showInPanel={showInPanel}>{content}</DrawerContainer>
    </Drawer>
  );
};
