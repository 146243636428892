import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, styled } from '@mui/material';
import { useClerk } from '@clerk/clerk-react';

import { PATH } from '@/common/constants/path';
import usePrelaunchState from '@/hooks/usePrelaunchState';
import { logoutAction } from '@/store/reducers/authorization.reducer';
import { getCurrentUserAction } from '@/store/reducers/user.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { getAvatarByName } from '@/utils/getAvatarByName';

import { BasketBtn } from '../basket-btn';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '2rem',
  [theme.breakpoints.down('laptop')]: {
    columnGap: '1rem',
  },
}));

const Li = styled('li')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const Image = styled('img')(({ theme }) => ({
  height: '30px',
  width: '30px',
  border: `2px solid ${theme.palette.primary.text}`,
  cursor: 'pointer',
  borderRadius: '100%',
}));

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    position: 'relative',
    borderRadius: 0,
    boxShadow: 'none',
    width: 'min-content',
    backgroundColor: 'transparent',
    paddingTop: '16px',
    '&::before': {
      content: '""',
      top: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '1px',
      height: '16px',
      backgroundColor: 'black',
    },
  },
  '& .MuiMenu-list': {
    padding: 0,
    border: '1px solid black',
    backgroundColor: 'white',
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.body1,
  minHeight: 'auto',
  padding: '0.75rem',
  borderBottom: '1px solid black',
  '&:last-child': {
    borderBottom: 'none',
  },
  // Remove default focus and hover styles
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey background on hover
  },
  // Remove the default focus outline
  '&:focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '0.5rem',
  },
}));

export const UserMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUserSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const { signOut } = useClerk();

  const prelaunch = usePrelaunchState(PATH.BASKET);

  const handleOpenMenu = (target: HTMLImageElement) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(target);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = () => {
    navigate(`${PATH.PROFILE}?id=${currentUser?.id}`);
    handleCloseMenu();
  };

  const navigateToSettings = () => {
    navigate(PATH.PROFILE_SETTING);
    handleCloseMenu();
  };

  const handleLogout = () => {
    dispatch(
      logoutAction({
        callback: () => {
          //navigate(PATH.HOME);
          signOut({ redirectUrl: '/' });
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getCurrentUserAction());
  }, []);

  return (
    <Container>
      <Li>
        <Image
          onClick={(event) => handleOpenMenu(event.currentTarget)}
          src={
            currentUser?.avatar
              ? getAssetBackendUrl(currentUser?.avatar, 40)
              : getAvatarByName(currentUser?.first_name || currentUser?.email || '')
          }
        ></Image>
        <StyledMenu
          id='basic-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            disablePadding: true,
          }}
          autoFocus={false}
          style={{ zIndex: 100000000000 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StyledMenuItem onClick={navigateToProfile}>{t('common.profile')}</StyledMenuItem>
          <StyledMenuItem onClick={navigateToSettings}>{t('common.settings')}</StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>{t('common.logout')}</StyledMenuItem>
        </StyledMenu>
      </Li>
        {!prelaunch && <BasketBtn />}
    </Container>
  );
};
