import { FC } from 'react';
import { ResourceWithStage } from '@/store/types/pattern';
import { ResourceItem } from '../resource-item';
import { ResourceList } from './styles';

interface Props {
  resources: ResourceWithStage[];
  onOpenPreview: (resourceId: number) => void;
}

export const Resources: FC<Props> = ({ resources, onOpenPreview }) => {
  return (
    <ResourceList>
      {resources.map((resource) => (
        <ResourceItem
          key={resource.id}
          resource={resource}
          onOpenPreview={onOpenPreview}
        />
      ))}
    </ResourceList>
  );
};
