import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Title } from '@mui/icons-material';
import { Button, Popover, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { t } from 'i18next';

interface Props {
  anchorEl: HTMLButtonElement | HTMLDivElement | null;
  onClose: () => void;
  onSelect: (id: number) => void;
  colors: YarnColorCollectionItem[] | null | undefined;
  manufacturerName?: string | null | undefined;
  yarnName?: string | null | undefined;
}

export const ColorPicker: FC<Props> = (props) => {
  const { anchorEl, onClose, onSelect, colors, manufacturerName, yarnName } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!anchorEl || !colors || !colors.length) {
    return null;
  }
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Colors colors={colors} onChange={onSelect} onClose={onClose} manufacturerName={manufacturerName} yarnName={yarnName}/>
    </Popover>
  );
};

interface ColorsProps {
  colors: YarnColorCollectionItem[];
  onChange: (id: number) => void;
  onClose: () => void;
  manufacturerName?: string | null | undefined;
  yarnName?: string | null | undefined;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  rowGap: '1rem',
  padding: '2rem',
});

const ColorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  marginRight: '2rem',
  '&:nth-child(4n)': {
    marginRight: 0,
  },
});

const Item = styled('img')({
  // width: 'calc(5rem + 2px)',
  // height: 'calc(5rem + 2px)',
  width: '10rem',
  height: '10rem',
  // marginLeft: '0.5rem',
  marginBottom: '1rem',
  cursor: 'pointer',
});

const PickerHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',          
  // alignItems: 'center',  
  marginTop: '2rem',
  padding: '0 1.5rem',
  borderTop: '1px solid #000',
  borderBottom: '1px solid #000',
});

const PickerHeaderLabels = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0.5rem',
}));

const PickerHeaderTitle = styled('div')(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 700,
}));

const PickerHeaderSubtitle = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
}));

const ClosePicker = styled(Button)(({ theme }) => ({
  ...theme.typography.h1,
}));

export const Colors: FC<ColorsProps> = (props) => {
  const { colors, onChange, onClose, manufacturerName, yarnName } = props;
  return (
    <div style={{ maxWidth: '50rem'}}>
      <PickerHeader>
        <PickerHeaderLabels>
          <PickerHeaderTitle>{manufacturerName}</PickerHeaderTitle>
          <PickerHeaderSubtitle>{yarnName}</PickerHeaderSubtitle>
        </PickerHeaderLabels>
        <ClosePicker variant='text' onClick={onClose}>{t('common.close')}</ClosePicker>
      </PickerHeader>
      <Container>
        {colors.map((el) => (
          <ColorContainer key={el.id}>
            <Item
              src={getAssetBackendUrl(el.image, 200)}
              title={el.name}
              alt={el.name}
              onClick={() => onChange(el.id)}
            ></Item>
            <Typography variant='body3'>{el.name}</Typography>
          </ColorContainer>
        ))}
      </Container>
    </div>
  );
};
