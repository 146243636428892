import { styled } from '@mui/material';

export const Spacer = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  transition: 'width 0.3s ease-in-out',
  width: isVisible ? '13rem' : '0',
}));

export const Container = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  position: 'absolute',
  width: '13rem',
  height: '100%',
  borderRight: '1px solid #000',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
}));

export const TitleContainer = styled('div')({
  borderBottom: '1px solid #000',
});

export const Title = styled('h2')({
  padding: '1rem 0rem 0rem 1.5rem',
  fontSize: '1.375rem', // 22px
  margin: 0,
  height: '3.5rem'
});

export const StepList = styled('ul')({
  listStyle: 'none',
  padding: '2rem 0.5rem 2rem 1.5rem',
  margin: 0,
  overflowY: 'auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
});

export const StepItem = styled('li')<{ isActive: boolean }>(({ isActive }) => ({
  fontSize: '1.125rem', // 18px in rem
  cursor: 'pointer',
  fontWeight: isActive ? 'bold' : 'normal',
  textDecoration: isActive ? 'none' : 'underline',
  display: 'inline-block',
}));

export const KnitSettingsContainer = styled('div')({
  borderTop: '1px solid #000',
  height: '3.5rem',
  display: 'flex',
  alignItems: 'center',
});

export const KnitSettingsButton = styled('div')<{ isPreview?: boolean }>(({ isPreview }) => ({
  padding: '0rem 1.5rem',
  cursor: isPreview ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textDecoration: 'underline',
  fontSize: '1.125rem', // 18px
  color: isPreview ? '#999' : 'inherit', // Grey out text in preview mode
}));

export const ToggleButton = styled('button')({
  position: 'absolute',
  right: '-30px',
  top: '50%',
  transform: 'translateY(-50%)',
  background: '#fff',
  border: '1px solid #000',
  borderLeft: 'none',
  padding: '10px 5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
