import CloseIcon from '@mui/icons-material/Close';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { Dropdown, DropdownOptionsItem } from '@/components/dropdown';

interface Props {
  id: number;
  label: string;
  data: {
    type: string;
    value: string | number;
    options: DropdownOptionsItem[];
    placeholder?: string;
  }[];
  onChange: (type: string, value: string | number) => void;
  onDelete?: (id: number) => void;
  isDeleted?: boolean;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginBottom: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    position: 'relative',
    flexWrap: 'wrap',
  },
}));

const ItemLabel = styled(Typography)(({ theme }) => ({
  // width: '8rem',
  marginBottom: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginLeft: 0,
    marginBottom: '1rem',
    width: '100%',
    paddingRight: '2rem',
  },
}));

const DeleteIcon = styled(CloseIcon)(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const DropdownStyled = styled(Dropdown)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    margin: '0 0.5rem 0.5rem 0 !important',
  },
}));

const DropdownContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'center',
  rowGap: '1rem',
}));

export const DropdownGroup: FC<Props> = (props) => {
  const { id, label, data, onChange, onDelete } = props;
  return (
    <Container>
      <ItemLabel>{label}</ItemLabel>
      <DropdownContainer>
        {data.map((el) => (
          <DropdownStyled
            key={el.type + '_' + label}
            value={el.value}
            options={el.options}
            onChange={(value) => onChange(el.type, value)}
            placeholder={el.placeholder}
            variant={'standard'}
          />
        ))}
      <DeleteIcon onClick={() => onDelete && onDelete(id)}/>
      </DropdownContainer>
    </Container>
  );
};
