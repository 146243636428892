import { styled } from '@mui/material';

export const Cell = styled('img')({
  width: '4.71rem',
  height: '4.71rem',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: '2rem',
  gap: '1rem',
});
