import { styled } from "@mui/material";
// import gradientBackground from '@/assets/icons/gradient-background.png';
import gradientBackgroundVideo from '@/assets/videos/gradient-background.mp4';

// const GradientContainer = styled('div')(({ theme }) => ({
//   position: 'fixed',
//   pointerEvents: 'none',
//   zIndex: -1,
//   width: '100%',
//   height: '100%',
//   backgroundImage: `url(${gradientBackground})`,
//   backgroundSize: '100%',
//   backgroundRepeat: 'no-repeat',
//   top: 0,
//   left: 0,
//   [theme.breakpoints.down('tablet')]: {
//     backgroundSize: '130%',
//   },
// }));

const VideoBackground = styled('video')(({ theme }) => ({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: -1,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  top: 0,
  left: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export const GradientBackground = () => {
  return (
    // <GradientContainer></GradientContainer>
    <VideoBackground autoPlay loop muted>
      <source src={gradientBackgroundVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </VideoBackground>
  );
};
