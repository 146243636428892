import {
  YarnColorCollectionItem,
  YarnImageCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { YarniverseStepsKeys } from '@/pages/yarniverse-page/steps';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArchiveYarnPayload,
  PublishYarnPayload,
  SetYarnPricePayload,
  UploadYarnImagePayload,
  WashAndCarePayload,
  Yarn,
  YarnColorImageRemovePayload,
  YarnColorPayload,
  YarnColorRemovePayload,
} from '../types/yarniverse';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarniverseState {
  currentStep: YarniverseStepsKeys;
  isLoading: boolean;
  data: Yarn;
  colors: YarnColorCollectionItem[];
  images: YarnImageCollectionItem[];
  prices: YarnPriceCollectionItem[];
}

const initialState: YarniverseState = {
  currentStep: YarniverseStepsKeys.Start,
  isLoading: false,
  data: {},
  colors: [],
  images: [],
  prices: [],
};

export const yarniverseSlice = createSlice({
  name: 'yarniverse',
  initialState,
  reducers: {
    setYarniverseStepAction: (state, action: PayloadAction<YarniverseStepsKeys>) => {
      state.currentStep = action.payload;
    },

    // Get yarn data
    getYarniverseDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarniverseDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Update data without saving to backend
    updateYarnStateData(state, action: PayloadAction<Yarn>) {
      state.data = action.payload;
    },

    //Set yarn data with save to backend
    setYarnDataAction: (state, _: PayloadAction<Yarn>) => {
      state.isLoading = true;
    },
    setYarnDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Get yarn colors
    getYarnColorsAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnColorsSuccessAction: (state, action: PayloadAction<YarnColorCollectionItem[]>) => {
      state.isLoading = false;
      state.colors = action.payload;
    },
    // Create yarn color
    createYarnColorAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },

    createYarnColorSuccessAction: (state, action: PayloadAction<YarnColorCollectionItem>) => {
      state.isLoading = false;
      state.colors = [...state.colors, action.payload];
    },
    // Update yarn color
    setYarnColorAction: (state, _: PayloadAction<YarnColorPayload>) => {
      state.isLoading = true;
    },
    setYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn color
    removeYarnColorAction: (state, _: PayloadAction<YarnColorRemovePayload>) => {
      state.isLoading = true;
    },
    removeYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn color image
    removeYarnColorImageAction: (state, _: PayloadAction<YarnColorImageRemovePayload>) => {
      state.isLoading = true;
    },
    removeYarnColorImageSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Upload Yarn image
    uploadYarnImageAction: (state, _: PayloadAction<UploadYarnImagePayload>) => {
      state.isLoading = true;
    },
    uploadYarnImageSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Get yarn images
    getYarnImagesAction: (state, _: PayloadAction<{ yarn: Yarn }>) => {
      state.isLoading = true;
    },
    getYarnImagesSuccessAction: (state, action: PayloadAction<YarnImageCollectionItem[]>) => {
      state.isLoading = false;
      state.images = action.payload;
    },

    // Remove yarn image
    setYarnWashAndCareAction: (state, _: PayloadAction<WashAndCarePayload>) => {
      state.isLoading = true;
    },
    setYarnWashAndCareSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Set price yarn
    setYarnPriceAction: (state, _: PayloadAction<SetYarnPricePayload>) => { },
    setYarnPriceSuccessAction: (state, action: PayloadAction<any>) => { },

    // Get yarn prices
    getYarnPricesAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnPricesSuccessAction: (state, action: PayloadAction<YarnPriceCollectionItem[]>) => {
      state.isLoading = false;
      state.prices = action.payload;
    },

    // Publish yarn
    publishYarnAction: (state, _: PayloadAction<PublishYarnPayload>) => {
      state.isLoading = true;
    },
    publishYarnSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Clear store
    clearYarniverseAction: () => {
      return initialState;
    },

    // Error action
    yarniverseErrorAction: (state) => {
      state.isLoading = false;
    },

    createMultipleYarnColorsAction: (
      state,
      _: PayloadAction<{ yarnId: number; yarnColors: { name: string; image: File }[] }>,
    ) => {
      state.isLoading = true;
    },
    createMultipleYarnColorsSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Archive yarn
    archiveYarnAction: (state, _: PayloadAction<ArchiveYarnPayload>) => {
      state.isLoading = true;
    },
    archiveYarnSuccessAction: (state) => {
      state.isLoading = false;
    },
    archiveYarnErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  setYarniverseStepAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  setYarnColorAction,
  setYarnColorSuccessAction,
  uploadYarnImageAction,
  uploadYarnImageSuccessAction,
  getYarnImagesAction,
  getYarnImagesSuccessAction,
  yarniverseErrorAction,
  removeYarnColorAction,
  removeYarnColorSuccessAction,
  removeYarnColorImageAction,
  removeYarnColorImageSuccessAction,
  updateYarnStateData,
  setYarnWashAndCareAction,
  setYarnWashAndCareSuccessAction,
  getYarniverseDataAction,
  getYarniverseDataSuccessAction,
  setYarnPriceAction,
  setYarnPriceSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  clearYarniverseAction,
  createYarnColorAction,
  createYarnColorSuccessAction,
  createMultipleYarnColorsAction,
  createMultipleYarnColorsSuccessAction,
  archiveYarnAction,
  archiveYarnSuccessAction,
  archiveYarnErrorAction,
} = yarniverseSlice.actions;

export const yarniverseReducer = yarniverseSlice.reducer;
