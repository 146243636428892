import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer, Container, TitleContainer, Title, StepList, StepItem, KnitSettingsContainer, KnitSettingsButton, ToggleButton } from './styles';
import { PatternStepWithStage } from '@/store/types/pattern';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { PATH } from '@/common/constants/path';
import { useTranslation } from 'react-i18next';

interface StepMenuProps {
  steps: PatternStepWithStage[];
  currentStep: PatternStepWithStage;
  onStepChange: (step: PatternStepWithStage) => void;
  isMenuVisible: boolean;
  onToggleMenu: () => void;
  patternId?: number;
  previewMode?: boolean;
}

export const StepMenu: React.FC<StepMenuProps> = ({ 
  steps, 
  currentStep, 
  onStepChange, 
  isMenuVisible, 
  onToggleMenu,
  patternId,
  previewMode
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleKnitSettingsClick = () => {
    if (patternId) {
      navigate(`${PATH.PATTERN}?id=${patternId}`);
    }
  };

  return (
    <>
      <Spacer isVisible={isMenuVisible} />
      <Container isVisible={isMenuVisible}>
        <TitleContainer>
          <Title>{t('knitmode.steps')}</Title>
        </TitleContainer>
        <StepList>
          {steps.map((step) => (
            <StepItem 
              key={step.id} 
              onClick={() => onStepChange(step)}
              isActive={step.id === currentStep.id}
            >
              {step.name}
            </StepItem>
          ))}
        </StepList>
        <KnitSettingsContainer>
          <KnitSettingsButton 
            onClick={previewMode ? undefined : handleKnitSettingsClick}
            isPreview={previewMode}
          >
            {t('knitmode.knitSettings')}
          </KnitSettingsButton>
        </KnitSettingsContainer>
        <ToggleButton onClick={onToggleMenu} aria-label={t('knitmode.toggleMenu')}>
          {isMenuVisible ? <ChevronLeft /> : <ChevronRight />}
        </ToggleButton>
      </Container>
    </>
  );
};
