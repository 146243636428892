import { CircularProgress, styled } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  isLoading: boolean;
  children: ReactNode;
}

const Loading = styled('div')({
  position: 'fixed',
  top: 10,
  left: 10,
  width: '100%',
  height: '100%',
  // backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  zIndex: 1000000,
  pointerEvents: 'none',
});

const CircularProgressStyled = styled(CircularProgress)({
  // color: '#F6F4ED',
  color: '#000',
});

export const PageWithLoading: FC<Props> = (props) => {
  const { isLoading, children } = props;
  return (
    <>
      {children}
      {!!isLoading && (
        <Loading>
          <CircularProgressStyled size={20} />
        </Loading>
      )}
    </>
  );
};
