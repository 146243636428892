import { Dropdown } from '@/components/dropdown';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getYarniverseDataSelector } from '@/store/selectors/yarniverse.selector';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { styled } from '@mui/material';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';

export const DropdownStyled = styled(Dropdown)({
  border: 'none',
  fontSize: '1.375rem',
});
export const CountryOfOrigin = () => {
  const dispatch = useDispatch();

  const { Countries } = useSelector(getCollectionDataSelector);

  const COUNTRY_OF_ORIGIN = useMemo(() => {
    return Countries.map((el) => ({ label: el.name, value: el.id }));
  }, [Countries]);

  const yarn = useSelector(getYarniverseDataSelector);
  const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] = useState<number | undefined>(
    yarn.country_of_origin,
  );

  const handleChange = (value: number) => {
    setSelectedCountryOfOrigin(value);
    dispatch(
      setYarnDataAction({
        ...yarn,
        country_of_origin: value,
      }),
    );
  };

  return (
    <Container>
      <DropdownStyled
        name={'test'}
        onChange={(value) => handleChange(value as number)}
        options={COUNTRY_OF_ORIGIN}
        value={selectedCountryOfOrigin}
        variant='filled'
        style={{
          margin: 0,
          background: 'transparent',
        }}
      />
    </Container>
  );
};
