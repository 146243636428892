import React from "react";

const TermsAndConditionsPage: React.FC = () => {

    return (
        <>
            <p>TermsAndConditionsPage</p>
        </>
    )
};

export default TermsAndConditionsPage;