import { TFunction } from 'i18next';
import { PATH } from '@/common/constants/path';
import { ItemSearchType } from '@/common/interfaces/search.interface';

export const getMenuItems = (t: TFunction) => [
  {
    name: t('menu.patterns.name'),
    link: PATH.PROMOTED_PATTERNS,
    ItemSearchType: ItemSearchType.Pattern,
    method: null,
    byline: t('menu.patterns.byline'),
    active: true
  },
  {
    name: t('menu.yarn.name'),
    link: PATH.PROMOTED_YARN,
    ItemSearchType: ItemSearchType.Yarn,
    method: null,
    byline: t('menu.yarn.byline'),
    active: true
  },
  {
    name: t('menu.needles.name'),
    link: PATH.NEEDLES,
    ItemSearchType: ItemSearchType.Needle,
    method: null,
    byline: t('menu.needles.byline'),
    active: true
  },
  {
    name: t('menu.designers.name'),
    link: PATH.PROMOTED_DESIGNERS,
    ItemSearchType: ItemSearchType.Designer,
    method: null,
    byline: t('menu.designers.byline'),
    active: true
  },
  {
    name: t('menu.learknit.name'),
    link: PATH.LEARKNIT,
    ItemSearchType: ItemSearchType.Resource,
    method: null,
    byline: t('menu.learknit.byline'),
    active: true
  },
  {
    name: t('menu.retailers.name'),
    link: PATH.KNITSHOP,
    ItemSearchType: null,
    method: null,
    byline: t('menu.retailers.byline'),
    active: true
  },
  {
    name: t('menu.onDemand.name'),
    link: PATH.PATTERNS,
    ItemSearchType: null,
    method: null,
    byline: t('menu.onDemand.byline'),
    active: false
  },
];


