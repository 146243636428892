import { styled } from '@mui/material';

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
});

export const PageContainer = styled('section')(() => ({
  maxWidth: '1600px',
  margin: '0 auto',
  padding: '2rem',
  borderRight: '1px solid #000',
  borderLeft: '1px solid #000',
}));
