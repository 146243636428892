import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Divider, ListItem, MenuList } from '@mui/material';

import { PATH } from '@/common/constants/path';
import usePrelaunchState from '@/hooks/usePrelaunchState';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { getAvatarByName } from '@/utils/getAvatarByName';
import { logoutAction } from '@/store/reducers/authorization.reducer';
import { getAuthorizationSelector } from '@/store/selectors/authorization.selector';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';

import Logo from '@/assets/logo.svg';
import { ReactComponent as MenuMobileIcon } from '@/assets/icons/menu-mobile.svg';
import { Button } from '@/components/button/button';
import { TermsPrivacyAboutPageType } from '@/pages/terms-privacy-about-page/terms-privacy-about-page';

import { BasketBtn } from '../../components/basket-btn';
import { LanguagePicker } from '../language-picker';
import {
  EpostLink,
  HeaderMobileEl,
  Link,
  LinkMobile,
  LoggedInMobileMenuFooter,
  MobileLogo,
  MobileMenu,
  MobileMenuBtn,
  MobileMenuBtnLine,
  MobileMenuFooter,
  MobileMenuFooterLink,
  MobileMenuImage,
  MobileMenuUser,
  RightMenu,
  StyledMenuItem,
  StyledMenuList,
} from './styles';
import {
  CloseButton,
  HeaderLabelStyle,
  MenuByline,
  MenuHeader,
  MenuLinkStyle,
  StyledList,
  StyledNavLink
} from '../slide-in-menu/styles';
import { getMenuItems } from '../slide-in-menu/menuItems';

export interface HeaderMobileProps {}

export const HeaderMobile: FC<HeaderMobileProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  const { isLoggedIn } = useSelector(getAuthorizationSelector);
  const currentUser = useSelector(getCurrentUserSelector);
  const menuItems = getMenuItems(t);
  const prelaunch = usePrelaunchState(PATH.BASKET);

  const menuOpenHandler = (opened: boolean) => {
    document.body.style.overflow = opened ? 'hidden' : 'visible';
    setMenuOpened(opened);
  };

  useEffect(() => {
    return () => {
      menuOpenHandler(false);
    };
  }, []);

  useEffect(() => {
    menuOpenHandler(false);
  }, [location]);

  const handleLogout = () => {
    dispatch(
      logoutAction({
        callback: () => {
          // navigate(PATH.SIGNUP);
          navigate(PATH.HOME);
        },
      }),
    );
  };

  return (
    <>
      <HeaderMobileEl>
        <MobileMenuBtn onClick={() => menuOpenHandler(true)}>
          <MenuMobileIcon />
        </MobileMenuBtn>
        <Link to={PATH.HOME}>
          <MobileLogo src={Logo} alt={t('main.title')} />
        </Link>
        <RightMenu>
          <LanguagePicker />
          {!prelaunch && <BasketBtn />}
        </RightMenu>
      </HeaderMobileEl>
      {menuOpened && (
        <MobileMenu>
          <MenuList>
            <MenuHeader>
              <HeaderLabelStyle>{t('header.nav.menu')}</HeaderLabelStyle>
              <CloseButton variant='text' onClick={() => menuOpenHandler(false)}>{t('common.close')}</CloseButton>
            </MenuHeader>
            <StyledList>
              {menuItems.map((item, index) => (
                <ListItem key={index} disablePadding>
                  {item.active ? (
                    <StyledNavLink to={item.link} onClick={() => menuOpenHandler(false)} >
                      <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                      <MenuByline active={item.active}>{item.byline}</MenuByline>
                    </StyledNavLink>
                  ) : (
                    <span>
                        <MenuLinkStyle active={item.active}>{item.name.toUpperCase()}</MenuLinkStyle>
                        <MenuByline active={item.active}>{item.byline}</MenuByline>
                    </span>
                  )}
                </ListItem>
              ))}
            </StyledList>
            <Divider />
            {!isLoggedIn ? (
              <MobileMenuFooter>
                <Button colored onClick={() => navigate(PATH.SIGNUP)}>
                  {t('header.nav.createacc')}
                </Button>
                <Button onClick={() => navigate(PATH.SIGNIN)}>
                  {t('header.nav.signin')}
                </Button>
              </MobileMenuFooter>
            ) : (
              <LoggedInMobileMenuFooter>
                <MobileMenuUser>
                  <MobileMenuImage
                    src={
                      currentUser?.avatar
                      ? getAssetBackendUrl(currentUser?.avatar, 40)
                      : getAvatarByName(currentUser?.first_name || currentUser?.email || '')
                    }
                    alt={currentUser?.first_name || 'user'}
                  />
                  <MobileMenuFooterLink onClick={() => navigate(PATH.PROFILE)}>
                    {currentUser?.display_name || currentUser?.first_name || currentUser?.username}
                  </MobileMenuFooterLink>
                </MobileMenuUser>
                <MobileMenuFooterLink onClick={() => navigate(PATH.PROFILE)}>{t('common.profile')}</MobileMenuFooterLink>
                <MobileMenuFooterLink onClick={() => navigate(PATH.PROFILE_SETTING)}>{t('common.settings')}</MobileMenuFooterLink>
                <MobileMenuFooterLink onClick={handleLogout}>{t('common.logout')}</MobileMenuFooterLink>
              </LoggedInMobileMenuFooter>
            )}
            <Divider />
            <StyledMenuList>
              <StyledMenuItem>
                <LinkMobile
                  to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.TERMS}`}
                >
                  {t('footer.termsConditions')}
                </LinkMobile>
              </StyledMenuItem>
              <StyledMenuItem>
                <LinkMobile
                  to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.PRIVACY}`}
                >
                  {t('footer.privacyPolicy')}
                </LinkMobile>
              </StyledMenuItem>
              <StyledMenuItem>
                <LinkMobile
                  to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.ABOUT}`}
                >
                  {t('footer.about')}
                </LinkMobile>
              </StyledMenuItem>
              <StyledMenuItem>
                <EpostLink href={`mailto:${t('footer.emailAddress')}`}>{t('footer.emailAddress')}</EpostLink>
              </StyledMenuItem>
            </StyledMenuList>
          </MenuList>
        </MobileMenu>
      )}
    </>
  );
};
