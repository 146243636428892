import { styled } from '@mui/material';
import { Select, MenuItem } from '@mui/material';

export const StyledSelect = styled(Select)<{ $highlight: boolean }>(({ $highlight, theme }) => ({
  marginLeft: '0.5rem',
  minWidth: '120px',
  height: '2rem',
  fontSize: '1.125rem',
  // backgroundColor: $highlight ? theme.palette.primary.main : 'transparent',
  backgroundColor: theme.palette.primary.main, // TODO: switch back if we want to only highlight when no size is selected

  '& .MuiSelect-select': {
    padding: '0.25rem 1rem',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid black',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid black',
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  '&.Mui-selected:hover': {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  '&:hover': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
}));