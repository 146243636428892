import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';

const NeedlePage = lazy(() => import('./needle-page'));

export const NeedlePageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <NeedlePage />
  </Suspense>
);
