import { PATH } from '@/common/constants/path';
import { useAuth } from '@/components/auth-provider/auth-provider';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export interface ProtectedRouteProps {
  children?: any;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const isLoggedIn = useAuth();
  const currentUser = useSelector(getCurrentUserSelector);

  if(location.pathname !== '/new-payment-wall' && location.pathname !== '/payment-wall-stripe'){
    if(currentUser?.role?.name && ['PATTERN DESIGNER', 'YARN', 'PHYSICAL STORE'].includes(currentUser?.role?.name)){    
      if(!currentUser?.plan_active){    
        return <Navigate to={PATH.PAYMENT_WALL} />
      }
    }
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.SIGNIN} />;
  }
  return children;
};
