import { ScreenSteps } from '@/components/screen-steps';
import { setYarniverseStepAction } from '@/store/reducers/yarniverse.reducer';
import {
  getYarniverseCurrentStepSelector,
  getYarniverseDataSelector,
} from '@/store/selectors/yarniverse.selector';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Advertisement } from '../advertisement';
import { Categorization } from '../categorization';
import { Colors } from '../colors';
import { Start } from '../start';
import { WashAndCare } from '../wash-and-care';
import { useTranslation } from 'react-i18next';

export enum YarniverseStepsKeys {
  Start = 'Start',
  Categorization = 'Categorization',
  WashAndCare = 'WashAndCare',
  Colors = 'Colors',
  Advertisement = 'Advertisement',
}

export const Steps = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentStep = useSelector(getYarniverseCurrentStepSelector);

  const { id } = useSelector(getYarniverseDataSelector);

  const handlePressNext = useCallback((step: YarniverseStepsKeys) => {
    dispatch(setYarniverseStepAction(step));
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab') as YarniverseStepsKeys;
    if (tab) {
      dispatch(setYarniverseStepAction(tab));
    }
  }, [searchParams]);

  useEffect(() => {
    const searchParams: { tab: YarniverseStepsKeys; id?: string } = {
      tab: currentStep,
    };
    if (id) {
      searchParams['id'] = id.toString();
    }
    setSearchParams(searchParams);
  }, [currentStep, id]);

  const STEPS = [
    {
      id: YarniverseStepsKeys.Start,
      title: t('Yarniverse.enterYarnName'),
      content: <Start onPressNext={() => handlePressNext(YarniverseStepsKeys.Categorization)} />,
    },
    {
      id: YarniverseStepsKeys.Categorization,
      title: t('Yarniverse.categorization'),
      content: (
        <Categorization onPressNext={() => handlePressNext(YarniverseStepsKeys.WashAndCare)} />
      ),
    },
    {
      id: YarniverseStepsKeys.WashAndCare,
      title: t('Yarniverse.washAndCare'),
      content: <WashAndCare onPressNext={() => handlePressNext(YarniverseStepsKeys.Colors)} />,
    },
    {
      id: YarniverseStepsKeys.Colors,
      title: t('Yarniverse.colors'),
      content: <Colors onPressNext={() => handlePressNext(YarniverseStepsKeys.Advertisement)} />,
    },
    {
      id: YarniverseStepsKeys.Advertisement,
      title: t('Yarniverse.advertisement'),
      content: <Advertisement />,
    },
  ];

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    dispatch(setYarniverseStepAction(value as YarniverseStepsKeys));
  };

  const getIsBlocked = useCallback(
    (key: string) => {
      if (!id) {
        return true;
      }
      switch (key) {
        default: {
          return false;
        }
      }
    },
    [id],
  );

  return (
    <ScreenSteps
      currentStep={currentStep}
      steps={STEPS}
      onStepChange={handleStepChange}
      getIsBlocked={getIsBlocked}
    />
  );
};
