import { TranslatePatternRequest } from '@/common/interfaces/api/upload-pattern.dto';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { User } from '@/common/interfaces/user.interface';
import {
  ConfirmPaymentWithStage,
  CreatePaymentPayload,
  PaymentDataState,
  PaymentWithStage,
} from '@/store/types/pattern';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logoutSuccessAction } from './authorization.reducer';
import { Payment } from '@/common/interfaces/payment.interface';

export interface PaymentState {
  isLoading: boolean;
  data: {
    id?: string; // checkout session id
  };
  confirm_payment: any;
}

const initialState: PaymentState = {
  isLoading: false,
  data: {},
  confirm_payment: {},
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentData: (state, action) => {
      state.data = action.payload;
    },
    setPaymentDataAction: (state, _: PayloadAction<CreatePaymentPayload>) => {
      console.log('..... setPaymentDataAction');
      state.isLoading = true;
    },
    setPaymentDataSuccessAction: (state, action: PayloadAction<PaymentDataState>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPaymentDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },

    updatePaymentDataAction: (state, action: PayloadAction<Partial<Payment>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    updatePaymentAction: (
      state,
      _: PayloadAction<
        Partial<Payment> & {
          callback?: () => void;
        }
      >,
    ) => {
      state.isLoading = true;
    },
    updatePaymentSuccessAction: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    updatePaymentErrorAction: (state) => {
      state.isLoading = false;
    },
    createPaymentAction: (state, _: PayloadAction<CreatePaymentPayload>) => {
      state.isLoading = true;
    },
    setPayment: (state, action: PayloadAction<{ id: string }>) => {
      state.data = action.payload;
    },
    setConfirmPaymentDataAction: (state, _: PayloadAction<ConfirmPaymentWithStage>) => {
      state.isLoading = true;
    },
    setConfirmPaymentDataSuccessAction: (state, action: PayloadAction<PaymentDataState>) => {
      state.isLoading = false;
      state.confirm_payment = action.payload;
    },
    setConfirmPaymentDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },

    createConfirmPaymentAction: (state, _: PayloadAction<CreatePaymentPayload>) => {
      state.isLoading = true;
    },
    confirmPaymentAction: (state, action: PayloadAction<ConfirmPaymentWithStage>) => {
      state.confirm_payment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  setPaymentData,
  setConfirmPaymentDataAction,
  setConfirmPaymentDataSuccessAction,
  setConfirmPaymentDataErrorAction,
  createConfirmPaymentAction,
  confirmPaymentAction,
  setPaymentDataAction,
  setPaymentDataSuccessAction,
  setPaymentDataErrorAction,
  updatePaymentDataAction,
  updatePaymentAction,
  updatePaymentSuccessAction,
  updatePaymentErrorAction,
  createPaymentAction,
  setPayment,
} = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
