import { UserRole } from '@/common/constants/launchDates';
import { PATH } from '@/common/constants/path';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { isPrelaunchForPath, isPrelaunchForUserRole } from '@/utils/isPrelaunch';
import { isUserRole } from '@/utils/isUserRole';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

type Props = PropsWithChildren & { path: PATH };

export const RedirectWrapper: FC<Props> = ({ path, children }) => {
  const currentUser = useSelector(getCurrentUserSelector);
  const userRole: UserRole =
    currentUser && isUserRole(currentUser.role.name) ? currentUser.role.name : 'default';
  // NOTE: The above code will set any roles defined in Directus, but not in ROLE_NAMES to 'default',
  // The following code is type safe, and will still resolve to the correct role, even if not defined in ROLE_NAMES
  // But without defaulting to 'default'
  // const userRole: UserRole = currentUser?.role.name || 'default';

  if (isPrelaunchForUserRole(userRole)) {
    return <Navigate to={PATH.PRELAUNCH} />;
  }
  if (isPrelaunchForPath(path)) {
    return <div>This feature is not launched yet</div>;
  }
  return <>{children}</>;
};
