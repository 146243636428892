import React, { useState } from 'react';
import { Container, ResourceList, AccordionTitle, StyledAccordion, Spacer, ToggleButton } from './styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Resources } from '../resources';
import { ResourceWithStage } from '@/store/types/pattern';
import { useTranslation } from 'react-i18next';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';

interface ResourcesPanelProps {
  resources: ResourceWithStage[];
  diagrams: ResourceWithStage[];
  knitryResources: ResourceWithStage[];
  onOpenResource: (id: number, type: 'resource' | 'diagram') => void;
  isMenuVisible: boolean;
  showInPanel?: boolean;
}

export const ResourcesPanel: React.FC<ResourcesPanelProps> = ({ 
  resources, 
  diagrams,
  knitryResources,
  onOpenResource,
  isMenuVisible,
  showInPanel = true
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [resourceViewId, setResourceViewId] = useState<number | null>(null);
  const [resourceViewType, setResourceViewType] = useState<'resource' | 'diagram' | null>(null);

  const handleOpenResource = (id: number, type: 'resource' | 'diagram') => {
    setResourceViewId(id);
    setResourceViewType(type);
  };

  const handleCloseResource = () => {
    setResourceViewId(null);
    setResourceViewType(null);
  };

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Spacer isVisible={isVisible} />
      <Container isVisible={isVisible} isMenuVisible={isMenuVisible}>
        <ResourceList>
          <StyledAccordion
            renderSummary={() => <AccordionTitle>{t('knitmode.patternResources')}</AccordionTitle>}
            renderDetails={() => <Resources resources={resources} onOpenPreview={(id) => handleOpenResource(id, 'resource')} />}
          />
          <StyledAccordion
            renderSummary={() => <AccordionTitle>{t('knitmode.chartsSchematics')}</AccordionTitle>}
            renderDetails={() => <Resources resources={diagrams} onOpenPreview={(id) => handleOpenResource(id, 'resource')} />}
          />
          <StyledAccordion
            renderSummary={() => <AccordionTitle>{t('knitmode.knitryResources')}</AccordionTitle>}
            renderDetails={() => <Resources resources={knitryResources} onOpenPreview={(id) => handleOpenResource(id, 'resource')} />}
          />
        </ResourceList>
        <ToggleButton 
          onClick={handleToggle}
          aria-label={t('knitmode.toggleMenu')}
        >
          {isVisible ? <ChevronRight /> : <ChevronLeft />}
        </ToggleButton>
        {resourceViewId && resourceViewType && (
          <ResourceViewDrawer
            resourceId={resourceViewId}
            resourceType={resourceViewType}
            onClose={handleCloseResource}
            showInPanel={showInPanel}
          />
        )}
      </Container>
    </>
  );
};
