import { z } from "zod";

export const FormatString = z.array(z.discriminatedUnion("type", [
  z.object({
    type: z.literal("delimiter"),
    delimiter: z.string(),
  }),
  z.object({
    type: z.literal("size_info_match_group"),
    for_sizes: z.array(z.number()),
  }),
]));

type Delimiter = {
  type: "delimiter";
  optional: boolean;
  value: string;
};

type IgnoreGroup = {
  type: "ignore-group";
  openBracket?: string;
  for_sizes: (number[] | Delimiter)[];
  closeBracket?: string;
}

type MatchGroup = {
  type: "match-group";
  openBracket?: string;
  for_sizes: (number[] | Delimiter)[];
  closeBracket?: string;
}

type AST = (Delimiter | IgnoreGroup | MatchGroup)[];

export const parseFormatString = (str: string, sizeIds: number[]) => {
  const json = JSON.parse(str);
  const tokens = FormatString.parse(json);

  const ast: AST = [];
  let currentGroup: MatchGroup | IgnoreGroup | null = null;

  for (const token of tokens) {
    if (token.type === "delimiter") {
      if (token.delimiter === "(" || token.delimiter === "[" || token.delimiter === "{") {
        if (currentGroup) throw `found opening delimiter: "${token.delimiter}" while inside of match group`;
        currentGroup = {
          type: "ignore-group",
          openBracket: token.delimiter,
          for_sizes: [],
        };
      } else if (token.delimiter === ")" || token.delimiter === "]" || token.delimiter === "}") {
        if (!currentGroup) throw `found closing delimiter: "${token.delimiter}", but not currently parsing any group`;
        currentGroup.closeBracket = token.delimiter;
        ast.push(currentGroup);
        currentGroup = null;
      } else {
        if (currentGroup) {
          currentGroup.for_sizes.push({
            type: "delimiter",
            optional: false,
            value: token.delimiter,
          });
        } else {
          ast.push({
            type: "delimiter",
            optional: true,
            value: token.delimiter,
          });
        }
      }
    } else if (token.type === "size_info_match_group") {
      if (token.for_sizes.some(s => sizeIds.includes(s))) {
        if (currentGroup === null) ast.push({ type: "match-group", for_sizes: [token.for_sizes] });
        else {
          if (currentGroup.type === "ignore-group") currentGroup = { ...(currentGroup as IgnoreGroup), type: "match-group" };
          (currentGroup as MatchGroup).for_sizes.push(token.for_sizes);
        }
      } else {
        if (currentGroup === null) ast.push({ type: "ignore-group", for_sizes: [token.for_sizes] });
        else if (currentGroup.type === "ignore-group") currentGroup.for_sizes.push(token.for_sizes);
        else currentGroup.for_sizes.push(token.for_sizes);
      }
    }
  }

  // if there are delimiters between match groups,
  // they can't be optional
  for (let i = 0; i < ast.length - 2; i++) {
    const a = ast[i];
    const b = ast[i+1];
    const c = ast[i+2];
    if (a.type === "match-group" && b.type === "delimiter" && c.type === "match-group") {
      b.optional = false;
    }
  }

  return ast;
};

const extract_size_info = (text: string) => {
  const r = /^(,?[0-9a-zA-Z\.\-\/])+/;
  const info = text.match(r);
  if (!info) throw "expected size info";

  return [info[0], text.slice(info[0].length)];
}

const ignore_size_info = (text: string) => {
  const r = /(^--)|(^-)/;
  const info = text.match(r);
  if (!info) throw "expected one or two dashes (- or --)";

  return text.slice(info[0].length);
}

const optionally_consume_whitespace = (text: string) => {
  if (text.length === 0) return text;
  if (text.slice(0, 1) === " ") return text.slice(1);
  return text;
}

const consume_whitespace = (text: string) => {
  if (text.slice(0, 1) === " ") return text.slice(1);
  else throw `expected whitespace, found ${text.slice(0, 1)}`;
}

const match_once = (text: string, matcher: AST, selected: number, matched: string | null = null): [string, string] => {
  if (matcher.length === 0) {
    if (matched) {
      return [matched, text];
    } else {
      throw "no matching selected size";
    }
  }

  const should_consume_whitespace_before = !!matched;
  const should_consume_whitespace_after = !matched;

  const m = matcher[0];
  const matcher_next = matcher.slice(1);
  if (m.type === "match-group") {
    if (!!m.openBracket !== !!m.closeBracket) throw "malformed matcher; can't have open bracket but not close bracket or vice versa"

    if (should_consume_whitespace_before) text = optionally_consume_whitespace(text);

    if (m.openBracket) {
      const i = m.openBracket.length;
      const open = text.slice(0, i);
      text = text.slice(i);
      if (m.openBracket !== open) throw "expected open bracket";
    }

    let size_info: string | null = null;
    for (const x of m.for_sizes) {
      if (Array.isArray(x)) {
        let si;
        [si, text] = extract_size_info(text);
        if (x.includes(selected)) size_info = si;
      } else {
        if (should_consume_whitespace_before) text = optionally_consume_whitespace(text);

        const delimiter = x.value;
        let t = text.slice(0, delimiter.length);
        text = text.slice(t.length);
        if (t === "–") t = "-"; // replace long dash with regular dash for simplicity
        if (t !== delimiter) throw `expected delimiter: "${delimiter}, but found ${t}"`;

        if (should_consume_whitespace_after) text = optionally_consume_whitespace(text);
      }
    }

    if (m.closeBracket) {
      const j = m.closeBracket.length;
      const close = text.slice(0, j);
      text = text.slice(j);
      if (m.closeBracket !== close) throw "expected close bracket";
    }

    if (size_info) {
      if (matched) throw "apparently more than one size matched (same size id in multiple for_sizes?)"
      return match_once(text, matcher_next, selected, size_info);
    } else {
      if (should_consume_whitespace_after) text = optionally_consume_whitespace(text);
      return match_once(text, matcher_next, selected, matched);
    }
  } else if (m.type === "ignore-group") {
    if (!!m.openBracket !== !!m.closeBracket) throw "malformed matcher; can't have open bracket but not close bracket or vice versa"

    const reset = text;
    try {
      if (should_consume_whitespace_before) text = optionally_consume_whitespace(text);

      if (m.openBracket) {
        const i = m.openBracket.length;
        const open = text.slice(0, i);
        text = text.slice(i);
        if (m.openBracket !== open) throw "expected open bracket";
      }

    for (const x of m.for_sizes) {
      if (Array.isArray(x)) {
        text = ignore_size_info(text);
      } else {
        if (should_consume_whitespace_before) text = optionally_consume_whitespace(text);

        const delimiter = x.value;
        let t = text.slice(0, delimiter.length);
        text = text.slice(t.length);
        if (t === "–") t = "-"; // replace long dash with regular dash for simplicity
        if (t !== delimiter) throw `expected delimiter: "${delimiter}, but found ${t}"`;

        if (should_consume_whitespace_after) text = optionally_consume_whitespace(text);
      }
    }

      if (m.closeBracket) {
        const j = m.closeBracket.length;
        const close = text.slice(0, j);
        text = text.slice(j);
        if (m.closeBracket !== close) throw "expected close bracket";
      }

      if (should_consume_whitespace_after) text = optionally_consume_whitespace(text);
    } catch {
      text = reset;
    }

    return match_once(text, matcher_next, selected, matched);
  } else {
    const reset = text;
    try {
      if (should_consume_whitespace_before) text = consume_whitespace(text);

      const delimiter = m.value;
      let t = text.slice(0, delimiter.length);
      text = text.slice(t.length);
      if (t === "–") t = "-"; // replace long dash with regular dash for simplicity
      if (t !== delimiter) throw `expected delimiter: "${delimiter}, but found ${t}"`;

      if (should_consume_whitespace_after) text = consume_whitespace(text);
    } catch (e) {
      if (m.optional) text = reset;
      else throw e;
    }

    return match_once(text, matcher_next, selected, matched);
  }
};

const match = (text: string, matcher: AST, selected: number): string => {
  const pieces: string[] = [];

  while (text.length > 0) {
    try {
      let captured: string;
      [captured, text] = match_once(text, matcher, selected);
      pieces.push(captured);
    } catch (e) {
      // advance one forward if no match
      pieces.push(text.slice(0, 1));
      text = text.slice(1);
    }
  }

  return pieces.reduce((acc, s) => acc + s, "");
};

export const formatRelevantSizes = (instructions: string, formatString: string, relevantSizeIds: number[], selectedSize: number) => {
  if (!relevantSizeIds.includes(selectedSize)) return instructions;

  let matcher: AST;
  try {
    matcher = parseFormatString(formatString, relevantSizeIds);
  } catch (e) {
    console.warn("unable to parse format string", formatString, "with ids", relevantSizeIds, "with error", e);
    return instructions;
  }

  try {
    return match(instructions, matcher, selectedSize);
  } catch (e) {
    console.warn("error when matching this structure:", matcher, "the error was:", e);
    return instructions;
  }
};

