import React from "react";

const AboutUsPage: React.FC = () => {

    return (
        <>
            <p>AboutUsPage</p>
        </>
    )
};

export default AboutUsPage;