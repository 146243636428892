import { styled } from '@mui/material/styles';
import { DialogActions } from '@mui/material';
import { Button } from '@/components/button/button';

export const ModalButton = styled(Button)({
  padding: '0.5rem 2rem',
  fontSize: '1.125rem',
});

interface StyledDialogActionsProps {
  isInfo?: boolean;
}

export const StyledDialogActions = styled(DialogActions)<StyledDialogActionsProps>(({ isInfo }) => ({
  display: 'flex',
  justifyContent: isInfo ? 'center' : 'space-between',
  padding: '1rem',
}));
