import {
  PatternImageCollectionItem,
  YarnImageCollectionItem,
} from "@/common/interfaces/collection.interface";
import { Button } from "@/components/button/button";
import { getAssetBackendUrl } from "@/utils/getAssetBackendUrl";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  ButtonText,
  Container,
  Header,
  MainCheckbox,
  MainCheckboxContainer,
  Next,
  Picture,
  PictureContainer,
  Prev,
} from "./styles";
import { t } from "i18next";
import { ConfirmationModal } from "../confirmation-modal";

interface Props {
  mainImageId?: number;
  images: PatternImageCollectionItem[] | YarnImageCollectionItem[];
  onUpload: (image: File) => void;
  onMain: (image: any) => void;
  handleDelete?: (imageId: number) => void;
  buttonText: string;
}

const MAX_IMAGE_DIMENSION = parseInt(process.env.REACT_APP_MAX_IMAGE_DIMENSION || '6000', 10);

export const PhotoUploader: FC<Props> = (props) => {
  const { mainImageId, images, onUpload, onMain, handleDelete, buttonText, ...rest } = props;

  const [currentImage, setCurrentImage] = useState<
    PatternImageCollectionItem | YarnImageCollectionItem | null
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const ref = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    const img = new window.Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
        setModalTitle(t('confirmationModal.imageNotUploaded'));
        setModalMessage(t('confirmationModal.imageTooBigMessage1')+ MAX_IMAGE_DIMENSION + t('confirmationModal.imageTooBigMessage2'));
        setIsModalOpen(true);
        URL.revokeObjectURL(objectUrl);
        return;
      }

      onUpload(file);

      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;

    event.target.value = "";
  };

  const setMainImage = () => {
    if (!currentImage) return; 
    onMain(currentImage);
  };

  const onDelete = () => {
    if (!currentImage) return;
    handleDelete?.(currentImage.id);
  };

  useEffect(() => {
    if (images.length) {
      setCurrentImage(images[0]);
    }
    if (images.length === 1) {
      setMainImage();
    }
  }, [images]);

  const onNextImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImage?.id,
    );
    if (currentIndex === images.length - 1) {
      setCurrentImage(images[0]);
    } else {
      setCurrentImage(images[currentIndex + 1]);
    }
  };

  const onPrevImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImage?.id,
    );
    if (currentIndex === 0) {
      setCurrentImage(images[images.length - 1]);
    } else {
      setCurrentImage(images[currentIndex - 1]);
    }
  };

  return (
    <Container {...rest}>
      {images.length ? (
        <PictureContainer>
          <Header>
            <ButtonText onClick={onClick}>{t('patternizerSteps.advertisement.uploadImage')}</ButtonText>
            <ButtonText onClick={onDelete}>{t('patternizerSteps.advertisement.deleteImage')}</ButtonText>
            <MainCheckboxContainer>
              <ButtonText>{t('patternizerSteps.advertisement.setAsMainPicture')}</ButtonText>
              <MainCheckbox
                onClick={setMainImage}
                className={currentImage?.id === mainImageId ? "checked" : ""}
              ></MainCheckbox>
            </MainCheckboxContainer>
          </Header>
          <Picture src={getAssetBackendUrl(currentImage?.image || "", 900)} />
          <Prev onClick={onPrevImage} />
          <Next onClick={onNextImage} />
        </PictureContainer>
      ) : (
        <>
          <Button onClick={onClick}>{buttonText}</Button>
        </>
      )}
      <input
        ref={ref}
        type="file"
        hidden
        accept="image/*"
        onChange={onChange}
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleCloseModal}
        title={modalTitle}
        message={modalMessage}
        onCloseText={t('confirmationModal.close')}
        isInfo={true}
      />
    </Container>
  );
};
