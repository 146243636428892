import React, { FC } from 'react';
import { styled } from '@mui/material';
import { InputButtonSelect } from '@/components/input-button-select';

interface SystemProps {
  unitSystem: string;
  setUnitSystem: (value: string) => void;
}

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
});

export const SystemSelection: FC<SystemProps> = ({ unitSystem, setUnitSystem }) => {
  const unitSystems = ['EU', 'US'];

  const UnitInputButtonSelect = styled(InputButtonSelect)({
    width: 'fit-content',
    paddingRight: '4rem',
    margin: '0 1rem 0 0',
  });

  return (
    <CheckboxContainer>
      {unitSystems
        .map((el) => (
        <UnitInputButtonSelect
          key={el}
          label={el}
          value={el}
          checked={unitSystem === el}
          onChange={() => setUnitSystem(el)}
        />
      ))}
    </CheckboxContainer>
  );
};
