import {
  YarnColorCollectionItem,
  YarnManufacturerCollectionItem,
} from '@/common/interfaces/collection.interface';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { searchYarnManufacturerAction } from '@/store/reducers/collection.reducer';
import { YarnAlternativeWithStage, YarnCollectionItemWithStage } from '@/store/types/collection';
import { Box, Grid } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from './components/colors';
import { Selector } from './components/selector';
import {
  InputStyled,
  SearchContainer,
  SearchIconStyled,
  Title,
  FilterCol,
  StyledBox,
  Yarniverse,
  GridContainer,
} from './styles';
import { Alternatives } from '../alternatives';

interface Props {
  onAddYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
  onAddFirstYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
  onAdd: () => void;
  alternatives: YarnAlternativeWithStage[];
  selectedAlternative?: number;
  setSelectedAlternative: (id: number) => void;
  onChangeMeasurement: (id: number, value: string) => void;
  onChangeName: (name: string, alternativeItem: number) => void;
  onDeleteItem: (id: number) => void;
  onDeleteAlternative: (id: number) => void;
}

export const Yarnivers: FC<Props> = (props) => {
  const { 
    onAddYarn,
    onAddFirstYarn,
    onAdd,
    alternatives,
    selectedAlternative,
    setSelectedAlternative,
    onChangeMeasurement,
    onChangeName,
    onDeleteItem,
    onDeleteAlternative,
  } = props;

  const dispatch = useDispatch();

  const [selectedYarn, setSelectedYarn] = useState<YarnCollectionItemWithStage>();

  const [selectedManufacturer, setSelectedManufacturer] =
    useState<YarnManufacturerCollectionItem>();

  const yarnTitle = useMemo(
    () => selectedManufacturer?.name + (selectedYarn ? `: ${selectedYarn.name}` : ''),
    [selectedManufacturer, selectedYarn],
  );

  const handleSearch = (value: string) => {
    dispatch(searchYarnManufacturerAction(value));
  };

  const [searchValue, setSearchValue] = useInputWithDelay('', handleSearch);

  return (
    <StyledBox>
      <Alternatives
          alternatives={alternatives}
          onAdd={onAdd}
          setSelectedAlternative={setSelectedAlternative}
          selectedAlternative={selectedAlternative}
          onChangeMeasurement={onChangeMeasurement}
          onChangeName={onChangeName}
          onDeleteItem={onDeleteItem}
          onDeleteAlternative={onDeleteAlternative}
        />
      {/* <SearchContainer>
        <SearchIconStyled />
        <InputStyled
          placeholder='Search yarn brands in Yarniverse'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </SearchContainer> */}
      <GridContainer container>
        <FilterCol laptop={6} mobile={12}>
          <Selector
            selectedYarn={selectedYarn}
            setSelectedYarn={setSelectedYarn}
            selectedManufacturer={selectedManufacturer}
            setSelectedManufacturer={setSelectedManufacturer}
          />
        </FilterCol>
        <Grid laptop={6} mobile={12}>
          <Colors title={yarnTitle} yarn={selectedYarn} onAddYarn={onAddYarn} onAddFirstYarn={onAddFirstYarn} alternatives={alternatives} />
        </Grid>
      </GridContainer>
    </StyledBox>
  );
};
