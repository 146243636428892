import { YarnManufacturerCollectionItem } from '@/common/interfaces/collection.interface';
import { getYarnByManufacturerAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Container, Item } from './styles';
import { Menu, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import useResource from '@/hooks/api/useResource';
import { t } from 'i18next';

interface Props {
  selectedYarn?: YarnCollectionItemWithStage;
  setSelectedYarn: (yarn?: YarnCollectionItemWithStage) => void;
  selectedManufacturer?: YarnManufacturerCollectionItem;
  setSelectedManufacturer: (manufacturer: YarnManufacturerCollectionItem) => void;
}

export const Selector: FC<Props> = (props) => {
  const { selectedYarn, setSelectedYarn, selectedManufacturer, setSelectedManufacturer } = props;
  const dispatch = useDispatch();
  const { YarnManufacturer, Yarn } = useSelector(getCollectionDataSelector);
  const { data, fetchData } = useResource(`item/Yarn?fields=yarn_manufacturer&limit=10000`) as { data: { data: { yarn_manufacturer: number }[] } | null, fetchData: () => void};

  const handleChangeManufacturer = (event: SelectChangeEvent<unknown>) => {
    const manufacturerId = event.target.value as number;
    const manufacturer = YarnManufacturer.find(m => m.id === manufacturerId);
    if (manufacturer) {
      setSelectedManufacturer(manufacturer);
      setSelectedYarn(undefined); // Reset selectedYarn
    }
  };

  const handleChangeYarn = (event: SelectChangeEvent<unknown>) => {
    const yarnId = event.target.value as number;
    const yarn = Yarn.find(y => y.id === yarnId);
    if (yarn) {
      setSelectedYarn(yarn);
    }
  };

  // useEffect(() => {
  //   if (!YarnManufacturer.length) return;
  //   setSelectedManufacturer(YarnManufacturer[0]);
  // }, [YarnManufacturer]);

  useEffect(() => {
    if (!selectedManufacturer) return;
    dispatch(getYarnByManufacturerAction(selectedManufacturer?.id));
  }, [selectedManufacturer]);

  // useEffect(() => {
  //   if (!Yarn.length) return;
  //   setSelectedYarn(Yarn[0]);
  // }, [Yarn]);

  useEffect(() => {
    fetchData();
  }, []);

  const manufacturersList = new Set (data?.data.map((item) => item.yarn_manufacturer).filter((item) => item !== null));

  return (
    <Container>
      <YarnSelect 
        displayEmpty
        onChange={handleChangeManufacturer} 
        value={selectedManufacturer?.id || ''}
        variant='standard'
        >
        <MenuItem disabled value=''>{t('patternizerSteps.selectSupplier')}</MenuItem>
        {YarnManufacturer
          .slice()
          .filter((el) => manufacturersList.has(el.id))
          .sort((a, b) => {
            const nameA = a.name || '';
            const nameB = b.name || '';
            return nameA.localeCompare(nameB);
          })
          .map((el) => (
            <MenuItem 
              key={el.id} 
              value={el.id}>
              {el.name}
            </MenuItem>
          ))}
      </YarnSelect>
      <YarnSelect
        displayEmpty
        onChange={handleChangeYarn} 
        value={selectedYarn?.id || ''}
        variant='standard'
        >
        <MenuItem value='' disabled>{t('patternizerSteps.selectYarn')}</MenuItem>
        {Yarn
          .slice()
          .sort((a, b) => {
            const nameA = a.name || '';
            const nameB = b.name || '';
            return nameA.localeCompare(nameB);
          })
          .map((el) => (
            <MenuItem 
              key={el.id} 
              value={el.id}
              >
              {el.name}
            </MenuItem>
          ))}
        </YarnSelect>
      {/* <Column>
        {YarnManufacturer
        .slice()
        .sort ((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        })
        .map((el) => (
          <Item
            key={el.id}
            className={el.id === selectedManufacturer?.id ? 'selected' : ''}
            onClick={() => handleChangeManufacturer(el)}
            variant='h5'
          >
            {el.name}
          </Item>
        ))}
      </Column>
      <Column style={{ borderLeft: `1px solid #000` }}>
        {Yarn
        .slice()
        .sort ((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        })
        .map((el) => (
          <Item
            key={el.id}
            className={el.id === selectedYarn?.id ? 'selected' : ''}
            onClick={() => handleChangeYarn(el)}
            variant='h5'
          >
            {el.name}
          </Item>
        ))}
      </Column> */}
    </Container>
  );
};


const YarnSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body2,
  width: '45%',
  '&::before': {
    // borderColor: theme.palette.secondary.main,
  },
  '& .MuiSelect-select': {
    padding: '0.5rem',
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));