import DOMPurify from 'dompurify';
import { FC, useMemo } from 'react';
import { Text } from './styles';
import { useSelector } from 'react-redux';
import { getAvailableSizesForStep } from '@/store/selectors/pattern.selector';
import { formatRelevantSizes } from '@/common/size-format';

interface Props {
  instructions: string;
  fontSize: number;
  stepId: number;
  selectedSizeId: number | null;
  onOpenResource: (resourceId: number, resourceType: 'resource' | 'diagram') => void;
  patternSizeModel: any;
}

export const Step: FC<Props> = (props) => {
  const { instructions, fontSize, stepId, selectedSizeId, onOpenResource, patternSizeModel } = props;

  // Mock data for pattern_size_model
  const mockFormatString = [
    {"type":"size_info_match_group","for_sizes":[56]},
    {"type":"delimiter","delimiter":"("},
    {"type":"size_info_match_group","for_sizes":[3]},
    {"type":"delimiter","delimiter":")"},
    {"type":"size_info_match_group","for_sizes":[4]},
    {"type":"delimiter","delimiter":"("},
    {"type":"size_info_match_group","for_sizes":[5]},
    {"type":"delimiter","delimiter":")"},
    {"type":"size_info_match_group","for_sizes":[6]},
    {"type":"delimiter","delimiter":"("},
    {"type":"size_info_match_group","for_sizes":[7]},
    {"type":"delimiter","delimiter":")"},
    {"type":"size_info_match_group","for_sizes":[38]},
    {"type":"delimiter","delimiter":"("},
    {"type":"size_info_match_group","for_sizes":[9]},
    {"type":"delimiter","delimiter":")"}
  ];

  const formatString = patternSizeModel;
  const relevantSizes = useSelector(getAvailableSizesForStep(stepId));

  // Format instructions based on selected size
  const formattedInstructions = useMemo(() => {
    console.log('Formatting instructions with:', {
      stepId,
      selectedSizeId,
      formatString,
      relevantSizes,
      hasInstructions: !!instructions
    });

    if (!instructions || !formatString || !relevantSizes || !selectedSizeId) {
      console.log('Missing required data for formatting:', {
        hasInstructions: !!instructions,
        hasFormatString: !!formatString,
        hasRelevantSizes: !!relevantSizes,
        selectedSizeId
      });
      return instructions;
    }

    // Convert formatString to string if it's an array
    const formatStringAsString = Array.isArray(formatString) ? JSON.stringify(formatString) : formatString;
    const formatted = formatRelevantSizes(instructions, formatStringAsString, relevantSizes, selectedSizeId);
    console.log('Formatted instructions:', {
      original: instructions.substring(0, 100) + '...',
      formatted: formatted.substring(0, 100) + '...',
      selectedSizeId,
      relevantSizes
    });

    return formatted;
  }, [instructions, formatString, relevantSizes, selectedSizeId]);

  const clearInstructions = DOMPurify.sanitize(formattedInstructions || instructions);

  const handleTextClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'SPAN' && target.dataset.resourceId && target.dataset.resourceType) {
      const resourceId = parseInt(target.dataset.resourceId, 10);
      const resourceType = target.dataset.resourceType as 'resource' | 'diagram';
      onOpenResource(resourceId, resourceType);
    }
  };

  return (
    <div>
      <Text
        dangerouslySetInnerHTML={{ __html: clearInstructions }}
        onClick={handleTextClick}
        fontSize={fontSize}
      />
    </div>
  );
};
