import { styled, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const FooterContainer = styled('footer')(({ theme }) => ({
  borderTop: '1px solid #000',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    display: "none" // TODO: Consider if we want footer on mobile
  },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem 5rem 2rem 2rem',
  maxWidth: '120rem',
  margin: '0 auto',
  columnGap: '5rem',
  [theme.breakpoints.down('desktop')]: {
    columnGap: '2rem',
    padding: '2rem',
  },
}));

export const LogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('desktopL')]: {
    width: '15rem',
  },
  [theme.breakpoints.down('desktop')]: {
    width: '12rem',
  },
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

export const LogoImageMobile = styled('img')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('laptop')]: {
    display: 'block',
    width: '12rem',
  },
}));

export const ResponsiveContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  columnGap: '5rem',
  [theme.breakpoints.down('desktop')]: {
    columnGap: '2rem',
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    rowGap: '2rem',
  },
}));

export const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 3,
  paddingTop: '4rem',
  [theme.breakpoints.down('desktopL')]: {
    paddingTop: '2rem',
  },
  [theme.breakpoints.down('desktop')]: {
    padding: '0',
  },
  [theme.breakpoints.down('laptop')]: {
    flexWrap: 'wrap',
    rowGap: '2rem',
  },
}));

export const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('laptop')]: {
    minWidth: '12rem',
  },
}));

export const LastList = styled(List)(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('laptop')]: {
    textAlign: 'initial',
    minWidth: '12rem',
  },
}));

export const FooterText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  padding: '0.25rem',
}));

export const GroupTitle = styled(FooterText)({
  fontWeight: 'bold',
});

export const StyledRouterLink = styled(RouterLink)({
  textDecoration: 'none',
});

export const CopyrightText = styled(FooterText)({
  textAlign: 'right',
  padding: '2rem 0',
});

export const InnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
});
