import { styled, TextareaAutosize } from '@mui/material';
import { Input } from '@/components/input/input';

export const DesignerWrapper = styled('div')(({ theme }) => ({
  padding: '3rem 0'
}));

export const Content = styled('div')(({ theme }) => ({
  padding: '2rem',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2rem',
  // justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    marginBottom: '2rem',
    padding: '1rem',
  },
}));

export const Name = styled('h1')(({ theme }) => ({
  margin: 0,
  fontSize: '2.5rem',
  fontWeight: 400,
  [theme.breakpoints.down('tablet')]: {
    fontSize: '2rem',
  },
}));

export const Designer = styled('div')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 700,
  textDecoration: 'underline',
  // padding: '3rem 0'
}));

export const Description = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: 'gray',
  margin: '1rem 0',
  // [theme.breakpoints.down('tablet')]: {
  //   fontSize: '1rem',
  // },
}));

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  ...theme.typography.body2,
  border: 'none',
  outline: 'none',
  minHeight: '16rem',
  width: '100%',
  // paddingTop: '1rem',
  height: 'fit-content',
  // maxHeight: '25rem',
  overflow: 'auto !important',
  resize: 'none',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '1rem',
    paddingTop: 0,
  },
}));

export const PriceTitle = styled('div')({
  fontSize: '1.375rem',
  fontWeight: 400,
});

export const PriceSet = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Currency = styled('div')(({ theme }) => ({
  fontSize: '3.125rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const PriceInput = styled(Input)(({ theme }) => ({
  fontSize: '3.125rem',
  padding: '0',
  '& ::before': {
    border: 'none !important',
  },
  '& ::after': {
    border: 'none !important',
  },
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  input: {
    '&::placeholder': {
      opacity: 1,
      fontSize: '1.5rem',
    },
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: '2rem',
  },
}));

export const Keywords = styled('div')(({ theme }) => ({
  ...theme.typography.h3,
  // ...theme.typography.body1,
  // height: '8.44rem',
  minHeight: '16rem',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  // marginBottom: '2rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
  },
}));

export const KeywordsTitle = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
}));

export const Block = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  flexGrow: 0,
});

export const MiddleBlock = styled(Block)({
  justifyContent: 'space-between',
  flexGrow: 1,
});
