import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  // marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2rem',
  [theme.breakpoints.down('tablet')]: {
    marginTop: '1rem',
  },
}));

export const AlternativesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  columnGap: '5rem',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '1rem',
  rowGap: '1rem',
  flex: '4',
  // overflow: 'scroll',
});

export const AlternativeButton = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
  borderRadius: '0',
  width: 'fit-content',
  backgroundColor: selected ? `${theme.palette.secondary.main}` : '#fff',
  justifyContent: 'space-between',
}));

export const AddAlternativeButton = styled(Button)({
  width: 'fit-content',
  alignSelf: 'flex-start',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
});

export const YarnContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '3rem',
  // alignItems: 'center',
});

export const ItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const NoYarnText = styled(Typography)({
  marginTop: '3rem',
});
