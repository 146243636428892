import { List, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const HeaderMobileEl = styled('header')({
  borderBottom: '1px solid #000',
  padding: '1.5rem 0.5rem 0.5rem 0.5rem',
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  alignItems: 'center',
  width: '100%',
});

export const MobileMenuBtn = styled('button')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: 'none',
  background: 'none',
  padding: 0,
  cursor: 'pointer',
});

export const MobileMenuBtnLine = styled('span')({
  display: 'block',
  marginBottom: '0.2rem',
  height: '0.2rem',
  background: '#000',
  ':last-child': {
    marginBottom: 0,
  },
});

export const MobileLogo = styled('img')({
  width: '6.5rem',
});

export const RightMenu = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '1rem',
});

export const MobileMenu = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  zIndex: 1000,
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
});

export const MobileMenuLogo = styled('img')({
  width: '2.5rem',
  margin: '1rem',
});

export const Link = styled(NavLink)({
  color: '#000',
  textDecoration: 'none',
});

export const LinkMobile = styled(Link)({
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'unset',
  },
});

export const StyledMenuList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 0rem 1.5rem 0.5rem',
  rowGap: '1rem',
});

export const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  minHeight: 'unset',
});

export const EpostLink = styled('a')({
  color: '#000',
  textDecoration: 'none',
});

export const MobileMenuFooter = styled('div')({
  display: 'flex',
  padding: '1.5rem 0rem 1.5rem 0.5rem',
  columnGap: '1rem',
});

export const LoggedInMobileMenuFooter = styled(MobileMenuFooter)({
  flexDirection: 'column',
  rowGap: '1rem',
});

export const MobileMenuUser = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '0.5rem',
});

export const MobileMenuImage = styled('img')({
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '100%',
});

export const MobileMenuFooterLink = styled('a')({
  color: '#000',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});
