import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ByUserText, NameLink, NameText } from './styles';
import { PATH } from '@/common/constants/path';

interface ByUserProps {
  name: string;
  userId?: string; // Provide user ID for link to work
  className?: string;
}

export const ByUser: React.FC<ByUserProps> = ({ name, userId, className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (userId) {
      navigate(`${PATH.PROFILE}?id=${userId}`);
    }
  };

  return (
    <ByUserText className={className}>
      {t('common.by')}{' '}
      {userId ? (
        <NameLink onClick={handleClick}>{name}</NameLink>
      ) : (
        <NameText>{name}</NameText>
      )}
    </ByUserText>
  );
};
