import { Button } from '@/components/button/button';
import { styled, Typography } from '@mui/material';

export const ColorContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: '600px',
  gap: '1rem',
});
export const TextStyled = styled(Typography)({
  margin: '4rem 0',
});

export const ButtonUploadStyled = styled(Button)({
  marginTop: '2rem',
  width: 'fit-content',
});

export const ButtonNextStyled = styled(Button)({
  marginTop: '10rem',
  width: 'fit-content',
  backgroundColor: '#CEFF1A',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  gap: '1rem',
});

export const PageContainer = styled('section')(() => ({
  maxWidth: '1600px',
  margin: '0 auto',
  padding: '2rem',
  borderRight: '1px solid #000',
  borderLeft: '1px solid #000',
}));
