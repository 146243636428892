import { styled } from '@mui/material';
import { Button } from '../button/button';
import { ImgVideo } from '../img-video/img-video';

export const Container = styled('div')<{ isRemoving?: boolean }>(({ theme, isRemoving }) => ({
  width: '207px',
  padding: '16px',
  backgroundColor: theme.palette.secondary.main,
  transition: 'all 0.3s ease-out',
  position: 'relative',
  ...(isRemoving && {
    opacity: 0.5,
    transform: 'scale(0.95)',
    pointerEvents: 'none',
  }),
}));

export const RemovingOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  zIndex: 2,
});

export const ImageContainer = styled('div')({
  position: 'relative',
  input: {
    '& input.Mui-disabled': {
      color: 'green',
    },
  },
});

export const DeleteButton = styled(Button)({
  position: 'absolute',
  top: '4px',
  right: '4px',
  backgroundColor: '#fff',
  fontSize: '14px !important',
  height: 'fit-content',
  width: 'fit-content',
  zIndex: 10,
  padding: 0,
  '&:hover': {
    backgroundColor: '#fff',
    opacity: 0.8,
  },
});

export const ImgEl = styled(ImgVideo)({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const PlaceHolderImage = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'underline',
  width: '175px',
  height: '175px',
  border: '1px solid black',
  backgroundColor: '#C3C0C0',
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

export const EanPlaceHolderImage = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'underline',
  width: '174px',
  height: '174px',
  backgroundColor: 'white',
  border: '1px solid black',
  boxSizing: 'content-box',
  overflow: 'hidden',
}));

export const Name = styled('div')({
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.125rem',
  padding: '0.5rem 0.5rem 1rem 0.5rem',
  borderBottom: '1px solid #000',
  minHeight: '44px',
});
