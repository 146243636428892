import { styled } from '@mui/material';

export const ByUserText = styled('span')({
  fontSize: '1.375rem', // 22px in rem
  display: 'inline-block',
});

export const NameLink = styled('span')({
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
});

export const NameText = styled('span')({
  // Regular text without any hover effects or cursor changes
});
